<template>
	<div class="footer-links-list">
		<h2>Services</h2>

		<a href="/automotive-service/">Automotive Service</a>
		<a href="/store-pickup/">Drive Thru Pickup</a>
		<a href="/same-day-local-delivery/">Same Day Local Delivery</a>
		<a href="/farms/">FARMS Service</a>
		<a href="/animalrx/">Blain's Animal Rx</a>
		<a href="/gift-registry/">Registries &amp; Lists</a>
		<a href="/gift-cards/">Gift Cards</a>
		<a href="/gifts/">Gift Guide</a>
		<a href="/best-program/">Extended Service Program</a>
		<a href="/small-engine-repair-and-parts/">Small Engine Repair</a>
		<a href="/fishing-hunting-licenses/">Fishing &amp; Hunting Licenses</a>
		<a href="/rebates/">Rebates</a>
		<a href="/blog/vip-petcare-vaccinations-at-blains-farm-fleet/">VIP Pet Care</a>
		<a href="/other-services/">Other Store Services</a>
	</div>
</template>

<script>
export default {
	name: 'ServicesLinks'
};
</script>