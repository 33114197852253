import Header from './components/Header.vue';
import Vue from 'vue';


const isSSR = (typeof document === 'undefined');

function razor(razorString, fallback) {
	return isSSR ? razorString : fallback;
}

Vue.prototype.$razor = razor;

export default new Vue({
	render: h => h(Header),
});
