import BlainBaseService from './BlainBaseService';
import {
	IAuthenticateError,
	IAuthenticateResource,
	IAuthenticateResponse,
	ICreateAccountResource,
	ICreateAccountResponse,
	IGetRewardsDataResponse,
	IRecoveryResponse
} from "../types/Account";

export interface IAccountService {
	resetPassword(bid: string, at: string, newPassword: string, antiForgeryToken: string, rememberMe: boolean): Promise<IRecoveryResponse>
	/**
     * Sends a recover password request
     */
	recoverPassword(email: string, antiForgeryToken: string): Promise<IRecoveryResponse>;
	/**
     * Sends a request to server, with authentication token, to be logged in. 
	 * If no account exists with the email address in the token, a new account is created.
     */
	googleLogin(credential: string, skipCartMerge: boolean): Promise<IAuthenticateResponse>;
	/**
     * Sends a request to server, with authentication response, to be logged in. 
	 * If no account exists with the email address in the token, a new account is created.
     */
	facebookLogin(authResponse: facebook.AuthResponse, skipCartMerge: boolean): Promise<IAuthenticateResponse | IAuthenticateError>;
	/**
     * Sends a request to server, with authentication response, to be logged in. 
	 * If no account exists with the email address in the token, a new account is created.
     */
	appleLogin(signInResponse: AppleID.ISignInResponse, skipCartMerge: boolean): Promise<IAuthenticateResponse | IAuthenticateError>;
	/**
     * Sends a request to server, with authentication token, to verify a session. 
	 * Responds with an error if user attempts to log in to a different account.
     */
	googleVerify(credential: string): Promise<IAuthenticateResponse | IAuthenticateError>;
	/**
     * Sends a request to server, with authentication token, to verify a session. 
	 * Responds with an error if user attempts to log in to a different account.
     */
	facebookVerify(authResponse: facebook.AuthResponse): Promise<IAuthenticateResponse | IAuthenticateError>;
	/**
     * Sends a request to server, with authentication token, to verify a session. 
	 * Responds with an error if user attempts to log in to a different account.
     */
	appleVerify(signInResponse: AppleID.ISignInResponse): Promise<IAuthenticateResponse | IAuthenticateError>;
	/**
     * Attempts a login for a user with an email/password combination.
     */
	attemptLogin(data: IAuthenticateResource, antiForgeryToken: string): Promise<IAuthenticateResponse>
	/**
     * Creates an account for a user with an email/password combination.
     */
	createAccount(data: ICreateAccountResource, antiForgeryToken: string): Promise<ICreateAccountResponse>
	/**
     * Returns anti-forgery html input element as string.
     */
	getAntiForgeryToken(): Promise<{token: string}>
	/**
     * Gets the rewards status of logged in user.
     */
	getRewardsData(): Promise<IGetRewardsDataResponse>
}

export default class AccountService extends BlainBaseService implements IAccountService {
	async resetPassword(bid: string, at: string, newPassword: string, antiForgeryToken: string, rememberMe: boolean = false): Promise<IRecoveryResponse> {
		return this._httpSvc.post<IRecoveryResponse>("/account/reset", { bid, at, newPassword, rememberMe }, { headers: { 'blainVerificationToken': antiForgeryToken } });
	}

	async recoverPassword(emailAddress: string, antiForgeryToken: string): Promise<IRecoveryResponse> {
		return this._httpSvc.post<IRecoveryResponse>(
			'/account/recover',
			{ emailAddress },
			{ headers: { 'blainVerificationToken': antiForgeryToken } }
		);
	}

	async googleLogin(credential: string, skipCartMerge: boolean): Promise<IAuthenticateResponse | IAuthenticateError> {
		return this._httpSvc.post<IAuthenticateResponse>('/account/google-auth', { credential, skipCartMerge });
	}

	async facebookLogin(authResponse: facebook.AuthResponse, skipCartMerge: boolean): Promise<IAuthenticateResponse | IAuthenticateError> {
		return this._httpSvc.post<IAuthenticateResponse>('/account/facebook-auth', { authResponse: JSON.stringify(authResponse), skipCartMerge });
	}

	async appleLogin(signInResponse: AppleID.ISignInResponse, skipCartMerge: boolean): Promise<IAuthenticateResponse | IAuthenticateError> {
		return this._httpSvc.post<IAuthenticateResponse>('/account/apple-auth', { signInResponse: JSON.stringify(signInResponse), skipCartMerge });
	}

	async googleVerify(credential: string): Promise<IAuthenticateResponse | IAuthenticateError> {
		return this._httpSvc.post<IAuthenticateResponse>('/account/google-verify', { credential });
	}

	async facebookVerify(authResponse: facebook.AuthResponse): Promise<IAuthenticateResponse | IAuthenticateError> {
		return this._httpSvc.post<IAuthenticateResponse>('/account/facebook-verify', { authResponse: JSON.stringify(authResponse) });
	}

	async appleVerify(signInResponse: AppleID.ISignInResponse): Promise<IAuthenticateResponse | IAuthenticateError> {
		return this._httpSvc.post<IAuthenticateResponse>('/account/apple-verify', { signInResponse: JSON.stringify(signInResponse) });
	}

	async attemptLogin(data: IAuthenticateResource, antiForgeryToken: string): Promise<IAuthenticateResponse> {
		return await this._httpSvc.post<IAuthenticateResponse>("/account/authenticate", data , { headers: { 'blainVerificationToken': antiForgeryToken } });
	}

	async createAccount(data: ICreateAccountResource, antiForgeryToken: string): Promise<ICreateAccountResponse> {
		return await this._httpSvc.post<ICreateAccountResponse>("/account/register", data , { headers: { 'blainVerificationToken': antiForgeryToken } });
	}

	async getAntiForgeryToken(): Promise<{token: string}> {
		return await this._httpSvc.post<{token: string}>("/account/getantiforgerytoken", null);
	}

	async getRewardsData(): Promise<IGetRewardsDataResponse> {
		return await this._httpSvc.post<IGetRewardsDataResponse>("/account/getrewardsdata", null);
	}

	async verifyPassword(password: string, antiForgeryToken: string): Promise<{success: boolean, requireLogin: boolean}> {
		return await this._httpSvc.post<{success: boolean, requireLogin: boolean}>("/account/verifyPassword", { password }, { headers: { 'blainVerificationToken': antiForgeryToken } });
	}
}