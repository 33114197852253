<template>
	<nav aria-label="Footer">
		<div class="footer-mobile-links-wrapper flex flex-wrap flex-center">
			<button
				class="blue-link flex-row fs-12 medium-bold tr-btn"
				@click.prevent="expand('services')"
				aria-label="services navigation links"
				:aria-expanded="(activeLink === 'services').toString()"
				aria-haspopup="true"
			>
				<span class="text">Services</span>
				<span aria-hidden="true" class="icon icon-chevron-down"></span>
			</button>
			<button
				class="blue-link flex-row fs-12 medium-bold tr-btn"
				@click.prevent="expand('company')"
				aria-label="company navigation links"
				:aria-expanded="(activeLink === 'company').toString()"
				aria-haspopup="true"
			>
				<span class="text">Company</span>
				<span aria-hidden="true" class="icon icon-chevron-down"></span>
			</button>
			<a class="blue-link flex-row fs-12 medium-bold" href="/mastercard/">
				<span class="text">Blain’s Mastercard</span>
				<span aria-hidden="true" class="icon icon-chevron-down"></span>
			</a>
			<a class="blue-link flex-row fs-12 medium-bold" href="/store-finder/">
				<span class="text">Stores</span>
				<span aria-hidden="true" class="icon icon-chevron-down"></span>
			</a>
			<button
				class="blue-link flex-row fs-12 medium-bold tr-btn"
				@click.prevent="expand('customer-care')"
				aria-label="customer care navigation links"
				:aria-expanded="(activeLink === 'customer-care').toString()"
				aria-haspopup="true"
			>
				<span class="text">Customer Care</span>
				<span aria-hidden="true" class="icon icon-chevron-down"></span>
			</button>
		</div>
		<nav-links-modal
			v-if="activeLink"
			:active-link="activeLink"
			@collapse="collapse"
		/>
	</nav>
</template>

<script>
import NavLinksModal from './NavLinksModal.vue';
export default {
	name: 'MobileLinkSection',
	components: { NavLinksModal },
	data() {
		return {
			activeLink: null
		};
	},
	methods: {
		expand(link) {
			if (this.activeLink === link) return;
			else this.activeLink = link;
		},
		collapse() {
			if (this.activeLink) this.activeLink = null;
		},
	},
};
</script>

<style lang='less'>
@import '../../../../less/_mixins.less';
.footer-mobile-links-wrapper .blue-link {
	color: @DB;
	font-size: 12px;
	line-height: 1em;
	margin: 0 0 10px 10px;

	&:hover {
		text-decoration: none;
	}

	span.icon {
		font-size: 7px;
		margin-left: 5px;
	}
}
</style>