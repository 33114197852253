import AccountService, { IAccountService } from 'JS/services/AccountService';
import bugsnagClient from 'JS/global/bugsnag';
import RewardsService, { IRewardsService } from 'JS/services/RewardsService';
import { inject, InjectionKey, provide } from 'vue';

export interface IServiceRegistry {
	accountService: IAccountService;
	rewardsService: IRewardsService;
	getAntiForgeryToken(): Promise<string>;
}

export const serviceRegistry = {
	accountService: new AccountService(),
	rewardsService: new RewardsService(),
	getAntiForgeryToken
};

export const injectionKey = Symbol() as InjectionKey<IServiceRegistry>;

export function provideServices(services: IServiceRegistry = Object.freeze(serviceRegistry)) {
	provide(injectionKey, services);
}

export function useServices() {
	return inject(injectionKey);
}

/**
 * Requests a new anti-forgery token
 * @returns
 */
export async function getAntiForgeryToken(): Promise<string> {
	let token: string;
	try {
		const response = await serviceRegistry.accountService.getAntiForgeryToken();
		if (response.token) {
			const el = document.createElement('div');
			el.innerHTML = response.token;
			token = el.querySelector('input[name=__RequestVerificationToken]').getAttribute('value');
		} else {
			bugsnagClient.notify(new Error("Failed to retrieve anti-forgery token"));
		}
	} catch (err) {
		bugsnagClient.notify(err);
	};

	return token;
}