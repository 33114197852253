import bugsnagClient from 'JS/global/bugsnag';
import { getErrorMessage } from 'JS/utilities/debugging';
import { HTTPMethod, IBaseHttpService, IBlainApiRequestOptions } from '../types/Http';

export default class BaseHttpService implements IBaseHttpService {
	/*============================================================
	 == Public Methods
	/============================================================*/

	/**
	 * Makes a GET request using XMLHttpRequest
	 *
	 * @param {string} url - The request URL
	 * @param {IBlainApiRequestOptions} [options] - Request options
	 * @return {Promise<T>} - A parsed API response
	 */
	get<T>(url: string, options?: IBlainApiRequestOptions): Promise<T> {
		return this._makeRequest(url, 'GET', null, options);
	}

	/**
	 * Makes a POST request using XMLHttpRequest
	 *
	 * @param {string} url - The request URL
	 * @param {any} data
	 * @param {IBlainApiRequestOptions} [options] - Request options
	 * @return {Promise<T>} - A parsed API response
	 */
	post<T>(url: string, data: any, options?: IBlainApiRequestOptions): Promise<T> {
		return this._makeRequest(url, 'POST', data, options);
	}

	/**
	 * Makes a PUT request using XMLHttpRequest
	 *
	 * @param {string} url - The request URL
	 * @param {any} data
	 * @param {IBlainApiRequestOptions} [options] - Request options
	 * @return {Promise<T>} - A parsed API response
	 */
	put<T>(url: string, data: any, options?: IBlainApiRequestOptions): Promise<T> {
		return this._makeRequest(url, 'PUT', data, options);
	}

	/**
	 * Makes a DELETE request using XMLHttpRequest
	 *
	 * @param {string} url - The request URL
	 * @param {IBlainApiRequestOptions} [options] - Request options
	 * @return {Promise<T>} - A parsed API response
	 */
	async delete<T>(url: string, options?: IBlainApiRequestOptions): Promise<T> {
		return this._makeRequest(url, 'DELETE', null, options);
	}

	/*============================================================
	 == Private Methods
	/============================================================*/

	/**
	 * Makes an AJAX request using the XMLHttpRequest
	 *
	 * @param {string} url - The request URL
	 * @param {string} method  - The request method type
	 * @param {any} data - Data to send as the body of the request
	 * @param {IBlainApiRequestOptions} [options] - Request options
	 * @return {Promise<T>} - A parsed API response
	 */
	private async _makeRequest<T>(
		url: string,
		method: HTTPMethod,
		data?: any,
		options?: IBlainApiRequestOptions
	): Promise<T> {

		let req: XMLHttpRequest;

		try {
			return new Promise<T>((resolve, reject) => {
				req = new XMLHttpRequest();
				req.open(method, url, true);
				req.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
				if (options && options.headers) {
					for (const header in options.headers) {
						req.setRequestHeader(header, options.headers[header]);
					}
				}
				req.onload = () => {
					if (req.status === 200) {
						try {
							const res = req.responseText ? JSON.parse(req.responseText) : '';
							resolve(res);
						} catch (e) {
							const { responseText, statusText, status } = req;
							bugsnagClient.leaveBreadcrumb(`Failed to parse response as JSON: ${getErrorMessage(e)}`, {
								url,
								method,
								data,
								responseText,
								statusText,
								status
							});
							reject(e);
						}
					} else if (req.status === 204) {
						resolve(null);
					} else if (req.status === 405) {
						// This will happen when the bot firewall requires a captcha.
						// the page is reloaded to allow legitimate users to be verified
						window.location.reload();
					} else {
						reject('A network error has occurred');
					}
				};
				// req.onerror = reject(req);
				req.send(data ? JSON.stringify(data) : null);
			});

		} catch (e) {
			e.request = options;
			this._handleError(e);
		}
	}

	/**
	 * Handles any errors caught in _makeRequest
	 *
	 * @param {Error} e - The error
	 */
	private _handleError(e: Error): void {
		// eslint-disable-next-line no-console
		console.error(e);
	}
}