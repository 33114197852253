import bugsnagClient from './bugsnag';

const MIN_SCORE = 1;

/**
 *
 * @param {JQuery<HTMLElement>} $formGroup
 */
export function addPasswordStrengthValidation($formGroup) {
	const passwordStrengthContainer = mountPasswordStrengthBarContainer($formGroup);

	const $input = $formGroup.find('input');

	import(/* webpackChunkName: "zxcvbn" */ "zxcvbn")
		.then(({ default: zxcvbn }) => {
			registerZxcvbnValidatorRule(zxcvbn);
			$input.data('rule-zxcvbn', true);

			const passwordStrengthBar = mountPasswordStrengthBar(passwordStrengthContainer);

			$input.on("input", e => {
				const { value } = e.currentTarget;
				const score = passwordMeetsMinimumRequirements(value)
					? zxcvbn(value).score
					: 0;

				passwordStrengthBar.$props.score = score;
				passwordStrengthBar.$props.showScore = !!value.length;
			});
		})
		.catch(err => {
			bugsnagClient.notify(err);

			// if the above throws at any time, make sure to remove strength validation
			$input.data('rule-zxcvbn', false);
		});
}

/**
 *
 * @param {JQuery<HTMLElement>} $formGroup
 */
function mountPasswordStrengthBarContainer($formGroup) {
	const passwordStrengthContainer = document.createElement("div");

	$(passwordStrengthContainer)
		.addClass('password-strength')
		.insertAfter($formGroup);

	return passwordStrengthContainer;
}

/**
 *
 * @param {HTMLElement} $formGroup
 */
function mountPasswordStrengthBar(container) {
	// TODO: consider making this a single-file-component
	const passwordStrengthBar = new Vue({
		el: container,
		template: `
			<div :class="strengthClasses">
				<div
					v-for="(segment, i) in segments"
					:key="i"
					:class="getSegmentClasses(i)"
				>
					<div class="password-strength__bar"></div>
					<span v-if="showScore && i === score" class="password-strength__label">{{ segment.labelText }}</span>
				</div>
			</div>
		`,
		props: {
			score: {
				type: Number,
				default: 0,
			},
			showScore: {
				type: Boolean,
			}
		},
		computed: {
			strengthClasses() {
				return [
					'password-strength',
					this.segments[this.score].className,
				];
			}
		},
		data: {
			segments: [
				{
					labelText: "Too Weak",
					className: "very-weak"
				},
				{
					labelText: "Fair",
					className: "fair"
				},
				{
					labelText: "Good",
					className: "good"
				},
				{
					labelText: "Strong",
					className: "strong"
				},
				{
					labelText: "Very Strong",
					className: "very-strong"
				}
			],

		},
		methods: {
			getSegmentClasses(index) {
				const classes = ['password-strength__segment'];

				if (this.showScore && index <= this.score) {
					classes.push('password-strength__segment--filled');
				}

				return classes;
			}
		}
	});

	return passwordStrengthBar;
}

function registerZxcvbnValidatorRule(zxcvbn) {
	$.validator.addMethod(
		"zxcvbn",
		function (value) {
			return (!value) || zxcvbn(value).score >= MIN_SCORE;
		},
		"Password not strong enough"
	);
}

export function passwordMeetsMinimumRequirements(password) {
	// test for 8 chars, at least one digit, one lowercase letter, and one uppercase letter
	return /^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9]).*$/.test(password);
}