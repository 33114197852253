<template>
	<modal
		@cancel="$emit('cancel')"
		color-preset="white"
		head-preset="clear"
		head-decor-color="none"
		:loading="loading"
		loading-message="Subscribing..."
	>
		<div class="sign-up-success-wrapper">
			<h2>
				Thank you!
			</h2>
			<p class="main-text">
				You’re all set to know about new arrivals, special events and new sales flyers, plus more more!
			</p>
			<p class="secondary-text">
				Make sure you add newsletter@news.farmandfleet.com to your safe senders list.
			</p>
			<ui-button
				@click="$emit('cancel')"
				color="soft-blue"
				size="md"
				v-focus
				aria-label="You’re all set to know about new arrivals, special events and new sales flyers, plus more more! Continue Shopping."
			>
				Continue Shopping
			</ui-button>
		</div>
	</modal>
</template>

<script>
import focus from '../../../directives/focus';
import Modal from '../../../components/Modal.vue';
export default {
	name: 'SignUpModalSuccess',
	props: {
		loading: Boolean
	},
	components: {
		Modal
	},
	directives: {
		'focus': focus
	},
};
</script>

<style lang="less">
@import '../../../../less/_mixins.less';
.blain-modal-loading {
	padding: 42px 20px 45px 20px;
	.mobile-styles({
		padding: 35px 20px 35px 20px;
	});
}
.sign-up-success-wrapper {
	padding: 10px 45px 45px 45px;
	.mobile-styles({
		padding: 0px 35px 35px 35px;
	});
	h2, p, a {
		font-family: 'Roboto';
	}
	h2 {
		font-weight: 500;
		margin-bottom: 18px;
		.ms-styles({
			margin-bottom: 15px;
			font-size: 26px;
		});
	}
	p {
		color: #656E97;
		line-height: 28px;
		.ms-styles({
			line-height: 20px;
		});
		&.main-text {
			font-size: 18px;
			.ms-styles({
				font-size: 16px;
			})
		}
		&.secondary-text {
			font-size: 12px;
		}
	}
}
</style>