
import { createRadioBase } from './BRadioBase';
import { defineComponent } from 'vue';

const { withRadioBaseProps, useRadioBase } = createRadioBase();

export default defineComponent({
	name: 'BRadioButton',
	props: {
		...withRadioBaseProps(),
		basicLabel: String,
	},
	setup(props, { emit }) {
		return {
			...useRadioBase(props, emit),
		};
	}
});
