// TODO remove all of these, especially those with conflicts with the standard
// Need to do a full-text search for all of them, because some are used in inline scripts
// in razor files.
//
// ALSO need to make sure that none of our custom scripts in Google Tag Manager rely on any of these
//
// See BFF-973 and BFF-974

String.prototype.trim = function() {
	return this.replace(/^\s+|\s+$/g, "");
};

String.prototype.toInteger = function() {
	return (isNaN(this)) ? 0 : parseInt(this, 10);
};

String.prototype.toCurrency = function () {
	let num = this.toString().replace(/\$|\,/g, '');
	if (isNaN(num)) return "0.00";
	num = Math.floor(num * 100 + 0.50000000001);
	let cents = num % 100;
	num = Math.floor(num / 100).toString();
	if (cents < 10) cents = cents.toString().padLeft(2);
	num = num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return num + "." + cents;
};


String.prototype.htmlEncode = function () {
	return String(this)
		.replace(/& /g, '&amp; ')
		.replace(/"/g, '&quot;')
		.replace(/'/g, '&#39;')
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;');
};

String.prototype.htmlDecode = function () {
	return String(this)
		.replace(/&quot;/g, '"')
		.replace(/&#39;/g, "'")
		.replace(/&lt;/g, '<')
		.replace(/&gt;/g, '>')
		.replace(/&amp;/g, '&');
};

String.prototype.padLeft = function (len) {
	let character = "0";
	let str = this;
	if (arguments > 1) character = arguments[1];
	for (let x = str.length; x < len; x++) str = character + str + "";
	return str;
};

String.prototype.padRight = function (len) {
	let character = "0";
	let str = this;
	if (arguments > 1) character = arguments[1];
	for (let x = str.length; x < len; x++) str += character + '';
	return str;
};

// Ensures a string is a url local to the current host. Based off of method in ASP.NET MVC stack
String.prototype.isLocalUrl = function() {
	const url = this;
	return url.length > 0 && ((url.charAt(0) === "/" && (url.length === 1 || (url.charAt(1) !== "/" && url.charAt(1) !== "\\"))));
};

String.prototype.capitalize = function() {
	return this.charAt(0).toUpperCase() + this.slice(1);
};

// Needs in order to support one of the third party library in IE11 that uses .string.includes()
if (!String.prototype.includes) {
	String.prototype.includes = function(search, start) {
		'use strict';
		if (typeof start !== 'number') {
			start = 0;
		}
		if (start + search.length > this.length) {
			return false;
		} else {
			return this.indexOf(search, start) !== -1;
		}
	};
}