<template>
	<div class="notif-summary-wrapper">
		<div
			:class="['notif-content-wrapper', { 'viewed': isViewed }, { 'clicked': isClicked }]"
			role="button"
			tabindex="0"
			@click="notifClick"
			@keydown.enter="notifClick"
		>
			<div class="image">
				<div v-if="notification.imageClass" :class="notification.imageClass"></div>
				<picture v-else>
					<source :srcset="notification.imageUrlWebp" type="image/webp" />
					<source :srcset="notification.imageUrl" type="image/jpeg" />
					<img :src="notification.imageUrl" alt="Notification Image" loading="lazy" />
				</picture>
			</div>
			<div class="content">
				<div class="main-text">
					<p v-if="notification.subject" class="subject">
						{{ notification.subject }}
					</p>
					<p v-if="notification.body" class="body">
						{{ notification.body }}
					</p>
				</div>
			</div>
		</div>
		<div
			v-if="isActive && notification.products.length > 0"
			class="expandable-content"
		>
			<a
				class="product-notif"
				v-for="p in notification.products"
				:key="p.id"
				:href="p.productUrl"
			>
				<div class="product-img" v-if="p.imageUrl">
					<picture>
						<source v-if="p.imageUrlWebp" :srcset="p.imageUrlWebp" type="image/webp" />
						<source :srcset="p.imageUrl" type="image/jpeg" />
						<img :src="p.imageUrl" :alt="`${p.compositeName} image`" />
					</picture>
				</div>
				<div class="price">
					<div v-if="p.currentPrice%p.regularPrice == 0" class="reg-price">
						{{ p.currentPrice.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
					</div>
					<div v-else>
						<div class="original-price">{{ p.regularPrice.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</div>
						<div class="sale-price">{{ p.currentPrice.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</div>
					</div>
				</div>
				<div class="product-name" v-if="p.compositeName">
					{{ p.compositeName }}
				</div>
			</a>
		</div>
	</div>
</template>

<script>
export default {
	name: 'NotificationSummary',
	props: {
		notification: {
			type: Object,
			required: true
		},
		activeId: Number,
		viewedLocally: Boolean
	},
	computed: {
		isActive() {
			return this.activeId === this.notification.id;
		},
		isClicked() {
			return this.notification.viewed != null;
		},
		isViewed() {
			return this.notification.impression != null || this.viewedLocally;
		}
	},
	methods: {
		notifClick() {
			if (this.isActive) {
				return this.$emit('collapse');
			}

			if (!this.isViewed && this.notification.id != undefined) {
				this.$emit('record-notif-click', this.notification.id);
			}

			if (this.notification.linkUrl) {
				this.notification.products.length < 1
					? window.location.replace(this.notification.linkUrl)
					: this.$emit('expand', this.notification);
			} else {
				this.$emit('expand', this.notification);
			}
		}
	}
};
</script>

<style lang='less' scoped>
@import '~@less/_mixins.less';
.notif-summary-wrapper {
	border-bottom: 2px solid #E5E7F1;
	max-width: 100%;
	&:last-child {
		border: none;
	}
	.mobile-styles({
		max-width: 100%;
	});
	.notif-content-wrapper {
		cursor: pointer;
		display: flex;
		align-items: center;
		text-decoration: none;
		padding: 5px;
		background: #ECEEF5;
		&.viewed, &.clicked {
			background: #fff;
		}
	}
	.image {
		width: 60px;
		height: 60px;
		display: flex;
		justify-content: center;

		img {
			width: 60px;
		}

		div {
			height: 100%;
			margin: 0 auto;
			max-width: 35px;
			width: 100%;

			&[class*="svg"] {
				align-self: center;
				height: 22px;
			}
			// icon is a little smaller than svg
			&.icon {
				color: @LB;
				font-size: 26px;
				align-self: center;
				height: 26px;
				min-width: 60px;
			}
		}
	}
	.content {
		margin-left: 10px;
		text-align: left;
		p {
			margin: 0;
			padding: 0;
		}
		.main-text {
			.subject {
				padding: 0;
				margin: 0;
				color: @LB;
				font-size: 14px;
				font-weight: 500;
				line-height: 16px;
				&:hover {
					color: @DB;
					text-decoration: underline;
				}
			}
			.body {
				padding: 0;
				margin: 2px 0 0 0;
				color: #666666;
				font-size: 12px;
				line-height: 14px;
			}
		}
	}
}
.expandable-content {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	.product-notif {
		max-width: 50%;
		cursor: pointer;
		text-decoration: none;
		padding: 5px;
		.mobile-styles({
			margin: 10px;
			max-width: 45%;
		});
		.ms-styles({
			margin: 0;
		});
		.product-img {
			width: 100px;
			height: 100px;
			position: relative;
			margin: 0 auto;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.price {
			margin-top: 5px;
			.reg-price, .sale-price {
				font-weight: bold;
				font-size: 16x;
				line-height: 14px;
			}
			.original-price {
				font-size: 12px;
				text-decoration: line-through;
			}
			.sale-price {
				color: #B12F31;
			}
		}
		.product-name {
			line-height: 18px;
			font-weight: bold;
			font-size: 14px;
			margin-top: 10px;
		}
	}
}
</style>