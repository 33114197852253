export type QueryStringPrimitive = string | number | boolean;
export type QueryStringableObject = Record<string, QueryStringPrimitive | QueryStringPrimitive[]>;
export interface QueryStringOptions {
	/**
	 * Controls the way that query parameters with multiple values are formatted
	 * @example { listFormat: 'comma' } // key=a,b,c
	 *
	 * @example { listFormat: 'separate' } // key=a&key=b&key=c
	 */
	listFormat?: 'comma' | 'separate';

	/**
	 * Controls whether param names with empty values are removed from the query string. Empty values inside of an array are not affected. Defaults to true.
	 *
	 * @example
	 * objectToQueryString({ a: null, b: undefined, c: '', d: '   ', e: []}, { stripTopLevelEmptyValues: true })
	 * // returns ''

	 * objectToQueryString({ a: null, b: undefined, c: '', d: '   ', e: []}, { stripTopLevelEmptyValues: false })
	 * // returns 'a=&b=&c=&d=+++&e='
	 */
	stripTopLevelEmptyValues?: boolean;
}
/**
 * Transforms an object with query-string-serializable values into a query string.
 *
 * **NOTE:** the `URLSearchParams` constructor has an overload that takes a `Record<string, string>`. That may be enough
 * to cover your use-case. Use this function if you're generating a query string from a more complex object or want the
 * configurability documented in `QueryStringOptions`
 *
 * @returns A query string _without_ a leading question mark
 */
export function objectToQueryString(obj: QueryStringableObject, { listFormat = 'comma', stripTopLevelEmptyValues = true }: QueryStringOptions = {}): string {
	return new URLSearchParams(Object.entries(obj).flatMap(([key, value]) => {
		if (stripTopLevelEmptyValues && isValueEmpty(value)) {
			return [];
		}

		if (!Array.isArray(value)) {
			return [[key, value?.toString() ?? '']];
		}

		if (listFormat === 'comma') {
			return [[key, value.map(v => v?.toString()).join(',')]];
		}

		return value.map(v => [key, v?.toString()]);
	})).toString();
}

function isValueEmpty(value: QueryStringPrimitive | QueryStringPrimitive[]): boolean {
	if (Array.isArray(value)) {
		return !value.length;
	}

	if (typeof value === 'boolean') {
		return false;
	}

	if (typeof value === 'string') {
		return !value.trim();
	}

	return value == null;
}