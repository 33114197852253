import { DeviceBreakpoint, ViewportListener } from "../types/Media";

/**
 * This module holds stateful values about the current viewport width and breakpoint.
 * Use the exported utilities to respond to changes to the viewport width and/or breakpoint
 */

/* Setup
============================================*/

// If window is undefined (usually due to server-side rendering), default to desktop
let windowWidth: number = !!window ? window.innerWidth : DeviceBreakpoint.DesktopSmall;
let resizeTimer: ReturnType<typeof setTimeout>;
let currentBreakpoint = getCurrentBreakpoint();
const resizeMethods: ((windowWidth: number) => void)[] = [];
const breakpointMethods: ((breakpoint: DeviceBreakpoint, windowWidth?: number) => void)[] = [];

function callBreakpointListeners(): void {
	const newBreakpoint = getCurrentBreakpoint();
	if (newBreakpoint === currentBreakpoint) return;
	currentBreakpoint = newBreakpoint;
	for (let i = 0; i < breakpointMethods.length; i++) {
		if (typeof breakpointMethods[i] !== 'function') continue;
		breakpointMethods[i](newBreakpoint, windowWidth);
	}
}

function callResizeListeners(): void {
	for (let i = 0; i < resizeMethods.length; i++) {
		if (typeof resizeMethods[i] !== 'function') continue;
		resizeMethods[i](windowWidth);
	}
}

function removeListener<T>(arr: T[], fn: T): void {
	const index = arr.indexOf(fn);
	if (index === -1) return;
	arr.splice(index, 1);
}

if (window) {
	window.addEventListener('resize', () => {
		if (resizeTimer) clearTimeout(resizeTimer);
		resizeTimer = setTimeout(() => {
			windowWidth = window.innerWidth;
			callResizeListeners();
			callBreakpointListeners();
		}, 50);
	});
}

/* Exports
============================================*/

/**
 * Gets the current device breakpoint according to the window size
 */
export function getCurrentBreakpoint(): DeviceBreakpoint {
	if (windowWidth <= DeviceBreakpoint.MobileSmall) return DeviceBreakpoint.MobileSmall;
	if (windowWidth <= DeviceBreakpoint.Mobile) return DeviceBreakpoint.Mobile;
	if (windowWidth <= DeviceBreakpoint.Tablet) return DeviceBreakpoint.Tablet;
	if (windowWidth <= DeviceBreakpoint.DesktopSmall) return DeviceBreakpoint.DesktopSmall;
	return DeviceBreakpoint.Desktop;
}

/**
 * Adds a debounced callback method when the window resize event occurs
 * @param fn Callback method
 */
export function onWindowResize(fn: ((windowWidth: number) => void)): ViewportListener {
	if (resizeMethods.indexOf(fn) > -1) return;
	resizeMethods.push(fn);
	return {
		remove: () => removeListener(resizeMethods, fn)
	};
}


/**
 * Adds a debounced callback method when the current device breakpoint changes
 * @param fn Callback method
 */
export function onBreakpointChange(fn: ((breakpoint: DeviceBreakpoint, windowWidth?: number) => void)): ViewportListener {
	if (breakpointMethods.indexOf(fn) > -1) return;
	breakpointMethods.push(fn);
	return {
		remove: () => removeListener(breakpointMethods, fn)
	};
}

/**
 * Returns true if current window width is less than or equal to the mobile breakpoint
 */
export function isMobileWidth(): boolean {
	return windowWidth <= DeviceBreakpoint.Mobile;
}

/**
 * Returns true if current window width is less than or equal to the tablet breakpoint
 */
export function isTabletWidth(): boolean {
	return windowWidth <= DeviceBreakpoint.Tablet;
}

/**
 * Returns true if current window width is greater than or the largest non-desktop breakpoint
 */
export function isDesktopWidth(): boolean {
	return windowWidth > DeviceBreakpoint.Tablet;
}

export { windowWidth };