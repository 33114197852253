import { render, staticRenderFns } from "./QuickCartProduct.vue?vue&type=template&id=481fb70c&scoped=true&functional=true"
import script from "./QuickCartProduct.vue?vue&type=script&lang=js"
export * from "./QuickCartProduct.vue?vue&type=script&lang=js"
import style0 from "./QuickCartProduct.vue?vue&type=style&index=0&id=481fb70c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "481fb70c",
  null
  
)

export default component.exports