import { effectScope, EffectScope, getCurrentScope, onScopeDispose } from "vue";

/**
 * Wraps an argument-free composable and returns a new composable of the same type. The wrapped composable will only be called once, no matter how many times
 * the returned composable is called (exception being if _all_ copies go out of scope, which is unlikely)
 *
 * Taken nearly verbatim from the [effectScope RFC](https://github.com/vuejs/rfcs/blob/48104bc0a117d0bffee7e6759d4fdd46fd39917b/active-rfcs/0041-reactivity-effect-scope.md)
 * I removed the ability to pass composables with arguments, because the original source in the RFC doesn't have a mechanism to handle subsequent calls with _different_ arguments.
 * That could be resolved by adding some means of memoization, but that'd be more complex than it'd be worth at time of implementation.
 * @param composable
 * @returns
 */
export function createSharedComposable<Fn extends () => any>(composable: Fn): () => ReturnType<Fn> {
	// The disposal code below doesn't work on the server--onScopeDispose never
	// runs--and can result in memory leaks
	if (typeof window === 'undefined') {
		return composable;
	}

	let subscribers = 0;
	let state: ReturnType<Fn>;
	let scope: EffectScope;

	const dispose = () => {
		if (scope && --subscribers <= 0) {
			scope.stop();
			state = scope = null;
		}
	};

	return (() => {
		subscribers++;
		if (!state) {
			scope = effectScope(true);
			state = scope.run(() => composable());
		}
		if (getCurrentScope()) {
			onScopeDispose(dispose);
		}
		return state;
	});
}