var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-field-wrapper',{staticClass:"blain-text-field",attrs:{"field-id":_vm.$id,"error":_vm.errorMessage,"data-test":"blain-form-text-field"},scopedSlots:_vm._u([{key:"default",fn:function({ ariaDescribedBy:errorsDescribedBy }){return [_c('label',{staticClass:"blain-text-field__label",attrs:{"for":_vm.$id}},[_c('span',{staticClass:"medium-bold"},[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),(!_vm.hideRequirementFlag)?[(_vm.required)?_c('span',{staticClass:"blain-text-field__required-notice",attrs:{"aria-hidden":"true"}},[_vm._v(" *required")]):_c('span',{staticClass:"blain-text-field__optional-notice",attrs:{"aria-hidden":"true"}},[_vm._v(" —optional")])]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"relative"},[_c(_vm.multiline ? 'textarea' : 'input',_vm._b({ref:"input",tag:"component",class:[
					'blain-text-field__text-input',
					!!_vm.errorMessage && 'error',
					!!_vm.fieldStateIcon && 'blain-text-field__text-input--has-icon',
					!!_vm.password && !!_vm.fieldStateIcon && 'blain-text-field__text-input--has-two-icons',
					_vm.errorMessage ? 'focusable-error' : ''
				],attrs:{"type":_vm.fieldType,"id":_vm.$id,"name":_vm.name,"aria-required":_vm.required.toString(),"aria-describedby":_vm.ariaDescribedby+' '+errorsDescribedBy,"novalidate":"novalidate"},domProps:{"value":_vm.cleave ? _vm.cleave.properties.result : _vm.value},on:{"input":_vm.handleInput,"blur":_vm.onBlur,"change":function($event){return _vm.$emit('change')},"focus":function($event){return _vm.$emit('focus')}}},'component',_vm.$attrs,false)),_vm._v(" "),(_vm.password)?_c('div',{class:[
					'blain-text-field__field-state-show',
					_vm.fieldStateIcon ? 'blain-text-field__field-state-both' : ''
				],attrs:{"aria-hidden":"true"},on:{"click":_vm.toggleShowPassword}},[_c('icon',{attrs:{"name":_vm.passwordIcon}})],1):_vm._e(),_vm._v(" "),(_vm.fieldStateIcon)?_c('icon',{class:[
					'blain-text-field__field-state-icon',
					_vm.fieldStateIcon.className
				],attrs:{"name":_vm.fieldStateIcon.name}}):_vm._e()],1)]}},(_vm.counterValue != null)?{key:"messages-right",fn:function(){return [_c('div',{class:[
				'blain-text-field__counter',
				_vm.inputLength > _vm.counterValue &&
					'blain-text-field__counter--max-exceeded'
			],attrs:{"aria-label":`${_vm.inputLength} characters entered out of ${_vm.counterValue} allowed`}},[_vm._v("\n\t\t\t"+_vm._s(_vm.inputLength)+"/"+_vm._s(_vm.counterValue)+"\n\t\t")])]},proxy:true}:null],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }