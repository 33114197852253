
import { defineComponent } from 'vue';
import { IconName } from 'JS/constants/icons';
import { PropType } from 'vue';

export default defineComponent({
	props: {
		name: {
			type: String as PropType<IconName>,
			default: "checkmark"
		},
		/**
		 * @deprecated Add context in icon's surroundings instead. This uses aria-label on the internal span, which is valid as of WAI-ARIA 1.1 (though SR support is mixed), but
		 * will not be in WAI-ARIA 1.2. See https://www.w3.org/TR/wai-aria-1.2/#generic
		 */
		label: {
			type: String,
			default: ""
		}
	},
});
