window.Blain = {};

if (window.publicPath) __webpack_public_path__ = window.publicPath;

require('lessRoot/global/index.less');
require('./header.js');
require('jquery-validation');
require('../vendor/blain-plugins.js');
require('../vendor/hawksearch.js');
require('../prototypes/string.js');
require('../prototypes/number.js');
require('../account/account-helpers.js');
require('./adblock.js');
require('./catalog.js');
require('./forms.js');
require('./global-event-listeners.js');
require('./login-register.js');
require('./sliver-banner.js');
require('./polyfills.js');
require('./rewards.js');
require('./footer.js');
require('./icons-list.js');
require('./bugsnag.js');
require('./optimove-newsletter-popup.ts');

import { defineListSliderElement } from '../list-slider';
import { initImages } from './images';
import { initProductCarousels } from './product-carousels';
defineListSliderElement();

Blain.Globals = {
	initted: false,
	init: function () {
		if (Blain.Globals.initted) return;
		Blain.Globals.initted = true;
		initProductCarousels();
		initBreadcrumbs();
		initImages();
		Blain.Login.init();
		Blain.UpdatePassword.init();
		Blain.GlobalEventListeners.init(document);
		Blain.SliverBanner.init();
		Blain.Forms.init();
		Blain.Catalog.init();
		Blain.IconsList.init();
	}
};

/*=======================================================================
== Breadcrumbs
========================================================================*/

const initBreadcrumbs = function() {
	const breadcrumbs = document.getElementById('breadcrumbs');
	if (breadcrumbs) {
		breadcrumbs.scrollLeft = breadcrumbs.scrollWidth;
	}
};

/*=======================================================================
== Blain global constants
========================================================================*/

Blain.customerServicePhone = "1-800-210-2370";

Blain.breakpoints = {
	desktopMin: "screen and (min-width: 1024px)",
	tablet: "screen and (max-width: 1023px)",
	tabletMin: "screen and (min-width: 768px)",
	mobile: "screen and (max-width: 767px)"
};

/*=======================================================================
== Block OnPopState event from firing onload on certain browsers
========================================================================*/

(function () {
	if (!window.addEventListener) return;
	let blockPopstateEvent = document.readyState != "complete";
	window.addEventListener("load", function () {
		setTimeout(function () {
			blockPopstateEvent = false;
		}, 0);
	}, false);
	window.addEventListener("popstate", function (evt) {
		if (blockPopstateEvent && document.readyState == "complete") {
			evt.preventDefault();
			evt.stopImmediatePropagation();
		}
	}, false);
})();

/*=======================================================================
== Custom Event Polyfill
========================================================================*/

(function () {
	function CustomEvent(event, params) {
		params = params || { bubbles: false, cancelable: false, detail: undefined };
		const evt = document.createEvent( 'CustomEvent' );
		evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
		return evt;
	}
	CustomEvent.prototype = window.Event.prototype;
	window.CustomEvent = CustomEvent;
})();

/*=======================================================================
== Modernizr
========================================================================*/

// Detect touch devices
if (!("ontouchstart" in document.documentElement)) {
	document.documentElement.className += " no-touch";
}

/*=======================================================================
== Adblock Detection
========================================================================*/

Blain.AdBlockDetect = new AdBlockDetect({
	checkOnLoad: true,
	resetOnEnd: true
});

Blain.AdBlockDetect.onDetected(function () {
	Blain.AdBlockDetected = true;
});

Blain.AdBlockDetect.onNotDetected(function () {
	Blain.AdBlockDetected = false;
});

/*=======================================================================
== doCSResponse for CyberSource
========================================================================*/

Blain.doCSResponse = function (pageStatus) {
	$(document).trigger("blain.csresponse", pageStatus);
	$("iframe.csiframe").remove();
};

/*=======================================================================
== Global jQuery Document Ready Functions
========================================================================*/

(function ($) {
	$(document).ready(function () {
		// Set user agent data attribute on html
		if (navigator.userAgent.indexOf("MSIE 10.0") > 1) {
			$("html").addClass("ie10");
		};
		Blain.Globals.init();
	});
})(jQuery);