
import BTextField from './BTextField.vue';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { FormatOptions } from './formatting';

/**
 * This import is the primary distinction between this and the BTextField component. It adds a non-neglible amount of kbs to the
 * final bundle. Importing it here intends to make sure that consumers that don't need phone number formatting needn't pay that cost.
 *
 * NOTE: due to how the underlying libraries work, this import has side effects (*grumble*). It is important that this import lives
 * after the BTextField import
 */
import 'cleave.js/dist/addons/cleave-phone.us';

@Component({
	name: 'BPhoneNumberField',
	components: {
		BTextField,
	},
	inheritAttrs: false
})
class BPhoneNumberField extends Vue {
	get format(): FormatOptions {
		return {
			type: 'phone',
		};
	}
}

export default BPhoneNumberField;
