<template>
	<div class="footer-links-list mr">
		<h2>Customer Care</h2>
		<customer-care />
		<div class="footer-divider"></div>
		<a href="/faq/">FAQ</a>
		<a href="/shipping-returns/">Shipping &amp; Returns</a>
		<a href="/best-price-promise/">Best Price Promise</a>
		<a href="/coupons/">Coupons</a>
		<a href="/account/certificate/">Tax Exempt Application</a>
	</div>
</template>

<script>
import CustomerCare from './shared/CustomerCare.vue';
export default {
	name: 'CustomerCareLinks',
	components: {
		CustomerCare
	}
};
</script>