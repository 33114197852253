import BaseHttpService from "./BaseHttpService";
import { INotification } from "../types/Notifications";
import { Response } from "JS/pdp/services/BackInStockService";

export class NotificationService extends BaseHttpService {

	logNotificationClick(notificationID: number | string): Promise<Response> {
		return this.post('/a/notificationClick', { notificationID });
	}

	logNotificationViews(alerts: INotification[]): Promise<Response> {
		return this.post('/a/notificationViews', { alerts });
	}

}

export default new NotificationService();