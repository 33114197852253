
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
	name: 'HorizontalTextDivider',
})
class HorizontalTextDivider extends Vue {
	@Prop({ type: String, default: 'OR' })
	text: string;
}

export default HorizontalTextDivider;
