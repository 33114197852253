<template>
	<div class="notif-wrapper">
		<div class="flex-row flex-between notif-header">
			<div class="flex-row">
				<span class="icon icon-notification-outline"></span>
				<p class="fs-14 no-mb medium-bold">Notifications</p>
			</div>
			<div class="show-mobile">
				<close-btn @close="$emit('close')" />
			</div>
		</div>
		<scrollable-container
			v-if="hasNotifications"
			:max-height="495"
			:include-scroll-hint="shouldScroll"
			@scroll="addNotificationsToBuffer"
		>
			<div v-if="hasGeneralNotifications" class="notifications-list">
				<div class="notif-indicator">
					General
				</div>
				<notification
					v-for="generalNotification in generalNotifications"
					:key="generalNotification.id"
					:active-id="activeNotificationID"
					:notification="generalNotification"
					:viewed-locally="hasBeenViewed(generalNotification)"
					@expand="expand"
					@collapse="collapse"
					@record-notif-click="onNotificationClick"
					ref="notification"
				/>
			</div>
			<div v-if="hasRewardsNotifications" class="notifications-list">
				<div class="notif-indicator">
					Rewards Offers
					<a href="/account/rewards/offers/" class="db-link pull-right">View All</a>
				</div>
				<notification
					v-for="rewardsNotification in rewardsNotifications"
					:key="rewardsNotification.id"
					:active-id="activeNotificationID"
					:notification="rewardsNotification"
					:viewed-locally="hasBeenViewed(rewardsNotification)"
					@expand="expand"
					@collapse="collapse"
					@record-notif-click="onNotificationClick"
					ref="notification"
				/>
				<div class="btn-wrapper">
					<ui-button
						href="/account/rewards/"
						color="soft-blue-outline"
						size="sm"
					>
						More Rewards Offers
						<i class="fa fa-chevron-right" aria-hidden="true"></i>
					</ui-button>
				</div>
			</div>
		</scrollable-container>
		<div v-else class="no-items-wrapper">
			<p class="no-items-text">
				You don’t have any notifications at this time
			</p>
		</div>
	</div>
</template>

<script>
import CloseBtn from 'JS/components/CloseBtn.vue';
import Notification from './Notification.vue';
import notificationService from 'JS/services/NotificationService';
import ScrollableContainer from 'JS/components/ScrollableContainer.vue';
import UiButton from 'JS/components/UiButton.vue';

export default {
	name: 'Notifications',
	props: {
		notifications: {
			type: Array,
			required: true
		},
		scrollThreshold: {
			type: Number,
			default: 6
		},
	},
	components: {
		CloseBtn,
		Notification,
		ScrollableContainer,
		UiButton
	},
	computed: {
		hasNotifications() {
			return this.notifications && this.notifications.length;
		},

		hasGeneralNotifications() {
			return this.generalNotifications.length > 0;
		},

		hasRewardsNotifications() {
			return this.rewardsNotifications.length > 0;
		},

		generalNotifications() {
			if (!this.hasNotifications) return [];
			return this.notifications
				.filter(n => n.notificationType !== this.rewardsGiftCardNotificationType);
		},

		rewardsNotifications() {
			if (!this.hasNotifications) return [];
			return this.notifications.filter(n => n.notificationType === this.rewardsGiftCardNotificationType);
		},

		shouldScroll() {
			return this.notifications.length > this.scrollThreshold;
		},

	},
	data() {
		return {
			activeNotificationID: null,
			showAllNotifications: false,
			viewedNotifications: [],
			timer: null,
			viewedTimer: null,
			rewardsGiftCardNotificationType: 5 // reference to NotificationType enum - RewardsGiftCardIssued value
		};
	},
	methods: {

		expand(notification) {
			if (!notification.products || notification.products.length < 1
					|| this.activeNotificationID === notification.id) return;
			this.activeNotificationID = notification.id;
		},

		collapse() {
			this.activeNotificationID = null;
		},

		onNotificationClick(notificationID) {
			notificationService.logNotificationClick(notificationID);
		},

		addNotificationsToBuffer(event) {
			if (!this.$refs.notification) return;
			clearTimeout(this.viewedTimer);
			this.viewedTimer = setTimeout(() => {
				const notViewed = this.$refs.notification
					.filter(x => this.shouldMarkAsViewed(event.target, x))
					.map(x => x.notification);
				this.markAsViewed(notViewed);
			}, 100);
		},

		shouldMarkAsViewed(container, ref) {
			if (ref.isViewed || ref.isClicked) return false;
			if (this.hasBeenViewed(ref.notification)) return false;
			return this.isVisible(container, ref.$el);
		},

		hasBeenViewed(notification) {
			return this.viewedNotifications.some(x => x.id == notification.id);
		},

		markAsViewed(notifications) {
			if (!notifications || !notifications.length) return;
			this.viewedNotifications = [...this.viewedNotifications, ...notifications];
			notificationService.logNotificationViews(notifications);
		},

		isVisible(container, elem) {
			const cRect = container.getBoundingClientRect();
			const eRect = elem.getBoundingClientRect();
			return eRect.bottom > cRect.top && eRect.top < cRect.bottom;
		}

	},
	mounted() {
		if (!this.notifications.length) return;
		this.markAsViewed(this.notifications.slice(0, this.scrollThreshold));
	}
};
</script>

<style lang='less' scoped>
@import '~@less/_mixins.less';
.notif-wrapper {
	padding: 10px 10px 5px 10px;

	.btn-wrapper {
		padding-top: 5px;

		a {
			max-width: 100%;
			font-size: 14px;
			font-weight: 500;
			margin-top: 5px;
			padding: 3px;
			color: @LB;
			.fa {
				font-size: 8px;
				vertical-align: middle;
			}
		}
	}
}
.notifications-list {
	display: flex;
	flex-direction: column;
	padding-top: 10px;
	overflow: hidden;

	&:first-of-type {
		padding-top: 0;
	}

	.notif-indicator {
		display: block;
		font-size: 10px;
		font-weight: 500;
		color: #717BA9;
		text-align: left;
		text-transform: uppercase;
		letter-spacing: 0.4px;
		line-height: 20px;
		.db-link {
			color: @DB;
			font-size: 12px;
			text-transform: capitalize;
			font-weight: 400;
		}
	}
}
.no-items-wrapper {
	padding-bottom: 10px;
	.no-items-text {
		border-top: 2px solid #E5E7F1;
		font-size: 14px;
		text-align: center;
		color: #666;
		line-height: 20px;
		font-weight: 500;
		padding: 15px 0;
		padding-bottom: 0;
		margin-bottom: 15px;
	}
}
.notif-header {
	background-color: #fff;
	margin-bottom: 10px;
	> div {
		color: #656E97;
		letter-spacing: 0.56px;
		.text {
			margin-top: 5px;
		}
		.icon {
			color: #98A1CA;
			font-size: 24px;
			margin-right: 5px;
		}
	}
}
</style>