
import CloseBtn from './CloseBtn.vue';
import Spinner from './Spinner.vue';
import trapFocus from '../directives/trapFocus';
import { defineComponent, onMounted, ref, watch } from 'vue';
import { getIntialFocusElement } from 'JS/utilities/dom';
import { nextTick } from 'vue';
import { temporarilyApplyStyles } from 'JS/utilities/dom';

export default defineComponent({
	components: {
		Spinner,
		CloseBtn
	},
	name: 'Modal',
	directives: {
		'trap-focus': trapFocus,
	},
	props: {
		closeOnClickOutside: {
			type: Boolean,
			default: true
		},
		loading: Boolean,
		loadingMessage: {
			type: String,
			default: "Please wait"
		},
		size: {
			default: "md",
			type: String
		},
		title: String,
		targetClass: {
			default: "",
			type: String
		},
		colorPreset: String,
		headPreset: {
			type: String,
			default: 'default'
		},
		headDecorColor: {
			type: String,
			default: 'grey'
		},
		icon: String,
		unclosable: Boolean,
		noCloseButton: Boolean,
		rounded: Boolean
	},
	computed: {
		modalClass() {
			// @ts-ignore
			return this.closeOnClickOutside ? 'close-on-click-outside' : '';
		}
	},
	methods: {
		clickedOutside(event) {
			event.target.className.indexOf('close-on-click-outside') > 0
				? this.$emit('cancel') : null;
		},
	},
	setup(props) {
		let focusOnLoad: Element;
		const modalWindow = ref<HTMLElement>(null);

		watch(() => props.loading, (isLoading) => {
			if (isLoading) {
				focusOnLoad = document.activeElement;
			} else {
				if (focusOnLoad) {
					nextTick(() => {
						const el = focusOnLoad as HTMLElement;
						el.focus();
					});
				}
			}
		});

		return {
			focusOnLoad,
			modalWindow
		};
	},
	mounted() {
		document.body.classList.add('modal-open');
		const restoreHtmlStyle = temporarilyApplyStyles(document.body, { overflow: 'hidden' });
		const previouslyFocused = document.activeElement as HTMLElement;
		nextTick(() => {
			const elToFocus = getIntialFocusElement(this.modalWindow);
			if (elToFocus) {
				elToFocus.focus();
			}
		});

		this.$on('hook:beforeDestroy', () => {
			restoreHtmlStyle();
			document.body.classList.remove('modal-open');
			previouslyFocused.focus();
		});
	},
});
