export enum DeviceBreakpoint {
	MobileSmall = 479,
	Mobile = 767,
	Tablet = 1023,
	DesktopSmall = 1339,
	Desktop = Infinity
}

type DeviceBreakpointName = keyof typeof DeviceBreakpoint;

export const DefaultBreakpointDefinition = {
	mobile: DeviceBreakpoint.Mobile as number,
	tablet: DeviceBreakpoint.Tablet as number,
	desktop: Infinity
} as const;

export type ViewportListener = {
	remove(): void
}