import { render, staticRenderFns } from "./TransitionFade.vue?vue&type=template&id=48dcea84&scoped=true"
import script from "./TransitionFade.vue?vue&type=script&setup=true&lang=ts"
export * from "./TransitionFade.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./TransitionFade.vue?vue&type=style&index=0&id=48dcea84&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48dcea84",
  null
  
)

export default component.exports