<template functional>
	<a
		:href="props.product.virtualURL"
		class="product-summary-wrapper flex-row"
	>
		<div
			v-if="props.product.imageURL"
			class="image"
		>
			<picture>
				<source v-if="props.product.imageURLWebp" :srcset="props.product.imageURLWebp" type="image/webp" />
				<source :srcset="props.product.imageURL" type="image/jpeg" />
				<img
					:src="props.product.imageURL"
					:alt="props.product.productName"
					loading="lazy"
				/>
			</picture>
		</div>
		<div class="product-detail">
			<div class="product">
				<div class="price fs-16 flex flex-align-center">
					<p class="price-prefix bold no-mb fs-16 rewards-red" v-if="props.product.price.pricePrefixText">
						{{ props.product.price.pricePrefixText }}
					</p>
					<div class="price-amount-wrapper fs-16 medium-bold">
						${{ props.product.unitPrice.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
						<span v-if="props.product.qty > 1" class="fs-14">ea</span>
					</div>
					<span class="original-price" v-if="props.product.price.originalPrice">
						Was <span>${{ props.product.price.originalPrice }}</span>
					</span>
				</div>
				<div class="product-name fs-14">
					{{ props.product.vendorBrand }} {{ props.product.productName }}
				</div>
				<div class="qty-atts fs-12 medium-bold">
					<div class="qty">
						Qty: {{ props.product.qty }}
					</div>
					<div v-if="props.product.attributes && props.product.attributes.length > 0">
						<div
							class="att"
							v-for="att in props.product.attributes"
							:key="att.attributeDescription"
						>
							{{ att.attributeDescription }}: {{ att.attributeValue }}
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="props.product.warranty"
				class="product-warranties"
			>
				<div class="price">
					<div class="price-amount-wrapper fs-16 medium-bold">
						${{ props.product.warranty.price }}
						<span v-if="props.product.warrantyQty > 1" class="fs-14">ea</span>
					</div>
				</div>
				<div class="warranty-name fs-14">
					{{ props.product.warranty.warrantyLength }} Year {{ props.product.warranty.fixedName }}
				</div>
				<div class="fs-12 medium-bold qty">
					Qty {{ props.product.warrantyQty }}
				</div>
			</div>
		</div>
	</a>
</template>

<script>
export default {
	name: 'QuickCartProduct',
	props: {
		product: {
			required: true,
			type: Object
		}
	}
};
</script>

<style lang='less' scoped>
.product-summary-wrapper {
	background-color: #fff;
	color: #000000;
	margin-bottom: 10px;
	padding: 5px;
	text-decoration: none;
}

.image {
	margin-top: 10px;
	flex: 1 1 120px;
	margin-right: 8px;
}

.product-detail {
	flex: 1 1 250px;
	line-height: 22px;
}


.price {
	> span {
		font-weight: 400;
		color: #656884;
		font-size: 12px;
		padding-top: 1px;
	}
}

.product-name,
.warranty-name {
	color: #656884;
}

.price-amount-wrapper {
	display: inline-block;
}

.price .dollar-sign {
	vertical-align: text-bottom;
	align-self: flex-start;
	padding: 4px 1px 0 0;
}

.price-prefix {
	text-transform: uppercase;
	line-height: 14px;
	margin-right: 5px;
}

.original-price {
	margin-left: 5px;
	span {
		text-decoration: line-through;
	}
}

.product-warranties {
	margin-top: 8px;

	.price-amount-wrapper {
		display: inline-block;
		margin-right: 5px;
	}
	.dollar-sign {
		padding: 0 1px 2px 0;
	}
}
</style>