<template functional>
	<component
		:is="props.tag"
		class="visually-hidden"
		v-bind="data.attrs"
	>
		<slot></slot>
	</component>
</template>

<script>
export default {
	props: {
		tag: {
			type: String,
			default: 'span',
		}
	}
};
</script>