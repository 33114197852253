import app from './index';
import bugsnagClient from '../global/bugsnag';

const headerId = "websiteHeaderVue";
const header = document.getElementById(headerId);

if (header) {
	app.$mount(header);
} else {
	const pagesWithoutHeader = ['/checkout', '/automotive-scheduler'];
	if (!pagesWithoutHeader.find(p => window.location.pathname.startsWith(p))) {
		const message = `Attempted to mount header at "${window.location.pathname}", but root element #${headerId} not found.`;
		// eslint-disable-next-line no-console
		console.warn(message);
		bugsnagClient.notify(new Error(message));
	}
}