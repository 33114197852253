<template>
	<button
		:class="`${btnClass} ${position} no-select tr-btn`"
		@click="$emit('close')"
		role="button"
		aria-label="Close Button"
		tabindex="0"
	>
		<span :class="`icon icon-${icon}`"></span>
	</button>
</template>

<script>
export default {
	name: 'CloseBtn',
	props: {
		btnClass: {
			type: String,
			default: 'close-circle'
		},
		icon: {
			type: String,
			default: 'remove'
		},
		position: {
			type: String,
			default: ''
		}
	}
};
</script>

<style lang="less" scoped>
@import '../../less/_mixins.less';
.close-circle {
	border-radius: 50%;
	border: 2px solid rgba(36, 135, 196, 0.205);
	width: 25px;
	height: 25px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;
	.icon {
		font-weight: lighter;
		color: @LB;
		font-size: 13px;
	}
	&.absolute-right {
		position: absolute;
		top: 5px;
		right: 5px;
	}
}
</style>