<template>
	<div class="footer-store-info">
		<div v-if="!!store">
			<h2 class="fs-12 text-uc">Your Store</h2>
			<div class="footer-store-body mb">
				<div class="store-details">
					<h3 class="fs-16 medium-bold">{{ store.city }}, {{ store.state }}</h3>
					<strong v-if="store.comingSoon" class="green fs-14">
						Be on the lookout...this store is coming soon!
					</strong>
					<base-button
						v-if="store.hoursStatus"
						class="hours-status fs-12 flex-row mb"
						@click="toggleStoreHours"
						:aria-expanded="showStoreHours.toString()"
					>
						<visually-hidden>Current store status:</visually-hidden>
						{{ store.hoursStatus }}
						<visually-hidden>. Press to {{ showStoreHours ? 'hide' : 'show' }} more information</visually-hidden>
						<icon class="fs-10 ml-sm" :name="`chevron-${toggleIcon}`" />
					</base-button>
					<div v-if="showStoreHours" class="store-hours-wrapper">
						<div v-if="shortHours">
							<table>
								<tbody>
									<tr class="fs-12" v-for="(storeHours, day) in shortHours" :key="day">
										<td class="bold black">{{ day }}</td>
										<td class="text-right">{{ storeHours }}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<p v-if="displayText" :class="['store-message', storeDisplayStyle]">
							{{ displayText }}
						</p>
					</div>
				</div>
				<div class="footer-divider"></div>
				<div class="links-wrapper">
					<a :href="storePhoneLink">
						Phone: {{ store.phone }}
					</a>
					<a v-if="storeDetailsUrl" :href="storeDetailsUrl">
						View Store Details
					</a>
				</div>
			</div>
			<a href="/store-finder/" class="footer-store-btn block fs-14 text-center">
				Find Other Stores
			</a>
		</div>
	</div>
</template>

<script>
import BaseButton from 'Src/js/components/BaseButton.vue';
import Icon from 'Src/js/components/Icon.vue';
import VisuallyHidden from 'Src/js/components/VisuallyHidden.vue';
export default {
	name: 'StoreSummary',
	components: {
		Icon,
		VisuallyHidden,
		BaseButton,
	},
	props: {
		store: {
			type: Object,
		}
	},
	computed: {
		displayText() {
			if (!this.store.currentHours) return;
			return this.store.currentHours.displayText;
		},
		isDesktop() {
			return !this.isTablet && !this.isMobile;
		},
		shortHours() {
			const daysArr = Object.keys(this.store.hours);
			const hoursArr = Object.values(this.store.hours);
			const obj = {};
			const shortDays = daysArr.map(d => d.includes('-')
				? d.split('-').map(el => el.slice(0,1)).join('–')
				: d.slice(0,3)
			);
			for (let i = 0;i < daysArr.length; i++) {
				obj[shortDays[i]] = hoursArr[i];
			}
			return obj;
		},
		storeDisplayStyle() {
			if (!this.store.currentHours) return;
			return this.store.currentHours.displayStyle || '';
		},
		storeDetailsUrl() {
			return this.store.urlAlias ? `/stores/${this.store.urlAlias}` : null;
		},
		storePhoneLink() {
			return this.store && this.store.phone ? `tel:+1${this.store.phone.replace(/\D/g, '')}` : null;
		},
		toggleIcon() {
			return this.showStoreHours ? 'up' : 'down';
		},
	},
	data() {
		return {
			showStoreHours: false,
		};
	},
	methods: {
		toggleStoreHours() {
			this.showStoreHours = !this.showStoreHours;
		}
	},
};
</script>

<style lang='less'>
@import '../../../less/_mixins.less';
.footer-store-info {
	max-width: 180px;
	width: 100%;

	h2, h3 {
		color: #4C5271;
	}

	h2 {
		margin-bottom: 8px;
	}

	table {
		max-width: 150px;
		width: 100%;
		color: #666;
	}

	td {
		padding: 3px 0;
	}

	a.footer-store-btn {
		border: solid 2px #A7CFE7;
		border-radius: 6px;
		color: @DB;
		padding: 0.6rem;
		transition: all 0.2s;

		&:hover {
			text-decoration: none;
			border-color: #2487c4;
		}
	}
}

.footer-store-body {
	border: solid 2px #E5E7F1;
	border-radius: 6px;
	padding: 10px;

	.footer-divider {
		background-color: #E5E7F1;
	}

	.hours-status{
		font-weight: 500;
		color: #008040;
		cursor: pointer;
	}
}
</style>