
import HeaderDropMenu from './HeaderDropMenu.vue';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
	name: 'HasMenu',
})
export default class HasMenu extends Vue {

	$refs: {
		menu: HeaderDropMenu
	}


	/* Methods
	============================================*/

	hideMenu() {
		this.$refs.menu?.hide();
	}

	openMenu() {
		this.$refs.menu?.show();
	}


}

