import vueEventBus from '../global/vue-event-bus';
import { EventBusEvent, EventBusEventMap, EventBusEvents, IEventBusService } from '../types/EventBus';
import { IQuickCartSummaryJson } from '../types/Order';

export default class EventBusService implements IEventBusService {

	constructor() {
		if (window && !window.vueEventBus) window.vueEventBus = vueEventBus;
	}

	/** @deprecated Use `emit` with the relevant event type and payload instead */
	updateQuickCart(cartSummary: IQuickCartSummaryJson): void {
		if (!cartSummary) return;
		this.emit(EventBusEvents.UpdateQuickCart, cartSummary);
	}

	/** @deprecated Use `on` with the relevant event type instead */
	listenOnLoginSuccess(callback: () => void): void {
		this.on(EventBusEvents.LoginSuccess, callback);
	}

	emit<T extends EventBusEvent>(
		event: T,
		...payload: EventBusEventMap[T] extends never ? [] : [EventBusEventMap[T]]
	): void
	emit<T extends EventBusEvent>(event: T, payload: EventBusEventMap[T]): void {
		window.vueEventBus.$emit(event, payload);
	}

	on<T extends EventBusEvent>(
		event: T,
		callback: EventBusEventMap[T] extends never ? (() => void) : ((payload: EventBusEventMap[T]) => void)
	) {
		window.vueEventBus.$on(event, callback);
	}

	off<T extends EventBusEvent>(
		event: T,
		callback: EventBusEventMap[T] extends never ? (() => void) : ((payload: EventBusEventMap[T]) => void)
	) {
		window.vueEventBus.$off(event, callback);
	}
}