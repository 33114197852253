import BaseHttpService from "JS/services/BaseHttpService";
import { IBaseHttpService } from "JS/types/Http";
import { IEmailSubscriptionResponse } from "JS/types/EmailSubscription";

export interface IEmailSubscriptionService {
	subscribeEmailToMarketing(emailAddress: string): Promise<IEmailSubscriptionResponse>;
	
}

export default class EmailSubscriptionService implements IEmailSubscriptionService {
	constructor(private _httpService: IBaseHttpService = new BaseHttpService()) {}

	async subscribeEmailToMarketing(emailAddress: string): Promise<IEmailSubscriptionResponse> {
		return await this._httpService.post<IEmailSubscriptionResponse>(
			`/email/marketing/subscribe`, 
			{ 
				EmailAddress: emailAddress
			});
	}

}