<template>
	<modal
		@cancel="$emit('collapse')"
		:title="modalTitle"
		color-preset="white"
		head-preset="clear"
	>
		<div class="list-items" v-if="activeLink === 'services'">
			<a href="/automotive-service/">Automotive Service</a>
			<a href="/store-pickup/">Drive Thru Pickup</a>
			<a href="/same-day-local-delivery/">Same Day Local Delivery</a>
			<a href="/farms/">FARMS Service</a>
			<a href="/animalrx/">Blain's Animal Rx</a>
			<a href="/gift-registry/">Registries &amp; Lists</a>
			<a href="/gift-cards/">Gift Cards</a>
			<a href="/gifts/">Gift Guide</a>
			<a href="/best-program/">Extended Service Program</a>
			<a href="/small-engine-repair-and-parts/">Small Engine Repair</a>
			<a href="/fishing-hunting-licenses/">Fishing &amp; Hunting Licenses</a>
			<a href="/rebates/">Rebates</a>
			<a href="/blog/vip-petcare-vaccinations-at-blains-farm-fleet/">VIP Pet Care</a>
			<a href="/other-services/">Other Store Services</a>
		</div>
		<div class="list-items" v-else-if="activeLink === 'company'">
			<a href="/about-us/" v-focus>About Us</a>
			<a href="/history/">Our History</a>
			<a href="/rewards/">Blain's Rewards</a>
			<a href="/blog/">Blain's Blog</a>
			<a href="/community/">Community Involvement</a>
			<a href="https://careers.farmandfleet.com/" target="_blank" rel="noopener">Careers at Blain's</a>
			<a href="https://realestate.farmandfleet.com/" target="_blank" rel="noopener">Real Estate</a>
			<a href="/modeling/">Modeling</a>
			<a href="/blog/category/events-news/">News</a>
			<a href="/mobile-app/">Mobile App</a>
		</div>
		<div class="list-items list-border" v-else-if="activeLink === 'customer-care'">
			<a href="/faq/" v-focus>FAQ</a>
			<a href="/shipping-returns/">Shipping &amp; Returns</a>
			<a href="/best-price-promise/">Best Price Promise</a>
			<a href="/coupons/">Coupons</a>
			<a href="/account/certificate/">Tax Exempt Application</a>
		</div>
	</modal>
</template>

<script>
import focus from '../../../directives/focus';
import Modal from '../../../components/Modal.vue';

export default {
	name: 'NavLinksModal',
	props: {
		activeLink: String
	},
	directives: {
		'focus': focus
	},
	components: { Modal },
	computed: {
		modalTitle() {
			switch (this.activeLink) {
				case 'services':
					return 'Services';
				case 'company':
					return 'Our Company';
				case 'customer-care':
					return 'Customer Care';
				default:
					return 'services';
			}
		}
	},
};
</script>

<style lang='less' scoped>
@import '../../../../less/_mixins.less';
.list-items {
	padding-bottom: 5px;
	a {
		margin: 0 10px;
		padding: 10px;
		display: block;
		text-decoration: none;
		border-top: 2px solid #E5E7F1;
		color: @DB;
		font-size: 14px;
		font-weight: 500;
		line-height: 19px;
		&:first-child {
			border: none;
		}
	}
}
</style>