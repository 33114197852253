<template functional>
	<div
		v-bind="data.attrs"
		v-on="listen"
		:class="['mobile-app-callout mb', data.class, data.staticClass]"
	>
		<div>
			<div class="flex-row block-mobile">
				<div class="mac-content mr">
					<div class="mac-header">
						<p>Blain’s Farm &amp; Fleet Mobile App</p>
					</div>
					<div class="flex-row">
						<div class="mac-logo mr">
							<div class="icon icon-blain-eagle"></div>
						</div>
						<div class="mac-text">
							<p class="no-mb">The savings, value, &amp; service you trust—right inside your pocket!</p>
						</div>
					</div>
				</div>
				<div class="mac-button mt">
					<ui-button color="soft-blue-outline" href="/mobile-app/">
						More About the App <span class="icon icon-chevron-right"></span>
					</ui-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MobileAppCallout',
};
</script>

<style lang='less'>
@import '../../../../less/_mixins.less';
.mobile-app-callout {
	background-color: #FFF;
}

.mobile-app-callout > div {
	max-width: 660px;
	margin: 0 auto;
	padding: 20px;
	border-top: 2px solid #E5E7F1;
	.mobile-styles({
		max-width: 295px;
		padding: 20px 0;
	});
}

.mac-content {
	flex: 0 0 300px;
	.mac-header p {
		color: #4C5271;
		font-weight: 200;
		margin-bottom: 5px;
	}
	.mac-logo {
		border-radius: 4px;
		box-shadow: 2px 2px 4px #00000029;
		background: #e12830;
		.icon {
			font-size: 40px;
			color: #fff;
		}
	}
	.mac-text {
		p {
			color: #656E97;
			font-size: 15px;
		}
	}
}

.mac-button a.ui-btn {
	background: transparent;
	max-width: 220px;
	font-weight: bold;
	&:hover {
		text-decoration: none;
	}
	.tablet-styles({
		font-size: 16px;
	});
	.mobile-styles({
		font-size: 14px;
	});
}
</style>