import { onUnmounted, Ref, watch } from "vue";
import { preventBodyScroll } from "JS/utilities/dom";

/**
 * Makes the page unscrollable when `shouldPrevent.value` is true and re-enables scrolling when false.
 * Ensures the the page is scrollable after the consuming component
 */
export function usePreventBodyScroll(shouldPrevent: Ref<boolean>) {
	let restoreBodyScroll: (() => void) | null = null;
	watch(shouldPrevent, prevent => {
		restoreBodyScroll?.();
		restoreBodyScroll = null;
		if (prevent) {
			restoreBodyScroll = preventBodyScroll();
		}
	}, { immediate: true });

	onUnmounted(() => restoreBodyScroll?.());
}
