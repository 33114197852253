// TODO: remove and add a normal function instead
// Need to do a full-text search for all of them, because some are used in inline scripts
// in razor files.
//
// ALSO need to make sure that none of our custom scripts in Google Tag Manager rely on any of these
//
// See BFF-973 and BFF-974

Number.prototype.toCurrency = function() {
	return this.toString().toCurrency();
};
