<template>
	<div class="store-finder-wrapper">
		<div class="flex block-mobile">
			<div class="sfw-store-details">
				<div class="sfw-head flex-row flex-between">
					<div class="flex-row">
						<div class="sfw-head-text">
							<i class="fa fa-check green"></i>My Store
						</div>
						<ui-button
							color="soft-blue-outline"
							size="sm"
							href="/store-finder/"
						>
							Find Other Stores
						</ui-button>
					</div>
					<div class="show-mobile">
						<close-btn @close="$emit('close')" />
					</div>
				</div>
				<div class="sfw-body" v-if="!mapSwitch">
					<div class="sfw-store-address">
						<div class="flex flex-between">
							<h3>{{ store.cityAlias }}, {{ store.state }}</h3>
							<span class="sfw-switch-link fs-14 medium-bold hide-desktop text-right pointer" @click="toggleMap">
								{{ mapSwitchText }}
							</span>
						</div>
						<p>{{ store.address1 }}</p>
						<p>{{ store.city }}, {{ store.state }} {{ store.zip }}</p>
					</div>
					<div v-if="store.comingSoon" style="margin-top: 5px;">
						<strong class="green fs-14" style="line-height: 16px;">
							Be on the lookout...this store is coming soon!
						</strong>
					</div>
					<div v-else-if="!store.comingSoon" class="sfw-store-hours-wrapper">
						<p v-if="store.hoursStatus" class="sfw-hours-message medium-bold fs-14 rewards-red">
							{{ store.hoursStatus }}
						</p>
						<div v-if="store.hours">
							<div
								v-for="(storeHours, day) in store.hours"
								:key="day"
								class="sfw-store-hours flex-row flex-between"
							>
								<span class="day fs-12 bold">
									{{ day }}
								</span>
								<span class="hours fs-14">
									{{ storeHours }}
								</span>
							</div>
						</div>
						<p
							v-if="store.currentHours && store.currentHours.displayText"
							:class="`sfw-store-message medium-bold fs-14 rewards-red ${storeDisplayStyle}`"
						>
							{{ store.currentHours.displayText }}
						</p>
					</div>
				</div>
				<div class="sfw-footer" v-if="!mapSwitch">
					<a :href="storePhoneLink" class="blue-link fs-14 medium-bold">
						Phone: {{ store.phone }}
					</a>
					<a v-if="storeDetailsUrl" :href="storeDetailsUrl" class="blue-link fs-14 medium-bold">
						View Store Details
					</a>
				</div>
			</div>
			<div class="sfw-google-map-container" v-if="showMap">
				<div
					v-if="mapSwitch"
					class="text-right sfw-switch-link fs-14 medium-bold hide-desktop text-right pointe"
					@click="toggleMap"
				>
					Store Details
				</div>
				<div class="sfw-google-map">
					<iframe
						allowfullscreen
						height="270px"
						:width="isMobile() ? '100%' : '270px'"
						:src="`
							//www.google.com/maps/embed/v1/place
							?q=place_id%3a${store.googlePlaceID}
							&amp;key=${googleApiKey}
							&zoom=${store.googleMapZoom}
						`"
					></iframe>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CloseBtn from 'JS/components/CloseBtn.vue';
import UiButton from 'JS/components/UiButton.vue';
export default {
	name: 'StoreFinder',
	components: {
		CloseBtn,
		UiButton
	},
	props: {
		googleApiKey: {
			required: true,
			type: String
		},
		store: {
			required: true,
			type: Object
		}
	},
	computed: {
		storeDisplayStyle() {
			return this.store.currentHours && this.store.currentHours.displayStyle
				? this.store.currentHours.displayStyle : '';
		},
		mapSwitchText() {
			return this.mapSwitch ? 'Details' : 'Map';
		},
		showMap() {
			return !this.isMobile() || (this.mapSwitch);
		},
		storeDetailsUrl() {
			return this.store.urlAlias ? `/stores/${this.store.urlAlias}` : null;
		},
		storePhoneLink() {
			return this.store && this.store.phone ? `tel:+1${this.store.phone.replace(/\D/g, '')}` : null;
		}
	},
	data() {
		return {
			mapSwitch: false
		};
	},
	methods: {
		isMobile() {
			return window.innerWidth < 768;
		},
		toggleMap() {
			this.mapSwitch = !this.mapSwitch;
		}
	}
};
</script>

<style lang='less'>
@import '~@less/_mixins.less';
.store-finder-wrapper {
	p {
		margin: 0;
		padding: 0;
	}
	p, h3 {
		font-family: 'Roboto';
	}
}

.sfw-google-map-container {
	flex: 0 0 270px;
	margin: 15px 17px;
	position: relative;
	.mobile-styles({
		padding: 0 15px;
		margin: 0;
		flex: 1 1 320px;
	});
}

.sfw-google-map {
	.aspect-ratio(1, 1);
	iframe {
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;
	}
}

.sfw-switch-link {
	line-height: 25px;
	color: @LB;
}

.sfw-store-details {
	flex: 1;
	padding: 10px 10px 20px 10px;
}

.sfw-head {
	padding-bottom: 8px;
	padding-left: 5px;
	align-content: center;
	border-bottom: 1px solid #ccc;
	a.ui-btn {
		max-width: 120px;
		font-size: 11px;
		padding: 3px 5px;
		max-height: 24px;
		.mobile-styles({
			padding: 4px;
		});
	}
}

.sfw-head-text {
	margin-right: 15px;
	font-size: 12px;
	font-weight: 500;
	white-space: nowrap;
	color: #008040;
	.fa {
		font-size: 14px;
		margin-right: 4px;
	}
}

.sfw-body {
	margin-top: 10px;
	border-bottom: 1px solid #ccc;
	padding-bottom: 10px;
	padding-left: 5px;
}

.sfw-store-address {
	h3 {
		font-size: 18px;
		font-weight: bold;
		color: #000;
		margin: 0 0 2px 0;
		padding: 0;
		line-height: 1.25em;
	}
	p {
		color: #666666;
		font-size: 14px;
		line-height: 16px;
		font-weight: 400;
	}
}

.sfw-footer {
	margin-top: 5px;
	padding-left: 5px;
	.blue-link {
		display: block;
		margin-top: 5px;
		text-decoration: none;
		color: @LB;
		text-align: left;
		&:hover {
			text-decoration: underline;
			color: #014A77;
		}
		.tablet-styles({
			margin-top: 10px;
		});
	}
}

.sfw-store-hours-wrapper {
	p {
		margin: 6px 0;
		&.sfw-store-message {
			margin: 3px 0 0 0;
		}
	}
}

.sfw-store-hours {
	max-width: 190px;
	line-height: 20px;
	.day {
		color: #000000;
	}
	.hours {
		color: #666666;
		font-weight: 400;
	}
}
</style>