import BlainBaseService from "./BlainBaseService";
import { ILinkRewardsUser, ILinkRewardsUserResponse } from "JS/types/rewards";

export interface IRewardsService {
	linkUser(linkUserObject: ILinkRewardsUser): Promise<ILinkRewardsUserResponse>;
	joinRewards(phoneNumber: string): Promise<ILinkRewardsUserResponse>;
	confirmUserCode(code: number | string): Promise<ILinkRewardsUserResponse>; 
}

export default class RewardsService extends BlainBaseService implements IRewardsService {
	
	async linkUser(linkUserObject: ILinkRewardsUser): Promise<ILinkRewardsUserResponse> {
		return await this._httpSvc.post<ILinkRewardsUserResponse>("/account/linkrewardsaccount", linkUserObject);
	}

	async joinRewards(phoneNumber: string): Promise<ILinkRewardsUserResponse> {
		return await this._httpSvc.post<ILinkRewardsUserResponse>("/account/newrewardsaccount", { phoneNumber });
	}

	async confirmUserCode(code: number | string): Promise<ILinkRewardsUserResponse> {
		return await this._httpSvc.post<ILinkRewardsUserResponse>("/account/confirmlinkaccount", { code: Number(code) });
	}
}