import { getCookie } from "../utilities/cookie.utils";
import { IHawkSuggestionsResult } from "../types/HawkSearch";
import { makeXmlHttpRequest } from "../utilities/ajax";

export class HawkSearchService {

	private _baseUrl: string;

	constructor(baseUrl?: string) {
		this._baseUrl = baseUrl ? baseUrl :
			process.env.NODE_ENV === 'production'
				? 'https://blain.lusearchapi-na.hawksearch.com/sites/blain2016/'
				: 'https://blain.dev.lusearchapi-na.hawksearch.com/sites/blain2016/';
	}

	public getSuggestions(query: string, visitorId?: string): Promise<IHawkSuggestionsResult> {
		let url = `${this._baseUrl}?fn=ajax&f=GetSuggestions&q=${query.trim()}`;
		const vid = visitorId ? getCookie('visitor_id') : null;
		if (vid) url += `&hawkvisitorid=${vid}`;
		return new Promise((resolve, reject) => {
			makeXmlHttpRequest('GET', url).then(responseText => {
				try {
					const result = this.parseGetSuggestionsResponse(responseText);
					resolve(result);
				} catch (e) {
					reject(e);
				}
			}).catch((e) => reject(e));
		});
	}

	public setBaseUrl(baseUrl: string): void {
		this._baseUrl = baseUrl;
	}

	/* Private Methods
	============================================*/

	private parseGetSuggestionsResponse(responseText: string): IHawkSuggestionsResult {
		// The responseText will surrounded in parentheses since it is assumed to be a callback
		// Strip the parentheses of the response and just return the parsed json
		const normalized = responseText.replace(/^[\(]|[\)]$/g, '');
		const suggestions = JSON.parse(normalized) as IHawkSuggestionsResult;
		if (suggestions?.Products?.length > 0) {
			for (let x = 0; x < suggestions.Products.length; x++) {
				const product = suggestions.Products[x];
				if (product.Url) product.Url = product.Url.replace("https://www.farmandfleet.com/", "/");
			}
		}
		return suggestions;
	}
}

export default new HawkSearchService();