import BlainBaseService from "JS/services/BlainBaseService";

export interface ITireSizeLookupService {
	getSectionWidths(): Promise<number[]>
	getAspectRatios(width: number): Promise<number[]>
	getRimSizes(width: number, rRatio: number): Promise<number[]>
}

export default class TireSizeLookupService extends BlainBaseService implements ITireSizeLookupService {

	private readonly BASE_URL = '/tire-size'

	async getSectionWidths(): Promise<number[]> {
		return this._httpSvc.get(`${this.BASE_URL}/section-widths/`);
	}

	async getAspectRatios(width: number): Promise<number[]> {
		return this._httpSvc.get(`${this.BASE_URL}/aspect-ratios/?width=${width}`);
	}

	async getRimSizes(width: number, ratio: number): Promise<number[]> {
		return this._httpSvc.get(`${this.BASE_URL}/rim-sizes/?width=${width}&ratio=${ratio}`);
	}

}