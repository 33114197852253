import { computed, onUnmounted, readonly, Ref, ref, watch } from 'vue';

export function useIntersectionObserver(element: Ref<HTMLElement>, optionsFactory: () => IntersectionObserverInit = () => ({})) {
	const isIntersecting = ref(false);
	let observer: IntersectionObserver | null = null;
	const options = computed(optionsFactory);

	watch(() => [options.value, element.value] as const, ([opts, el]) => {
		if (observer) {
			observer.disconnect();
			observer = null;
		}

		if (!el) {
			return;
		}

		observer = new IntersectionObserver(
			(entries, _observer) => {
				entries.forEach(entry => {
					isIntersecting.value = entry.isIntersecting;
				});
			},
			opts,
		);

		observer.observe(element.value);
	}, {
		immediate: true,
	});

	onUnmounted(() => {
		observer?.disconnect();
	});

	return { isIntersecting: readonly(isIntersecting) };
}

