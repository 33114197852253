
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		href: {
			type: String,
			default: null,
		},

		/**
		 * This component adds sane base styling by default. `noReset` allows disabling it in
		 * certain cases, ex: LinkButton.vue --- uses a global class that has its own reset for use
		 * outside of Vue.
		 */
		noReset: {
			type: Boolean,
		}
	}
});
