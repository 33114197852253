const htmlEl = (typeof document !== 'undefined')
	? document.getElementsByTagName('html')[0]
	: null;

export function setMenuOpenClasses(shadowed: boolean): void {
	if (!htmlEl) return;
	setTimeout(() => {
		htmlEl.classList.add('menu-open');
		if (!shadowed) return;
		document.body.classList.add('shadowed');
	}, 25);
}

export function setModalOpenClasses(shadowed: boolean): void {
	if (!htmlEl) return;
	setTimeout(() => {
		htmlEl.classList.add('modal-open');
		if (!shadowed) return;
		document.body.classList.add('shadowed');
	}, 25);
}

export function unsetOpenClasses(): void {
	if (!htmlEl) return;
	htmlEl.classList.remove('menu-open');
	htmlEl.classList.remove('modal-open');
	document.body.classList.remove('shadowed');
}

/* toyland date range checking for 2024 */

export function christmasGuide(nowDate: Date): boolean {
	const start = new Date(2024, 10, 16); // November 16

	return start <= nowDate;
}

export function toylandShop(nowDate: Date): boolean {
	const start = new Date(2024, 9, 20); // October 20
	const end = new Date(2024, 11, 26); // December 26

	return start <= nowDate && nowDate <= end;
}
