import { FilterKeysByValueType } from "JS/types/utility";

export const getFocusableElements = (parent: HTMLElement): NodeListOf<HTMLElement> => {
	return parent.querySelectorAll('a[href]:not([disabled]), [tabindex]:not([tabindex="-1"]):not([disabled]), [role=button]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
};

/**
 * Returns first focusable element within component with class of "focus-intial"
 * Or first focusable element if that does not exist.
 * @param parent
 */
export const getIntialFocusElement = (parent: Element): HTMLElement => {
	let focusEl = parent.getElementsByClassName("focus-initial")[0] as HTMLElement;
	if (!focusEl) {
		focusEl = parent as HTMLElement;
	}
	const focusableChild = getFocusableElements(focusEl)[0];
	if (focusableChild) {
		return focusableChild;
	}
	return focusEl; //if no focusable children found, try to focus on the element itself.
};

/**
 * Updates the content of a meta tag of a given type. If the tag doesn't exist, it will be created;
 * If the tag exists but the new content is empty, the tag will be removed
 *
 * @param type The meta tag type, eg. "keywords"
 * @param content Value for the meta tag's content attribute
 * @param typeAttribute Name of the attribute where the meta tag's type is set, eg. "name" for normal
 * 						meta tags, "property" for og tags
 */
export function updateMetaTag(type: string, content: string, typeAttribute = "name"): void {
	const head = document.querySelector('head');

	let tag = document.querySelector(`meta[${typeAttribute}="${type}"]`);

	if (!content) {
		if (tag) {
			head.removeChild(tag);
		}
		return;
	}

	if (!tag) {
		tag = document.createElement('meta');
		tag.setAttribute(typeAttribute, type);

		head.appendChild(tag);
	}


	tag.setAttribute('content', content);
};

export type StyleDeclaration = Partial<FilterKeysByValueType<CSSStyleDeclaration, string>>;

/**
 * Applies styles to an element and returns a function to roll those styles back to their previous values
 *
 * @param element
 * @param styles
 *
 * @returns a function that restores the previous values of applied styles
 */
export function temporarilyApplyStyles(element: { style: CSSStyleDeclaration }, styles: StyleDeclaration): () => void {
	const previousStyles = Object.keys(styles).map(name => [name, element.style.getPropertyValue(name)]);

	Object.entries(styles).forEach(([name, value]) => element.style[name] = value);

	return () => {
		previousStyles.forEach(([name, value]) => element.style[name] = value);
	};
}

/**
 * Makes the page unscrollable
 *
 * @returns a function that must be called to restore scrolling
 */
export function preventBodyScroll(): () => void {
	// don't try to prevent scroll if already prevented; scrollY won't be accurate
	if (document.body.dataset.scrollPrevented === 'true') {
		return () => {};
	}

	const { scrollY } = window;

	const restoreBodyStyles = temporarilyApplyStyles(document.body, {
		position: 'fixed',
		top: `${-scrollY}px`,
		bottom: '0',
		left: '0',
		right: '0',
		overflow: 'hidden',
	});

	const restoreHtmlStyles = temporarilyApplyStyles(document.documentElement, {
		scrollBehavior: 'auto',
	});

	document.body.dataset.scrollPrevented = 'true';

	return () => {
		restoreBodyStyles();
		document.body.dataset.scrollPrevented = 'false';
		window.scrollTo({ top: scrollY });

		// restore smooth scrolling. Needs to be to put in the event loop queue to avoid
		// restoring smooth scroll too soon I tried using "behavior: auto" in the scrollTo
		// call above, but it doesn't seem to be respected when the HTML element has smooth
		// scroll enabled (at least in FF)
		setTimeout(restoreHtmlStyles);
	};
}

export function scrollToEl(selector: string, options: ScrollIntoViewOptions): void {
	const el = document.querySelector(selector);
	if (el) el.scrollIntoView(options);
	// eslint-disable-next-line no-console
	else console.warn(`element with selector '${selector}' wasn't found`);
}

export function scrollToElement(el: HTMLElement, options: ScrollIntoViewOptions): void {
	if (el) scrollIntoViewIfNeeded(el, options);

	// eslint-disable-next-line no-console
	else console.warn(`Scroll to element wasn't found`);
}

function scrollIntoViewIfNeeded(target: HTMLElement, options: ScrollIntoViewOptions): void {
	if (target.getBoundingClientRect().bottom > window.innerHeight) {
		target.scrollIntoView(false);
	}

	if (target.getBoundingClientRect().top < 0) {
		target.scrollIntoView(options);
	}
}

type CreateElementOptions<T extends HTMLElementTagNameMap[keyof HTMLElementTagNameMap]> = Partial<{ [K in keyof Omit<T, 'children'>]: T[K] }> & {
	children?: (HTMLElement | string)[];
}

/**
 * Provides a declarative API for creating plain DOM elements.
 * @param tagName tag name of the desired HTMLElement
 * @param props properties/attributes to apply to the created element. Use the `children` property to append
 * text nodes (as strings) or other HTMLElements to the created element
 * @returns an HTMLElement
 */
export function createHTMLElement<K extends keyof HTMLElementTagNameMap>(tagName: K, props: CreateElementOptions<HTMLElementTagNameMap[K]> = {}): HTMLElementTagNameMap[K] {
	const element = document.createElement(tagName);

	const { children, ...attrs } = props;

	Object.entries(attrs ?? {}).forEach(([name, value]) => element[name] = value);
	children?.forEach?.(child => {
		const nodeToAppend = typeof child === 'string'
			? document.createTextNode(child)
			: child;

		element.appendChild(nodeToAppend);
	});

	return element;
}