
import clickOutside from '../../../directives/click-outside';
import Icon from 'JS/components/Icon.vue';
import menuManager from '../../util/menu-manager';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IManagedMenu } from '../../../types/Header';
import { setModalOpenClasses, unsetOpenClasses } from '../../util/menu.utils';

// https://www.smashingmagazine.com/2017/11/building-accessible-menu-systems/
@Component({
	name: 'HeaderDropMenu',
	components: {
		Icon
	},
	directives: {
		clickOutside
	}
})
export default class HeaderDropMenu extends Vue implements IManagedMenu {

	$refs!: {
		menuTrigger: HTMLElement;
	}

	/* Props
	============================================*/

	// https://github.com/vuejs/vue/issues/6144#issuecomment-316543936
	@Prop({ type: String, required: false, default: '' })
	readonly targetClass: string;

	@Prop({ type: String, required: false, default: 'right' })
	readonly position: string;

	@Prop({ type: String, required: false, default: "" })
	readonly overrideDropdownAriaLabel: string;

	@Prop({ type: Boolean, required: false, default: false })
	readonly hideChevron: string;

	@Prop({ type: Number, required: false, default: 10 })
	readonly chevronSize: number;

	/* Data
	============================================*/

	isShowing: boolean = false;
	hasBeenShown: boolean = false;
	hideTimer: ReturnType<typeof setTimeout> | null = null;

	/* Computed
	============================================*/

	get menuId(): string {
		return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
	}

	get dropdownAriaLabel(): string {
		if (this.overrideDropdownAriaLabel) {
			return this.overrideDropdownAriaLabel;
		}
		return "Toggle Sub-Menu";
	}

	/* Methods
	============================================*/

	handleKeyPress(e: KeyboardEvent) {
		switch (e.key) {
			case 'Escape':
				this.hide();
				this.$refs.menuTrigger.focus();
				break;
			default:
				break;
		}
	}

	handleMouseOver(show: boolean) {
		// Ignore mouse interaction on mobile sizes
		if (window.innerWidth < 768) return;
		return show ? this.show() : this.delayHide();
	}

	clearHideTimer() {
		if (this.hideTimer) clearInterval(this.hideTimer);
	}

	show() {
		this.clearHideTimer();
		if (this.isShowing) return;
		menuManager.closeMenus();
		menuManager.addMenu(this as any);
		this.$emit('open');
		this.hasBeenShown = true;
		this.isShowing = true;
		if (window.innerWidth > 767) return;
		setModalOpenClasses(true);
	}

	delayHide(delay: number = 250) {
		this.clearHideTimer();
		this.hideTimer = setTimeout(() => {
			this.hide();
		}, delay);
	}

	hide() {
		if (!this.isShowing) return;
		menuManager.removeMenu(this as any);
		this.isShowing = false;
		this.$emit('close');
		unsetOpenClasses();
	}

	toggle() {
		if (this.isShowing) {
			this.hide();
		} else {
			this.show();
		}
	}

	/* Lifecycle Hooks
	============================================*/

	destoyed() {
		menuManager.removeMenu(this);
	}

}

