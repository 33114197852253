export type AnalyticsPromoOptions = {
	promoId: string;
	promoName: string;
	creativeSlot: string;
	creativeName: string;
}

export type AnalyticsPromoAttrs = ReturnType<typeof createPromoDataAttributes>;

export function createPromoDataAttributes({
	promoId,
	promoName,
	creativeSlot,
	creativeName,
}: AnalyticsPromoOptions) {
	return {
		"data-promo-id": promoId,
		"data-creative-slot": creativeSlot,
		"data-creative-name": creativeName,
		"data-promotion-name": promoName,
	};
}