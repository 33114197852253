import { render, staticRenderFns } from "./EnterVerificationCode.vue?vue&type=template&id=0b7c1e2e&scoped=true"
import script from "./EnterVerificationCode.vue?vue&type=script&setup=true&lang=ts"
export * from "./EnterVerificationCode.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./EnterVerificationCode.vue?vue&type=style&index=0&id=0b7c1e2e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b7c1e2e",
  null
  
)

export default component.exports