import { IManagedMenu } from 'Src/js/types/Header';

let menus: IManagedMenu[] = [];

/**
 * Simple object for keeping track of open header menus
 */
const menuManager = {
	addMenu: (menu: IManagedMenu) => {
		if (!menu || !menu.menuId) return;
		menus.push(menu);
	},

	removeMenu: (menu: IManagedMenu) => {
		if (!menu || !menu.menuId) return;
		menus = menus.filter(x => x.menuId !== menu.menuId);
	},

	closeMenus: () => {
		menus.forEach((menu) => {
			if (!menu || !menu.hide) return;
			menu.hide();
		});
	},
};

export default menuManager;