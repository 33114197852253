import { InjectionKey, Ref } from 'vue';

export type ValidationResult = string | true;

/**
 * Function used for form field validation. Returns true when valid or an error message string when invalid
 */
export type ValidatorFunction = (value: string) => ValidationResult;

/**
 * A simple interface for form elements that can be validate by their parent BForm.
 */
export interface IValidatable {
	/**
     * Validation method. Not expected to be pure, ie. may cause form field to display an error message.
     */
	validate(): ValidationResult;

	/**
	 *
	 */
	resetValidation(): void;
}

/**
 * An alias for IRegistrable specific to BForms
 */
export type IFormRegistrar = IRegistrable<IValidatable>;

export const formRegistrarKey: InjectionKey<IFormRegistrar> = Symbol('form');

/**
 * A type that can register and unregister children of some kind
 */
export interface IRegistrable<RegistrableItem> {
	register(child: RegistrableItem): void;

	unregister(child: RegistrableItem): void;
}

/**
 * A radio component to be used with BRadioGroup
 */
export type IRadioComponent = {
	value: any;
	isActive: boolean;
	disabled: boolean;
	readonly checked: boolean;
	onChange(handler: () => void): () => void;
}

export const radioGroupKey: InjectionKey<IRegistrable<IRadioComponent> | undefined> = Symbol('radioGroup');
export const groupNameKey: InjectionKey<Ref<string>> = Symbol('groupName');
export const groupDisabledKey: InjectionKey<Ref<boolean>> = Symbol('groupDisabled');
