import WithUniqueId from 'Src/js/mixins/WithUniqueId';
import { Component, Inject, Mixins, Prop } from 'vue-property-decorator';
import { formRegistrarKey, IFormRegistrar, IValidatable, ValidationResult } from './internal-types';

@Component
export default class BCheckboxBase extends Mixins(WithUniqueId) implements IValidatable {
	/**
     * Optional id to apply to the internal checkbox input and to ARIA relationsips.
     * A UUID will be used instead when omitted.
     */
	@Prop({ type: String })
	readonly id: string;

	/**
     * Checked/unchecked value of the checkbox.
     */
	@Prop({ type: Boolean, required: true })
	readonly value: boolean;


	/**
     * Mapped to the internal checkbox's `disabled` property
     */
	@Prop({ type: Boolean })
	readonly disabled: boolean;

	/**
     * Mapped to the internal checkbox's `indeterminate` property
     */
	@Prop({ type: Boolean })
	readonly indeterminate: boolean;

	/**
     * Descriptive label for the checkbox input.
     */
	@Prop({ type: String, required: false })
	readonly label: string;

	/**
     * Whether or not the checkbox must be checked for form submission.
     */
	@Prop({ type: Boolean })
	readonly required: boolean;

	/**
     * Error message to display after validation when `required` prop is true
     * and box is not checked
     */
	@Prop({ type: String, default: 'This box must be checked.' })
	readonly requiredMessage: string;

	/**
     * When true, disables validate-on-blur behavior, only validating when
     * wrapping form is submitted
     */
	@Prop({ type: Boolean })
	readonly noAutoValidation: boolean;

	/**
	 * IDs of elements to add to aria-describedby for checkbox
	 */
	@Prop({ type: String, required: false })
	readonly ariaDescribedby: string

	/**
	* IDs of elements to add to aria-labelledby for checkbox
	*/
	@Prop({ type: String, required: false })
	readonly ariaLabelledby: string

	/**
	 * Custom aria-label to apply to the checkbox
	 */
	@Prop({ type: String, required: false })
	readonly ariaLabel: string

	@Inject({ from: formRegistrarKey as symbol, default: undefined }) form?: IFormRegistrar;

	errorMessage: string = '';

	/**
     * Id used for checkbox and ARIA relationships. Id prop if passed,
     * otherwise generated unique id
     */
	get $id (): string {
		if (this.id) {
			return this.id;
		}
		return this.uuid;
	}

	get ariaChecked(): 'true' | 'false' {
		if (this.indeterminate) return 'true';

		return this.value.toString() as 'true' | 'false';
	}

	/**
     * Handles validation behavior when checkbox is blurred
     */
	onBlur(): void {
		if (this.noAutoValidation) {
			return;
		}

		this.validate();
	}

	/**
     * Custom checkbox change logic
     */
	handleChange(event: InputEvent): void {
		if (this.disabled) return;
		this.$emit('change', event);
		this.$emit('input', !this.value);
		if (this.required && !this.value) {
			this.errorMessage = '';
		}
	}

	/**
     * IValidatable implementation. Handles `required` validation.
     */
	validate(): ValidationResult {
		if (this.required && !this.value) {
			this.errorMessage = this.requiredMessage;
			return this.requiredMessage;
		}

		this.errorMessage = '';
		return true;
	}

	/**
     * IValidatable implementation. Handles `required` validation.
     */
	resetValidation(): void {
		this.errorMessage = '';
	}

	/**
	 * Calculates aria-describedby and prevents nullish values from getting stringified in the DOM
	 */
	getAriaDescribedby(...values: (string | null | undefined)[]): string {
		return values.filter(v => !!v).join(' ');
	}

	mounted(): void {
		this.form?.register?.(this);
	}

	beforeDestroy(): void {
		this.form?.unregister?.(this);
	}
}