import BaseHttpService from '../services/BaseHttpService';
import { browserSupportsIntersectionObserver } from 'JS/utilities/browser-support';

const httpService = new BaseHttpService();
const lazySrcAttribute = 'data-lazy-src';
const lazySrcsetAttribute = 'data-lazy-srcset';

export function initImages(): void {
	lazyLoadImages(window.document);
	initImageClickTracking();
}

export function lazyLoadImages(
	el: HTMLElement | Document
): void {
	const selector = `img[${lazySrcAttribute}], source[${lazySrcsetAttribute}]`;
	const images = el.querySelectorAll<HTMLImageElement>(selector);

	if (!images.length) return;

	// Just show the images immediately on legacy browsers
	if (browserSupportsIntersectionObserver() == false) {
		for (const image of images) {
			lazyLoadImage(image);
		}
		return;
	}

	const imgObserver = new IntersectionObserver((entries, self) => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				lazyLoadImage(entry.target as HTMLImageElement);
				self.unobserve(entry.target);
			}
		});
	});

	for (const image of images) {
		imgObserver.observe(image);
	}
}

function initImageClickTracking(): void {
	const images = document.querySelectorAll<HTMLElement>('[data-view-img-data]');
	if (!images.length) return;
	for (const image of images) {
		trackClicksOnImage(image);
	}
}

function trackClicksOnImage(img: HTMLElement): void {
	const clickData = JSON.parse(img.getAttribute('data-view-img-data'));
	if (!clickData) return;
	img.addEventListener('click', () => {
		httpService.post('/event/a/banner-click', clickData);
	});
}

export function lazyLoadImage(img: HTMLImageElement, cb?: Function): void {
	if (!(img instanceof HTMLElement) || img.hasAttribute('src') || img.hasAttribute('srcset')) {
		if (cb && typeof cb === 'function') cb();
		return;
	}

	const attr = img.hasAttribute(lazySrcsetAttribute) ? 'srcset' : 'src';
	const src = img.getAttribute(lazySrcAttribute) || img.getAttribute(lazySrcsetAttribute);
	const preload = new Image();

	preload.onload = () => {
		img.setAttribute(attr, src);
		img.parentElement?.parentElement?.querySelector('.lazy-img-loader')?.remove();
		img.style.opacity = "1";
		img.removeAttribute('loading');
		if (cb && typeof cb === 'function') cb();
	};
	preload.setAttribute(attr, src);
}

export function lazyLoadImageAsync(img: HTMLImageElement): Promise<void> {
	return new Promise((resolve, reject) => {
		try {
			lazyLoadImage(img, resolve);
		} catch (e) {
			reject(e);
		}
	});
}


// checkWebpFeature (official Google method for checking support):
//   'feature' can be one of 'lossy', 'lossless', 'alpha' or 'animation'.
//   'callback(feature, isSupported)' will be passed back the detection result (in an asynchronous way!)
export function checkWebpFeature(feature: WebPFeature) {
	return new Promise<boolean>(resolve => {
		const img = new Image();
		img.onload = () => {
			const result = (img.width > 0) && (img.height > 0);
			resolve(result);
		};
		img.onerror = () => {
			resolve(false);
		};
		img.src = "data:image/webp;base64," + kTestImages[feature];
	});
}

const kTestImages = {
	lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
	lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
	alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
	animation: "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
};
type WebPFeature = keyof typeof kTestImages;