
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';


@Component({
	name: 'TransitionExpand',
})
class TransitionExpand extends Vue {
	@Prop({ type: Boolean })
	group: boolean;

	get component(): string {
		return this.group ? 'transition-group' : 'transition';
	}

	get transitionName(): string {
		return this.group ? 'transition-group-expand' : 'transition-expand';
	}

	// Height transition adapted from https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/
	enter(el: HTMLElement) {
		const { width } = getComputedStyle(el);

		el.style.setProperty('position', 'absolute');
		el.style.setProperty('visibility', 'hidden');
		el.style.setProperty('height', 'auto');
		el.style.setProperty('width', width);

		const { height } = getComputedStyle(el);

		el.style.setProperty('position', null);
		el.style.setProperty('visibility', null);
		el.style.setProperty('height', '0');
		el.style.setProperty('width', null);

		// Force repaint to make sure the
		// animation is triggered correctly.
		getComputedStyle(el).height;

		// Trigger the animation.
		// We use `requestAnimationFrame` because we need
		// to make sure the browser has finished
		// painting after setting the `height`
		// to `0` in the line above.
		requestAnimationFrame(() => {
			el.style.setProperty('height', height);
		});
	}

	afterEnter(el: HTMLElement) {
		el.style.setProperty('height', null);
	}

	leave(el: HTMLElement) {
		const height = getComputedStyle(el).height;

		el.style.setProperty('height', height);

		// Force repaint
		getComputedStyle(el).height;

		requestAnimationFrame(() => {
			el.style.setProperty('height', '0');
		});
	}
}

export default TransitionExpand;
