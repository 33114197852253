
import { defineComponent, PropType, ref, watch } from 'vue';
import { useIntersectionObserver } from 'JS/composables/intersection-observer';

export default defineComponent({
	props: {
		tag: { type: String, default: 'div' },
		options: { type: Object as PropType<IntersectionObserverInit>, default: () => ({}) }
	},
	emits: ['enter', 'leave'],
	setup(props, ctx) {
		const el = ref<HTMLElement | null>(null);
		const { isIntersecting } = useIntersectionObserver(el, () => props.options);

		watch(isIntersecting, (intersecting) => {
			if (intersecting) {
				ctx.emit('enter');
			} else {
				ctx.emit('leave');
			}
		}, { immediate: true });

		return {
			el,
		};
	}
});
