//account-verification join-verification and connect-verification are the
//same component but represent which flow brought the user to the verification page
export type UserVerificationVariant = "verification-account" | "verification-join" | "verification-connect" | "account-in-use";
export type IJoinRewardsStep = "join" | "connect" | 'enter-code' |"success" | "help" | "customer-has-rewards" | UserVerificationVariant;
export type EnterCodeVariant = "phone" | "email" | "rewards-number";

export interface IRewardsSignupValidation {
	phoneNumber: string;
	email: string;
	rewardsNumber: string;
}

export interface ILinkRewardsUser {
	phoneNumber: string;
	email: string;
	rewardsNumber?: string;
	verificationMethod?: 'phone' | 'email' | 'rewardsNumber';
}

export interface IConnectRewardsFormValidation {
	phoneNumber: string | undefined;
	email: string | undefined;
	rewardsNumber: string | undefined;
}

export interface ILinkRewardsUserResponse {
	success: boolean;
	reasonCode: RewardsReasonCode;
	errorMessage?: string;
	email?: string;
	phone?: string
}

export interface IVerifyUserData {
	phone: string,
	email: string,
	rewardsNumber?: string,
	variant: UserVerificationVariant,
	redactedData: 'phone' | 'email' | 'both';
}

export enum RewardsReasonCode {
	CUSTOMER_NOT_LOGGED_IN,
	REWARDS_ACCOUNT_NOT_FOUND,
	REWARDS_ACCOUNT_ALREADY_LINKED,
	REWARDS_ACCOUNT_ALREADY_EXISTS,
	VERIFICATION_REQUIRED,
	SESSION_M_ERROR,
	INVALID_INPUT_DATA,
	UNKNOWN_ERROR,
	CUSTOMER_HAS_REWARDS,
	NO_MATCH,
	PHONE_MULTI_MATCH,
	REWARDS_ACCOUNT_ALREADY_EXISTS_SHOW_ERROR
}