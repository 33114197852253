import {
	ButtonStateChangeEvent,
	IListSlider,
	SlideChangeEvent,
} from "./types";
import { createListSlider, getTrack, isCarouselButtonDisabled, toggleCarouselButtonDisabled } from './core';

/*=================================================================
 == <list-slider> Custom Element definition
/==================================================================*/

const ACTION_BUTTON_SELECTOR = 'button[data-list-slider-action]';

export class ListSliderElement extends HTMLElement {
	private _viewport: HTMLElement | null = null;
	private _slider: IListSlider | null = null;
	private _resizeObserver: ResizeObserver;
	private _mutationObserver: MutationObserver;
	private _removeSlideListener: (() => void) | null;
	private _removeButtonStateChangeListener: (() => void) | null;

	constructor() {
		super();

		// handles the viewport size changing
		this._resizeObserver = new ResizeObserver((_entries) => {
			this.reset();
		});

		this._mutationObserver = new MutationObserver((mutations) => {
			for (const mutation of mutations) {
				if (mutation.type !== 'childList') {
					continue;
				}

				// handles slider items being added or removed from the track
				if (this._viewport && mutation.target === getTrack(this._viewport)) {
					this.reset();
				} else if (
					// handles the viewport or track being added/removed
					[...mutation.addedNodes, ...mutation.removedNodes].some(n =>
						n instanceof Element && n.matches('[data-list-slider-viewport], ul, [data-list-slider-track]')
					)
				) {
					this.init();
				}
			}
		});

		this.addEventListener('click', e => this.handleActionClick(e));
	}

	connectedCallback() {
		this.init();
	}

	disconnectedCallback() {
		this.teardown();
	}

	private init() {
		this.teardown();

		this._mutationObserver.observe(this, {
			childList: true,
			subtree: true,
		});

		this._viewport = this.querySelector('[data-list-slider-viewport]');
		this._slider = createListSlider(this._viewport);
		if (!this._slider) {
			return;
		}

		this._resizeObserver.observe(this._viewport);

		this._removeSlideListener = this._slider.onSlide(e => this.dispatchEvent(e));
		this._removeButtonStateChangeListener = this._slider.onButtonStateChange(e => this.handleButtonStateChange(e));

		this.prevSlideCount = this.slideCount;
	}

	private teardown() {
		this._resizeObserver.disconnect();
		this._mutationObserver.disconnect();
		this._slider?.destroy();
		this._slider = null;
		this._removeSlideListener?.();
		this._removeSlideListener = null;
		this._removeButtonStateChangeListener?.();
		this._removeButtonStateChangeListener = null;
		this.prevSlideCount = 0;
	}

	private handleButtonStateChange(e: ButtonStateChangeEvent) {
		const actionButtons = this.querySelectorAll<HTMLButtonElement>(ACTION_BUTTON_SELECTOR);

		const { nextDisabled, previousDisabled } = e.detail;

		for (const action of actionButtons) {
			const { listSliderAction } = action.dataset;
			switch (listSliderAction) {
				case 'previous':
					toggleCarouselButtonDisabled(action, previousDisabled);
					break;
				case 'next':
					toggleCarouselButtonDisabled(action, nextDisabled);
					break;
			}
		}
	}

	private handleActionClick(e: MouseEvent) {
		if (!(e.target instanceof Element)) {
			return;
		}

		const action = e.target.closest(ACTION_BUTTON_SELECTOR);
		if (!(action instanceof HTMLButtonElement) || isCarouselButtonDisabled(action)) {
			return;
		}

		const { listSliderAction } = action.dataset;
		switch (listSliderAction) {
			case 'previous':
				this.backward();
				break;
			case 'next':
				this.forward();
				break;
		}
	}

	forward() {
		this._slider?.slideForward();
	}

	backward() {
		this._slider?.slideBackward();
	}

	slideIndexIntoView(index: number) {
		this._slider?.slideIndexIntoView(index);
	}

	private prevSlideCount = 0;
	get slideCount() {
		return this._slider?.getNumSlides() ?? 0;
	}

	get slide() {
		return this._slider?.getCurrentSlide() ?? 0;
	}
	set slide(slide) {
		this._slider?.goToSlide(slide);
	}

	private reset() {
		const fromSlide = this.slide;

		requestAnimationFrame(() => {
			this._slider?.reset();
			const slideCount = this.slideCount;
			const toSlide = this.slide;
			if (slideCount !== this.prevSlideCount || fromSlide !== toSlide) {
				this.dispatchEvent(new SlideChangeEvent({
					fromSlide,
					toSlide,
					slideCount,
				}));
			}
			this.prevSlideCount = slideCount;
		});
	};
};

declare global {
	interface HTMLElementTagNameMap {
		'list-slider': ListSliderElement;
	}
}

export function defineListSliderElement() {
	if (typeof window !== 'undefined' && !customElements.get('list-slider')) {
		customElements.define('list-slider', ListSliderElement);
	}
}