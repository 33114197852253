
import Icon from 'JS/components/Icon.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
	components: {
		Icon,
	},
	props: {
		variant: {
			type: String as PropType<'responsive' | 'close' | 'back'>,
			default: 'responsive',
		}
	},
	setup(props, { emit }) {
		function handleClick() {
			if (props.variant === 'back') {
				emit('back');
			} else {
				emit('close');
			}
		}

		return {
			handleClick,
		};
	}
});
