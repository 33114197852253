/**
 * Returns true if user's browser supports IntersectionObserver
 */
export function browserSupportsIntersectionObserver(): boolean {
	return 'IntersectionObserver' in window
		&& 'IntersectionObserverEntry' in window
		&& 'intersectionRatio' in window.IntersectionObserverEntry.prototype;
}

export function browserSupportsLazyImageLoading(): boolean {
	return ('loading' in HTMLImageElement.prototype);
}