
import Vue from 'vue';
import { Component, Prop, Provide } from 'vue-property-decorator';
import { focusNextError } from 'JS/components/blain-form/errors';
import { formRegistrarKey, IFormRegistrar, IValidatable } from './internal-types';

@Component({
	name: 'BForm',
})
export default class BForm extends Vue {

	/**
		 * Provided to child form elements so that they can register themselves
		 * to be validated at time of form submission.
		 */
	@Provide(formRegistrarKey as symbol) form: IFormRegistrar = {
		register: this.register,
		unregister: this.unregister,
	};

	/**
		 * Use traditional (non-AJAX) form submission after form validation instead of emitting a 'submit' event
		 */
	@Prop({ type: Boolean })
	readonly fullSubmit: boolean;

	$refs: {
		formElement: HTMLFormElement | null;
	}

	/**
		 * Registers a child form element that should be validated at the time of form submission.
		 * Called from child.
		 *
		 * @param formChild The form element to register
		 */
	register(child: IValidatable) {
		this.children.push(child);
	}

	/**
		 * Unregisters a child form element so as not to attempt to validate it at the time of form submission
		 *
		 * @param formChild The form element to unregister
		 */
	unregister(child: IValidatable) {
		this.children = this.children.filter(registeredChild => registeredChild !== child);
	}

	/**
	 * Submit handler that checks whether all registered child fields are valid
	 * before allowing form submission
	 */
	submit() {
		const isFormValid = this.validate();

		if (isFormValid) {
			if (this.fullSubmit) {
				this.$refs.formElement?.submit();
			} else {
				this.$emit('submit');
			}
		} else {
			this.$nextTick(() => {
				focusNextError();
			});
		}
	}

	validate() {
		return this.children
			.map(child => child.validate())
			.every(result => result === true);
	}

	resetValidation() {
		this.children.forEach(child => child.resetValidation());
	}

	/**
		 * The list of registered form elements that should be validated at the time of form submission
		 */
	protected children = [] as IValidatable[];
}
