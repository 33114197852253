/**
 * Provides global functionality for using the Sign In and Create Account Side Menu.
 */
import globalData from './window-data';
import LoginSideMenu from 'JS/components/side-menus/account-creation/LoginSideMenu.vue';
import Vue from 'vue';

let initted = false;

Blain.Login = {
	init() {
		const self = this;
		self.initLoginSideMenu();
		self.bindClickEventsForSidePanel();
	},

	/**
	 * Initializes the Login Side Menu component and mounts it to the DOM
	 */
	initLoginSideMenu() {
		if (initted) return;

		self.$loginSideMenu = new Vue(LoginSideMenu);
		self.$loginSideMenu.$mount(document.createElement('div'));
		document.body.appendChild(self.$loginSideMenu.$el);
		self.$loginSideMenu.$on('close', () => {
			self.$loginSideMenu.$props.show = false;
		});
		initted = true;
	},

	/**
	 * Show Sign in Side Menu. This is what outside components call to open the sidemenu.
	 */
	showSideMenu(pageToShow = 'login', skipCartMerge = false, destinationUrl = null, fromRewards = false) {
		// If the customer is signed in, show the rewards side panel
		self.$loginSideMenu.$props.page = pageToShow;
		self.$loginSideMenu.$props.skipCartMerge = skipCartMerge;
		self.$loginSideMenu.$props.destinationUrl = destinationUrl;
		self.$loginSideMenu.$props.fromRewards = fromRewards;
		self.$loginSideMenu.$props.show = true;
	},

	/**
	 * Binds click events to the provided DOM elements.  If no selectorObject is provided,
	 * uses a default object matching selectors used throughout MainWebSite for login and registering for an account
	 * @param {object} selectorObject for use in binding events to selected DOM elements
	 */
	bindClickEventsForSidePanel(selectorObject) {

		if (!selectorObject) {
			selectorObject = {
				loginLink: '.login-link',
				registerLink: '.register-link'
			};
		};

		const loginTarget = document.querySelectorAll(selectorObject.loginLink);
		const registerTarget = document.querySelectorAll(selectorObject.registerLink);

		if (loginTarget?.length > 0 || registerTarget?.length > 0) {

			if (loginTarget) {
				loginTarget.forEach(target => target.addEventListener('click', _e => {
					if (!!globalData.customer) {
						return;
					}

					_e.preventDefault();

					let destinationUrl = target.getAttribute("href");
					if (destinationUrl?.includes("javascript:void") ?? true) {
						destinationUrl = null;
					}
					const skipCartMerge = target.getAttribute("blain-skipcartmerge") === "true";
					this.showSideMenu('login', skipCartMerge, destinationUrl);
				}));
			}

			if (registerTarget) {
				registerTarget.forEach(target => target.addEventListener('click', _e => {
					if (!!globalData.customer) {
						return;
					}

					_e.preventDefault();

					let destinationUrl = target.getAttribute("href");
					if (destinationUrl?.includes("javascript:void") ?? true) {
						destinationUrl = null;
					}
					const skipCartMerge = target.getAttribute("blain-skipcartmerge") === "true";
					this.showSideMenu('create', skipCartMerge, destinationUrl);
				}));
			}

			self.$loginSideMenu.$mount(document.createElement('div'));
			document.body.appendChild(self.$loginSideMenu.$el);
		}
	}
};

Blain.UpdatePassword = {

	init: function() {

		if (!document.getElementById("updatePasswordForm")) return;

		const $updatePasswordForm = $('#updatePasswordForm');

		const $newPassword = $("#newPassword");

		if (window && window.history.state && window.history.state.destinationUrl) {
			$updatePasswordForm.append(`<input name="refUrl" type="hidden" value="${window.history.state.destinationUrl}" />`);
		}

		// Setup register links
		$updatePasswordForm.validate({
			showErrors: function(_, errorList) {
				const $errorMessage = $updatePasswordForm.find('.error-message');

				$errorMessage.children().detach();
				$newPassword.removeClass('error');

				if (errorList &&  errorList.length) {
					$newPassword.addClass('error');

					const $errorLabel = $(document.createElement('label'))
						.addClass('error')
						.attr('id', 'newPassword-error')
						.attr('for', 'newPassword')
						.text(errorList[0].message);

					const $warningIcon = $(document.createElement('span'))
						.addClass('fa fa-exclamation-triangle')
						.css('margin-right', '5px')
						.attr('aria-hidden', true);

					$errorLabel.prepend($warningIcon);

					$errorMessage.append($errorLabel);
				}
			},
		});
	},

};

$(document).ready(() => {
	Blain.Login.init();
});
