import bugsnagClient from '../global/bugsnag';
import { ILoggerClient, ILoggerService } from '../types/Logger';

export default class LoggerService implements ILoggerService {

	private client: ILoggerClient = null;

	constructor(client: ILoggerClient = null) {
		if (client != null) {
			this.client = client;
		} else {
			this.client = bugsnagClient;
		}
	}

	log(error: string): void {
		if (!this.client) return;
		this.client.notify(error);
	}

}