import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import { AMEX_REGEX, CURRENCY_REGEX, DISCOVER_REGEX, EMAIL_REGEX, MASTERCARD_REGEX, NUMERIC_REGEX, PHONE_REGEX, US_ZIP_REGEX, VISA_REGEX } from 'Src/js/helpers/regex';

import { ValidatorFunction } from './internal-types';

export function emptyOr(validator: ValidatorFunction): ValidatorFunction {
	return value => !value || validator(value);
}

export const length = (length: number): ValidatorFunction => (value: string) => {
	// Inform the user that they have too few characters
	if (value && value.length != length) {
		return `Must be ${length} characters. Currently (${value.length})`;
	}

	return true;
};

export const minLength = (minLength: number): ValidatorFunction => (value: string) => {
	if (value && value.length < minLength) {
		return `Must be at least ${minLength} characters long.`;
	}

	return true;
};

export const maxLength = (maxLength: number): ValidatorFunction => {
	return (value: string) => {
		if (value && value.length > maxLength) {
			return `Must be ${maxLength} characters or less.`;
		}

		return true;
	};
};

export const email: ValidatorFunction = (value: string) => {
	if (!value) return;

	// common email error checks requested
	if (value.endsWith(".con") ||
		value.endsWith("gmai.com") ||
		value.endsWith("comcast.nt") ||
		value.endsWith(".coim") ||
		value.endsWith(".vom") ||
		!EMAIL_REGEX.test(value)) return 'Please enter a valid email address.';

	return true;

};

export const phone: ValidatorFunction = (value: string) => {
	if (PHONE_REGEX.test(value)) return true;

	return 'Please enter a valid 10-digit phone number.';
};

export const zip: ValidatorFunction = (value: string) => {
	if (US_ZIP_REGEX.test(value)) return true;

	return 'Please enter a valid ZIP code.';
};

export const number: ValidatorFunction = (value: string) => {
	if (NUMERIC_REGEX.test(value)) return true;

	return 'Please enter a valid number.';
};

export const currency: ValidatorFunction = (value: string) => {
	if (CURRENCY_REGEX.test(value)) return true;

	return 'Please enter a valid dollar amount.';
};

export const password: ValidatorFunction = (value: string) => {
	if (value.length >= 8
		&& /[A-Z]/.test(value)
		&& /[a-z]/.test(value)
		&& /[0-9]/.test(value)
	) return true;

	return 'See password requirements below.';
};

export const ccexp: ValidatorFunction = (value: string) => {
	// Check 2/2 digits
	if (!/\d\d\/\d\d/.test(value)) {
		return 'Expiry date format must be MM/YY';
	}

	// Check month is 1 to 12 inclusive
	const today = new Date();
	//const thisYear = today.getFullYear()%100;
	const date = value.split('/');
	const ccmonth = parseInt(date[0]);
	const ccyear = date[1];
	const century = today.getFullYear().toString().substring(0, 2);
	const ccDate = new Date(parseInt(century + ccyear), ccmonth, 1);

	// Valid month
	if (ccmonth < 1 || ccmonth > 12) {
		return 'Please enter a valid month';
	}

	// Date in the future
	if (ccDate < today) {
		return 'Please enter a future date';
	}

	return true;
};

export const creditcard: ValidatorFunction = (value: string) => {
	if (/[^0-9 \-]+/.test(value) || value.length < 13) {
		return 'Please enter a valid credit card number.';
	}

	let isValid = false;

	value = value.replace(/\D/g, '');

	if (MASTERCARD_REGEX.test(value)) {
		// Mastercard
		isValid = value.length === 16;
	} else if (VISA_REGEX.test(value)) {
		// Visa
		isValid = value.length >= 13 || value.length <= 19; //value.length === 16;
	} else if (AMEX_REGEX.test(value)) {
		// Amex
		isValid = value.length === 15;
	} else if (DISCOVER_REGEX.test(value)) {
		// Discover
		isValid = value.length === 16;
	} else {
		return "We're sorry, but we do not accept this card type";
	}

	/* Other card validation tests if needed
	if (/^(3(0[012345]|[68]))/.test(value)) {
		// Dinersclub
		return value.length === 14;
	}
	if (/^(2(014|149))/.test(value)) {
		// Enroute
		return value.length === 15;
	}
	if (/^(3)/.test(value)) {
		// Jcb
		return value.length === 16;
	}
	if (/^(2131|1800)/.test(value)) {
		// Jcb
		return value.length === 15;
	}
	*/


	// https://jqueryvalidation.org/creditcard-method/
	// based on https://en.wikipedia.org/wiki/Luhn_algorithm
	let nCheck = 0,
		nDigit = 0,
		bEven = false,
		n = 0,
		cDigit = '';

	for (n = value.length - 1; n >= 0; n--) {
		cDigit = value.charAt(n);
		nDigit = parseInt(cDigit, 10);
		if (bEven) {
			if ((nDigit *= 2) > 9) {
				nDigit -= 9;
			}
		}

		nCheck += nDigit;
		bEven = !bEven;
	}

	isValid = nCheck % 10 === 0;


	return isValid ? true : 'Please enter a valid credit card number.';
};

@Component
export default class WithValidation extends Vue {
	get $emptyOr(): (validator: ValidatorFunction) => ValidatorFunction {
		return emptyOr;
	};
	get $minLength(): (minLength: number) => ValidatorFunction {
		return minLength;
	};
	get $maxLength(): (maxLength: number) => ValidatorFunction {
		return maxLength;
	};
	get $length(): (length: number) => ValidatorFunction {
		return length;
	};
	get $email(): ValidatorFunction {
		return email;
	};
	get $phone(): ValidatorFunction {
		return phone;
	};
	get $zip(): ValidatorFunction {
		return zip;
	};
	get $creditcard(): ValidatorFunction {
		return creditcard;
	}
	get $ccexp(): ValidatorFunction {
		return ccexp;
	}
	get $number(): ValidatorFunction {
		return number;
	}
	get $currency(): ValidatorFunction {
		return currency;
	}
	get $password(): ValidatorFunction {
		return password;
	}
}