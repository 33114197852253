import { CleaveOptions } from "cleave.js/options";

interface BaseFormatOptions {
	/** Validation will be done against the raw, unformatted value by default. Set this to true to run validation against the formatted value. */
	validateAgainstFormattedValue?: boolean;

	/** The raw input value will be emitted by default. Set this to true to emit the formatted value. */
	emitFormattedValue?: boolean;
}

interface SimpleFormatOptions extends BaseFormatOptions {
	type: 'phone' | 'cc-number' | 'cc-expiration' | 'money';
}

interface NumericFormatOptions extends BaseFormatOptions {
	type: 'numeric';

	/** Maximum length of a numeric input */
	length?: number;
}

/** This type allow passing CleaveOptions directly. If possible avoid using this to prevent leaking implementation details */
interface CustomFormatOptions extends BaseFormatOptions {
	type: 'custom'
	cleaveOptions: CleaveOptions;
}

export type FormatOptions = SimpleFormatOptions | NumericFormatOptions | CustomFormatOptions;

/**
 * Converts the given `format` to the equivalent CleaveOptions object. Returns null if `format` is invalid.
 */
export function getCleaveOptions(format: FormatOptions): CleaveOptions | null {
	switch (format.type) {
		case 'custom': {
			return Object.keys(format.cleaveOptions).length // smoke test that there are actually options set. cleave behavior without options is undefined.
				? format.cleaveOptions
				: null;
		}
		case 'numeric': {
			const options: CleaveOptions = {
				numericOnly: true,
			};

			if (format.length) {
				options.blocks = [format.length];
			}

			return options;
		}
		case 'phone': {
			return {
				phone: true,
				phoneRegionCode: 'US',
			};
		}
		case 'cc-number': {
			return {
				creditCard: true,
			};
		}
		case 'cc-expiration': {
			return {
				date: true,
				datePattern: ['m', 'y'],
			};
		}
		case 'money': {
			return {
				numeral: true,
				numeralPositiveOnly: true,
				prefix: '$',
				rawValueTrimPrefix: true,
			};
		}
		default: {
			return null;
		}
	}
}
