<template>
	<div class="footer-links-list mr">
		<h2>Our Company</h2>
		<a href="/rewards/">Blain's Rewards</a>
		<a href="/mobile-app/">Mobile App</a>
		<a href="/about-us/">About Us</a>
		<a href="/history/">Our History</a>
		<a href="/community/">Community Involvement</a>
		<a href="/blog/">Blain's Blog</a>
		<a href="/blog/category/events-news/">News</a>
		<a href="https://careers.farmandfleet.com/" target="_blank" rel="noopener">Careers at Blain's</a>
		<a href="https://realestate.farmandfleet.com/" target="_blank" rel="noopener">Real Estate</a>
		<a href="/modeling/">Modeling</a>


		<div class="footer-divider"></div>
		<h2>Blain's Mastercard</h2>
		<a href="https://www.card.fnbo.com/" target="_blank" rel="noopener">Pay &amp; Manage Card</a>
		<a href="/mastercard/">Apply for the Card</a>
	</div>
</template>

<script>
export default {
	name: 'CompanyLinks'
};
</script>