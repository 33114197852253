import CarouselTrackingService from "JS/services/CarouselTrackingService";
import { createListSlider } from "JS/list-slider";
import { createTrackingLinkClickHandler } from "JS/utilities/carousel-tracking";
import { ICarouselClickGtmData, ICarouselViewData } from "JS/types/Carousel";
import { onWindowResize } from "JS/utilities/viewport";

/**
 * Inits any product carousels on the page
 */
export function initProductCarousels(selector = '.product-carousel') {
	const carousels = document.querySelectorAll<HTMLElement>(selector);

	const trackingService = new CarouselTrackingService();

	const viewData: ICarouselViewData[] = [];

	for (const carousel of carousels) {
		const viewport = carousel.querySelector<HTMLElement>('[data-list-slider-viewport]');

		if (carousel.hasAttribute('data-list-slider') || !viewport) {
			continue;
		}

		carousel.setAttribute('data-list-slider', 'true');

		const { type, carouselName } = carousel.dataset;
		const view: ICarouselViewData | null = isNaN(+type) ? null : {
			type: +type,
			carouselName,
			url: window.location.pathname + window.location.search,
		};

		if (view) {
			viewData.push(view);

			const productLinks = carousel.querySelectorAll<HTMLElement>('.pc-item');
			productLinks.forEach((link, linkIndex) => {
				const { blainNumber, currentPrice, vendorBrand, productName, belowMap, priceMode } = link.dataset;
				if (blainNumber) {
					const clickData = {
						blainNumber,
						vendorBrand,
						productName,
						currentPrice: parseFloat(currentPrice),
						carousel: view,
						itemIndex: linkIndex,
					};

					const gtmData: ICarouselClickGtmData = {
						blainNumber,
						vendorBrand,
						productName,
						currentPrice: parseFloat(currentPrice),
						belowMap: belowMap === "True",
						priceMode: priceMode,
						itemListID: `carousel_${view.type.toString()}`,
						itemListName: view.carouselName,
						index: linkIndex
					};

					link.addEventListener('click', createTrackingLinkClickHandler(() => {
						trackingService.trackClick(clickData, gtmData);
					}));
				}
			});
		}


		const slider = createListSlider(viewport);
		onWindowResize(() => slider.reset());

		const btns = carousel.querySelectorAll('button');
		if (btns.length === 2) {
			const [prev, next] = btns;
			slider.setControls(prev, next);
		}
	}

	if (viewData.length) {
		trackingService.trackViews(viewData, []); // Do not track views on these carousels in GTM until they are in the viewport
	}
}
