import { computed, ExtractPropTypes, inject, onBeforeUnmount, onMounted, PropType, reactive } from 'vue';
import { v4 as getUUID } from 'uuid';
import { groupDisabledKey, groupNameKey, radioGroupKey  } from './internal-types';

export function createRadioBase<T = any>() {
	// TODO: emits for vue 3

	const withRadioBaseProps = () => ({
		/**
		 * The value associated with this radio item.
		 */
		value: { type: null as unknown as PropType<T>, required: true },

		/**
		 * If true, this item will be selected by default when mounted.
		 */
		checked: { type: Boolean },

		/**
		 * If true, this item will not be selectable. This will _not_ deselect a selected item if disabled changes to true.
		 */
		disabled: { type: Boolean },

		/**
		 * Name for relation to other radio items. Important for browser and screenreader functionality. If this item
		 * is nested within a BRadioGroup, it will receive a name from that BRadioGroup and this prop won't be required.
		 * If _not_ nested within a BRadioGroup, this prop is **required**.
		 */
		name: { type: String },

		/**
		 * Radio element id for relation to labels. If not provided, a randomly-generated id will be used instead.
		 */
		id: { type: String },
	});

	function useRadioBase(props: ExtractPropTypes<ReturnType<typeof withRadioBaseProps>>, emit: (event: string, ...args: any[]) => void) {
		const radioGroup = inject(radioGroupKey);
		const groupName = inject(groupNameKey);
		const groupDisabled = inject(groupDisabledKey);
		const uuid = getUUID();

		const disabled = computed(() => props.disabled || groupDisabled?.value || false);
		const checked = computed(() => props.checked);

		const changeListeners = new Set<() => void>();
		const radio = reactive({
			isActive: false,
			value: computed(() => props.value),
			disabled,
			checked,
			onChange(handler: () => void): () => void {
				changeListeners.add(handler);

				return () => changeListeners.delete(handler);
			}
		});

		onMounted(() => {
			radioGroup?.register(radio);
		});

		onBeforeUnmount(() => {
			radioGroup?.unregister(radio);
		});

		return {
			/**
			 * Computed id. This should be used instead of id in the templates of components that use this mixin.
			 */
			radioId: computed(() => props.id || uuid),

			/**
			 * Computed name. This should be used instead of name in the templates of components that use this mixin.
			 */
			radioGroupName: computed(() => props.name || groupName?.value),

			/**
			 * Computed disabled. This should be used instead of disabled in the templates of components that use this mixin.
			 */
			radioDisabled: disabled,

			isActive: computed({
				get: () => radio.isActive,
				set: value => radio.isActive = value,
			}),
			toggle: () => {
				emit('change');
				changeListeners.forEach(handler => handler());
			},
		};
	}

	return {
		withRadioBaseProps,
		useRadioBase,
	};
}