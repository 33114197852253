/**
 * Provides global functionality for using the Rewards Side Panel.
 *
 * When a customer is logged in, but does not have a rewards account,
 * we provide a UI enabling them to Sign Up ("Join") or link an
 * existing account ("Connect").  When a customer is not logged in, we
 * present UI that prompts them to either register for a new Blain's account
 * or log in.
 */

import RewardsSignupSidePanel from 'JS/components/side-menus/rewards-sign-up/RewardsSignupSidePanel.vue';
import Vue from 'vue';
require('lessRoot/rewards.less');

Blain.Rewards = {
	init() {
		const self = this;
		self.initRewardsSidePanel();
		self.bindClickEventsForSidePanel();
	},

	/**
	 * Initializes the Rewards Side Panel component and mounts it to the DOM
	 */
	initRewardsSidePanel() {
		self.$rewardsSidePanel = new Vue(RewardsSignupSidePanel);
		self.$rewardsSidePanel.$mount(document.createElement('div'));
		document.body.appendChild(self.$rewardsSidePanel.$el);
		self.$rewardsSidePanel.$on('close', () => {
			self.$rewardsSidePanel.$props.show = false;
		});
	},

	/**
	 * Makes the Rewards Side Panel visible when a user is logged in.
	 * If a user is not logged in, shows the Login Modal.
	 * @param {string} stepToShow - The "Rewards Signup" step to
	 * show - e.g. 'join' (Join) or 'connect' (Connect an Account)
	 * @param {boolean} skipCartMerge - Determines whether or not to
	 * skip cart merge in the event the user needs to log in.
	 */
	showJoinRewardsSidePanel(stepToShow = 'join', skipCartMerge = false, isFullScreenPage = false) {
		// If the customer is signed in, show the rewards side panel
		if (!!globalData.customer || isFullScreenPage) {
			self.$rewardsSidePanel.$props.step = stepToShow;
			self.$rewardsSidePanel.$props.reloadOnClose = isFullScreenPage;
			self.$rewardsSidePanel.$props.show = true;
		} else {
			// Otherwise, they'll need to log in or sign up first
			Blain.Login.showSideMenu('login', skipCartMerge, null, true);
		}
	},

	/**
	 * Binds click events to the provided DOM elements.  If no selectorObject is provided,
	 * uses a default object matching selectors used throughout MainWebSite for Joining and Connecting
	 * Rewards accounts.
	 * @param {object} selectorObject for use in binding events to selected DOM elements
	 */
	bindClickEventsForSidePanel(selectorObject) {

		if (!selectorObject) {
			selectorObject = {
				joinLink: '.rjAccept',
				connectLink: '.rjLink'
			};
		};

		const rewardsSignUpTarget = document.querySelectorAll(selectorObject.joinLink);
		const rewardsLinkTarget = document.querySelectorAll(selectorObject.connectLink);

		if (rewardsSignUpTarget?.length > 0 || rewardsLinkTarget?.length > 0) {

			if (rewardsSignUpTarget) {
				rewardsSignUpTarget.forEach(signUpTarget => signUpTarget.addEventListener('click', _e => {
					this.showJoinRewardsSidePanel('join');
				}));
			}

			if (rewardsLinkTarget) {
				rewardsLinkTarget.forEach(linkTarget => linkTarget.addEventListener('click', _e => {
					// The customer wants to connect an existing account, so set a prop to display this view.
					this.showJoinRewardsSidePanel('connect');
				}));
			}

			self.$rewardsSidePanel.$mount(document.createElement('div'));
			document.body.appendChild(self.$rewardsSidePanel.$el);
		}
	}
};

$(document).ready(() => {
	Blain.Rewards.init();
});