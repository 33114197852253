import { DirectiveOptions } from 'vue';
import { getFocusableElements } from '../utilities/dom';

function listener(this: HTMLElement, e: KeyboardEvent): void {
	const TAB = 9;

	if (e.keyCode !== TAB) {
		return;
	}

	const focusable = getFocusableElements(this);
	const firstFocusable = focusable[0];
	const lastFocusable = focusable[focusable.length - 1];

	if (e.shiftKey) {
		if (document.activeElement === firstFocusable) {
			e.preventDefault();
			lastFocusable.focus();
		}
	} else {
		if (document.activeElement === lastFocusable) {
			e.preventDefault();
			firstFocusable.focus();
		}
	}
}

const trapFocus: DirectiveOptions = {
	componentUpdated(el, binding) {
		const shouldTrapFocus = <boolean>binding.value;

		if (shouldTrapFocus) {
			el.addEventListener('keydown', listener);
		} else {
			el.removeEventListener('keydown', listener);
		}
	}
};

export default trapFocus;