import { EmailError } from '../helpers';

/*=======================================================================
== Query String Manipulation
========================================================================*/
(function ($) {
	$.qs = {
		get: function (name) {
			var parseParams = function () {
				var params = {},
					e,
					a = /\+/g, // Regex for replacing addition symbol with a space
					r = /([^&=]+)=?([^&]*)/g,
					d = function (s) { return decodeURIComponent(s.replace(a, " ")); },
					q = window.location.search.substring(1);
				while (e = r.exec(q)) params[d(e[1])] = d(e[2]);
				return params;
			}

			if (!this.qsParams) this.qsParams = parseParams();
			return this.qsParams[name];
		},
		set: function (key, value, url) {
			if (!url) url = window.location.href;
			var re = new RegExp("([?|&])" + key + "=.*?(&|#|$)", "gi");
			if (url.match(re)) {
				var tmp = url;
				if (value) tmp = url.replace(re, '$1' + key + "=" + encodeURIComponent(value) + '$2');
				else tmp = url.replace(re, '$2');
				if (tmp.indexOf('&') != -1 && tmp.indexOf('?') == -1) tmp = tmp.replace(/&/, '?');
				return tmp;
			} else {
				if (value) {
					var separator = url.indexOf('?') != -1 ? '&' : '?',
						hash = url.split('#');
					url = hash[0] + separator + key + '=' + encodeURIComponent(value);
					if (hash[1]) url += '#' + hash[1];
					return url;
				} else return url;
			}
		}
	};
})(jQuery);

// Secure post plugin
(function ($) {
	$.securePost = function (obj) {
		var token = $('input[name="__RequestVerificationToken"]').val();
		obj.headers = {
			'blainVerificationToken': token
		};
		obj.type = "POST";
		obj.contentType = "application/json; charset=utf-8";
		obj.dataType = "json";
		$.ajax(obj);
	};

})(jQuery);

/*=======================================================================
== Blain Modal Plugin
========================================================================*/
/*
 *  Project: Blain Modals
 *  Author: Matthew Seifert, PortalMedia, Inc.
 */

// the semi-colon before function invocation is a safety net against concatenated
// scripts and/or other plugins which may not be closed properly.
; (function ($, window, document, undefined) {

	// undefined is used here as the undefined global variable in ECMAScript 3 is
	// mutable (ie. it can be changed by someone else). undefined isn't really being
	// passed in so we can ensure the value of it is truly undefined. In ES5, undefined
	// can no longer be modified.

	// window is passed through as local variable rather than global
	// as this (slightly) quickens the resolution process and can be more efficiently
	// minified (especially when both are regularly referenced in your plugin).

	// Create the defaults once
	var pluginName = 'blainModal',
		defaults = {
			closeOnEscape: true,
			closeOnEnter: false
		};

	// The actual plugin constructor
	function Plugin(element, options) {
		this.element = element;
		this.$element = $(element);
		this.$content = $(element).find(".blain-modal-content");

		if (!element) return;

		// jQuery has an extend method which merges the contents of two or
		// more objects, storing the result in the first object. The first object
		// is generally empty as we don't want to alter the default options for
		// future instances of the plugin
		this.options = $.extend({}, defaults, options);
		this._defaults = defaults;
		this._name = pluginName;
		this._isShown = false;
		this.templates = {}
		this.init();
	}

	Plugin.prototype.show = function () {
		var modal = this;
		var elem = $(modal.element);
		elem.trigger("blainmodal.show");
		elem.addClass("show");
		$("body").addClass("blain-modal-open");
		modal._isShown = true;
		if (modal.options.closeOnEscape || modal.options.closeOnEnter) {
			$(document).on('keyup.hidemodal', function (e) {
				if (e.which === 13 && modal.options.closeOnEnter) modal.hide();
				if (e.which === 27 && modal.options.closeOnEscape) modal.hide();
			});
		}
		elem.trigger("blainmodal.shown");
	}

	Plugin.prototype.hide = function() {
		var modal = this;
		var elem = $(modal.element);
		elem.trigger("blainmodal.hide");
		elem.removeClass("show");
		this._isShown = false;
		if (this.options.closeOnEscape) {
			$(document).off('keyup.hidemodal');
		}
		if ($(".blain-modal.show").length === 0) $("body").removeClass("blain-modal-open");
		elem.trigger("blainmodal.hidden");
	};

	Plugin.prototype.init = function () {
		var modal = this;
		var elem = $(modal.element);
		if (elem.data('dismissible') === false) modal.options.closeOnEscape = false;
		elem.find("span.close, a.close, button.close").on("click.blainmodal", function (e) {
			e.preventDefault();
			elem.trigger('close');
			modal.hide();
		});
	};

	// You don't need to change something below:
	// A really lightweight plugin wrapper around the constructor,
	// preventing against multiple instantiations and allowing any
	// public function (ie. a function whose name doesn't start
	// with an underscore) to be called via the jQuery plugin,
	// e.g. $(element).defaultPluginName('functionName', arg1, arg2)
	$.fn[pluginName] = function (options) {
		var args = arguments;

		// Is the first parameter an object (options), or was omitted,
		// instantiate a new instance of the plugin.
		if (options === undefined || typeof options === 'object') {
			return this.each(function () {

				// Only allow the plugin to be instantiated once,
				// so we check that the element has no plugin instantiation yet
				if (!$.data(this, 'plugin_' + pluginName)) {

					// if it has no instance, create a new one,
					// pass options to our plugin constructor,
					// and store the plugin instance
					// in the elements jQuery data object.
					$.data(this, 'plugin_' + pluginName, new Plugin(this, options));
				}
			});

			// If the first parameter is a string and it doesn't start
			// with an underscore or "contains" the `init`-function,
			// treat this as a call to a public method.
		} else if (typeof options === 'string' && options[0] !== '_' && options !== 'init') {

			// Cache the method call
			// to make it possible
			// to return a value
			var returns;

			this.each(function () {
				var instance = $.data(this, 'plugin_' + pluginName);

				// Tests that there's already a plugin-instance
				// and checks that the requested public method exists
				if (instance instanceof Plugin && typeof instance[options] === 'function') {

					// Call the method of our plugin instance,
					// and pass it the supplied arguments.
					returns = instance[options].apply(instance, Array.prototype.slice.call(args, 1));
				}

				// Allow instances to be destroyed via the 'destroy' method
				if (options === 'destroy') {
					$.data(this, 'plugin_' + pluginName, null);
				}
			});

			// If the earlier cached method
			// gives a value back return the value,
			// otherwise return this to preserve chainability.
			return returns !== undefined ? returns : this;
		}
	};

}(jQuery, window, document));

// Message modal plugin
(function($) {
	$.modalMsg = function (msg) {
		if (!msg.width || (msg.width !== "wide" && msg.width !== "medium")) msg.width = "narrow";
		let htmlstr = '<div id="messageModal" class="blain-modal-new">'
			+ '<div style="padding: 20px 10px 20px 10px" class="blain-modal-content-new blain-modal-' + msg.width + '">'
			+ '<span class="close">&times;</span>';
		if (msg.title && msg.title !== "") htmlstr += '<h2 class="text-center">' + msg.title.htmlEncode() + '</h2>';
		htmlstr += '<p class="text-center">' + msg.message.htmlEncode() + '</p>';
		if (msg.finePrint && msg.finePrint !== "") htmlstr += '<p class="small text-center">' + msg.finePrint.htmlEncode() + '</p>';
		htmlstr += '<button class="red-btn maxwidth-120 close">OK</button>'
			+ '</div></div></div>';
		const $obj = $(htmlstr);
		$obj.appendTo(document.body);
		$obj.blainModal({ closeOnEnter: true });
		$obj.on("blainmodal.hidden", function () {
			$obj.remove();
		});
		$obj.blainModal("show");
		return $obj;
	}
})(jQuery);

// Confirm modal plugin
(function($) {
	$.modalDialog = function (options) {

		if (!options || typeof options !== "object") {
			throw "Argument passed to $.modalDialog must be an options object";
		} else if (!options["message"] || !options["buttons"]) {
			throw "$.modalDialog options must contain message and buttons";
		} else if (typeof options["message"] !== "object") {
			throw "$.modalDialog options property 'message' must be an object";
		} else if (typeof options["buttons"] !== "object") {
			throw "$.modalDialog options property 'buttons' must be an object";
		}

		const msg  = options["message"];
		const btns = options["buttons"];

		let htmlstr = '<div id="messageModal" class="blain-modal">'
			+ '<div class="blain-modal-content blain-modal-' + msg.width + '">'
			+ '<span class="close">&times;</span>';
		if (msg.title && msg.title !== "") htmlstr += '<h2 class="text-center">' + msg.title.htmlEncode() + '</h2>';
		htmlstr += '<p class="text-center">' + msg.message.htmlEncode() + '</p>';
		if (msg.finePrint && msg.finePrint !== "") htmlstr += '<p class="small text-center">' + msg.finePrint.htmlEncode() + '</p>';
		htmlstr += '<div class="blain-modal-btns"></div>';
		htmlstr += '</div></div></div>';
		const $obj = $(htmlstr);

		if (!msg.width || (msg.width !== "wide" && msg.width !== "medium")) msg.width = "narrow";
		$obj.appendTo(document.body);
		$obj.find("button").on("click", function() {
			$obj.blainModal("hide");
		});
		$obj.blainModal({ closeOnEnter: true });
		$obj.on("blainmodal.hidden", function () {
			$obj.remove();
		});

		// Add buttons
		for (var label in btns) {
			if (btns.hasOwnProperty(label)) {
				const btn = btns[label];
				const $btn = $('<button></button>');
				$btn.addClass(btn.className);
				$btn.text(btn.label);
				$btn.on('click', function() {
					btn.callback($obj);
				});
				$obj.find(".blain-modal-btns").append($btn);
			}
		}

		$obj.blainModal("show");
		return $obj;
	}
})(jQuery);

// Progress modal plugin
(function ($) {
	$.modalProgress = function (msg) {
		let htmlstr = '<div id="progressModal" class="blain-modal-new">'
			+ '<div class="blain-modal-content-new blain-modal-narrow">'
			+ '<div class="spinner"> \
					<div class="ie-10-spinner"></div> \
					<div class="spinner-loader"> \
						<svg class="spinner-circular" viewBox="25 25 50 50"> \
							<circle class="spinner-path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"><circle> \
						</svg> \
					</div> \
				</div>'
			+ '<h3 class="no-margin text-center">' + msg.htmlEncode() + '</h3>'
			+ '</div></div></div>';
		const $obj = $(htmlstr);
		$obj.appendTo(document.body);
		$obj.blainModal({ closeOnEscape: false });
		$obj.on("blainmodal.hidden", function () {
			$obj.remove();
		});
		$obj.blainModal("show");
		return $obj;
	}
})(jQuery);

// Parse AJAX error object
(function ($) {
	$.parseAjaxError = function (xhr) {
		var msg = xhr.responseText;
		if (xhr.status === 404) msg = "URL route not found";
		else if (xhr.status === 403) msg = "We are unable to validate your request. Please ensure that your browser can accept cookies, refresh the page, and then try again. If you continue to have problems after several attempts, please call Customer Service at 800-210-2370.";
		try {
			return JSON.parse(xhr.responseText);
		} catch (ex) {
			return {
				ErrorCode: xhr.status,
				ErrorMessage: msg,
				JSException: ex
			};
		}
	}
})(jQuery);

// DateDiff function
(function($) {
	$.dateDiff = function(start, end, interval) {
		const number = end - start;
		const val = interval.toLowerCase().charAt(0);
		switch (val) {
			case "y":
				return Math.floor(number / 31536000000);
			case "d":
				return Math.floor(number / 86400000);
			case "h":
				return Math.floor(number / 3600000);
			case "m":
				return Math.floor(number / 60000);
			case "s":
				return Math.floor(number / 1000);
			}
		return "";
	};
})(jQuery);

// Viewport plugin
(function($) {
	var $w = $(window);
	$.fn.isInViewport = function(partial, hidden, direction, container) {

		if (this.length < 1) return;

		// Set direction default to 'both'.
		direction = direction || 'both';

		var $t = this.length > 1 ? this.eq(0) : this,
			isContained = typeof container !== 'undefined' && container !== null,
			$c = isContained ? $(container) : $w,
			wPosition = isContained ? $c.position() : 0,
			t = $t.get(0),
			vpWidth = $c.outerWidth(),
			vpHeight = $c.outerHeight(),
			clientSize = hidden === true ? t.offsetWidth * t.offsetHeight : true;

		if (typeof t.getBoundingClientRect === 'function') {

			// Use this native browser method, if available.
			var rec = t.getBoundingClientRect(),
				tViz = isContained ?
				rec.top - wPosition.top >= 0 && rec.top < vpHeight + wPosition.top :
				rec.top >= 0 && rec.top < vpHeight,
				bViz = isContained ?
				rec.bottom - wPosition.top > 0 && rec.bottom <= vpHeight + wPosition.top :
				rec.bottom > 0 && rec.bottom <= vpHeight,
				lViz = isContained ?
				rec.left - wPosition.left >= 0 && rec.left < vpWidth + wPosition.left :
				rec.left >= 0 && rec.left < vpWidth,
				rViz = isContained ?
				rec.right - wPosition.left > 0 && rec.right < vpWidth + wPosition.left :
				rec.right > 0 && rec.right <= vpWidth,
				vVisible = partial ? tViz || bViz : tViz && bViz,
				hVisible = partial ? lViz || rViz : lViz && rViz,
				vVisible = (rec.top < 0 && rec.bottom > vpHeight) ? true : vVisible,
				hVisible = (rec.left < 0 && rec.right > vpWidth) ? true : hVisible;

			if (direction === 'both')
				return clientSize && vVisible && hVisible;
			else if (direction === 'vertical')
				return clientSize && vVisible;
			else if (direction === 'horizontal')
				return clientSize && hVisible;
		} else {

			var viewTop = isContained ? 0 : wPosition,
				viewBottom = viewTop + vpHeight,
				viewLeft = $c.scrollLeft(),
				viewRight = viewLeft + vpWidth,
				position = $t.position(),
				_top = position.top,
				_bottom = _top + $t.height(),
				_left = position.left,
				_right = _left + $t.width(),
				compareTop = partial === true ? _bottom : _top,
				compareBottom = partial === true ? _top : _bottom,
				compareLeft = partial === true ? _right : _left,
				compareRight = partial === true ? _left : _right;

			if (direction === 'both')
				return !!clientSize && ((compareBottom <= viewBottom) && (compareTop >= viewTop)) && ((compareRight <= viewRight) && (compareLeft >= viewLeft));
			else if (direction === 'vertical')
				return !!clientSize && ((compareBottom <= viewBottom) && (compareTop >= viewTop));
			else if (direction === 'horizontal')
				return !!clientSize && ((compareRight <= viewRight) && (compareLeft >= viewLeft));
		}
	};

})(jQuery);

// Global unhandled AJAX error handler
(function ($) {
	$.globalAjaxError = function (err) {
		let width = "medium";
		let height = "normal";
		if (err.ErrorDetail) {
			width = "wide";
			height = "tall";
		}
		let htmlstr = '<div id="ajaxErrorModal" class="blain-modal">'
			+ '<div class="bm-padding blain-modal-content-new blain-modal-' + width + ' blain-modal-' + height + '">'
			+ '<span class="close">&times;</span>'
			+ '<h2>Uh oh, something unexpected happened.</h2>'
			+ '<p>' + err.ErrorMessage.htmlEncode() + '</p>';
		if (err.ErrorDetail && err.ErrorDetail !== null && err.ErrorDetail !== "") {
			htmlstr += '<pre>' + err.ErrorDetail.htmlEncode() + '</pre>';
			EmailError("Global Ajax Error. Error: " + err.ErrorDetail);
		}
		htmlstr += '<button class="red-btn maxwidth-120">OK</button>'
			+ '</div></div></div>';
		const $obj = $(htmlstr);
		$obj.appendTo(document.body);
		$obj.find("button").on("click", function () {
			$obj.blainModal("hide");
		});
		$obj.blainModal({ closeOnEnter: true });
		$obj.on("blainmodal.hidden", function () {
			$obj.remove();
		});
		$obj.blainModal("show");
		return $obj;
	}
})(jQuery);