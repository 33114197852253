// Array.find
Array.prototype.find = Array.prototype.find || function(callback) {
	if (this === null) {
		throw new TypeError('Array.prototype.find called on null or undefined');
	} else if (typeof callback !== 'function') {
		throw new TypeError('callback must be a function');
	}
	const list = Object(this);
	// Makes sures is always has an positive integer as length.
	const length = list.length >>> 0;
	const thisArg = arguments[1];
	for (let i = 0; i < length; i++) {
		const element = list[i];
		if (callback.call(thisArg, element, i, list)) {
			return element;
		}
	}
};

if (typeof Object.assign !== "function") {
	Object.assign = function(target, varArgs) {
		"use strict";
		if (target == null) {
			// TypeError if undefined or null
			throw new TypeError("Cannot convert undefined or null to object");
		}

		const to = Object(target);

		for (let index = 1; index < arguments.length; index++) {
			const nextSource = arguments[index];

			if (nextSource != null) {
				// Skip over if undefined or null
				for (const nextKey in nextSource) {
					// Avoid bugs when hasOwnProperty is shadowed
					if (
						Object.prototype.hasOwnProperty.call(
							nextSource,
							nextKey
						)
					) {
						to[nextKey] = nextSource[nextKey];
					}
				}
			}
		}
		return to;
	};
}

/*
 * Node.isConnected polyfill for IE and EdgeHTML
 * 2020-02-04
 *
 * By Eli Grey, https://eligrey.com
 * Public domain.
 * NO WARRANTY EXPRESSED OR IMPLIED. USE AT YOUR OWN RISK.
 */

if (!("isConnected" in Node.prototype)) {
	Object.defineProperty(Node.prototype, "isConnected", {
		get() {
			return (
				!this.ownerDocument ||
				!(
					this.ownerDocument.compareDocumentPosition(this) &
					this.DOCUMENT_POSITION_DISCONNECTED
				)
			);
		}
	});
}

// Polyfill for Object.remove suggested by MDN https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove
// from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
(function (arr) {
	arr.forEach(function (item) {
		if (item.hasOwnProperty('remove')) {
			return;
		}
		Object.defineProperty(item, 'remove', {
			configurable: true,
			enumerable: true,
			writable: true,
			value: function remove() {
				this.parentNode.removeChild(this);
			}
		});
	});
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);