import bugsnagClient from "JS/global/bugsnag";
import { ExternalAuthGoogleOptions, IAuthenticateError, IAuthenticateResponse, LoginMethod } from "JS/types/Account";
import { IAccountService } from "JS/services/AccountService";
import { onMounted, ref } from "vue";

/**
 * Google needs to render it's own iframe to interact with sign in. 
 * See https://developers.google.com/identity/gsi/web/reference/js-reference#google.accounts.id.renderButton
 * for examples and documentation.
 * Create an element and expose a named ref 
 * `ref={someString}` and corresponding `const {someString} = ref(null);`
 * to pass to this composable, where the sign in button will be mounted.
 * @param accountService Instance or mock of AccountService
 * @param skipCartMerge Skip adding items from user's previous cart on login
 * @param googleSettings Google auth will not work without this parameter
 * @returns 
 */
export function useExternalAuth(
	accountService: IAccountService,
	skipCartMerge: boolean,
	googleSettings?: ExternalAuthGoogleOptions
) {
	const loading = ref(false);
	const error = ref<string>();
	const result = ref<IAuthenticateResponse>();

	const GENERIC_ERROR_MESSAGE = "Something went wrong. Please try again later.";

	async function blainAuthenticate(
		callback: () => Promise<IAuthenticateResponse | IAuthenticateError>,
		loginMethod: LoginMethod
	) {
		if (loading.value) return;
		loading.value = true;
		try {
			const authResult = await callback();

			if (authResult.success) {
				window.blainGtmDataLayer.push({
					'loginMethod': loginMethod,
					'event': "login"
				});
				result.value = authResult;
			} else if ('error' in authResult) {
				const errorMessage = `${authResult.error}`
					+ (authResult.message ? ` - ${authResult.message}` : '');
				error.value = 
					authResult.error
						? errorMessage
						: GENERIC_ERROR_MESSAGE;
			} else {
				error.value = GENERIC_ERROR_MESSAGE;
			}
		} catch (e) {
			error.value = GENERIC_ERROR_MESSAGE;
			bugsnagClient.notify(e);
		} finally {
			loading.value = false;
		}
	}

	function facebookLogin() {
		error.value = undefined;

		// FB auth token is only available inside this closure.
		FB.login((response: fb.StatusResponse) => {
			if (response.status == 'connected') {
				blainAuthenticate(() => accountService.facebookLogin(response.authResponse, skipCartMerge), LoginMethod.Facebook);

			} else if (response.status == 'unknown') {} else {
				error.value = GENERIC_ERROR_MESSAGE;
			}
		}, {
			auth_type: 'rerequest',
			scope: 'email'
		});
	}

	function facebookVerify() {
		error.value = undefined;

		FB.login((response: fb.StatusResponse) => {
			if (response.status == 'connected') {
				blainAuthenticate(() => accountService.facebookVerify(response.authResponse), LoginMethod.Facebook);

			} else if (response.status == 'unknown') {} else {
				error.value = GENERIC_ERROR_MESSAGE;
			}
		}, {
			auth_type: 'rerequest',
			scope: 'email'
		});
	}

	async function appleAuthenticate() {
		//@ts-ignore
		const response = await AppleID.auth.signIn();
		if ('error' in response) {
			switch (response.error) {
				case 'popup_closed_by_user':
					break;
			
				default:
					error.value = GENERIC_ERROR_MESSAGE;
			}

			return;
		} 
		
		return response;
	}

	async function appleLogin() {
		const response = await appleAuthenticate();
		if (!response) return;
		blainAuthenticate(() => accountService.appleLogin(response, skipCartMerge), LoginMethod.Apple);
	}

	async function appleVerify() {
		const response = await appleAuthenticate();
		if (!response) return;
		blainAuthenticate(() => accountService.appleVerify(response), LoginMethod.Apple);
	}

	/**
	 * Since google handles calling it's own authentication we only need to tell it what 
	 * to call afterward (see google init in mounted hook.) We do not, and cannot, reach out to google ourselves for authentication,
	 * so we don't expose a `googleAuthenticate` function.
	 */
	async function googleLogin(response: google.accounts.id.CredentialResponse) { 
		blainAuthenticate(
			() => accountService.googleLogin(response.credential, skipCartMerge),
			LoginMethod.Google
		);
	} 

	async function googleVerify(response: google.accounts.id.CredentialResponse) { 
		blainAuthenticate(
			() => accountService.googleVerify(response.credential),
			LoginMethod.Google
		);
	} 

	// Link to Google SDK in MainWebSite <head>
	function googleInit(googleSettings: ExternalAuthGoogleOptions, verify?: boolean): void {
		if (!googleSettings) return;

		const callback = verify ? googleVerify : googleLogin;
		
		google.accounts.id.initialize({
			client_id: '945353081997-9ul8jsbdnembr4ntvtc75gg25ck8eh41.apps.googleusercontent.com',
			ux_mode: 'popup',
			callback
		});
		google.accounts.id.renderButton(googleSettings.parent.value, googleSettings.options);
	}

	onMounted(() => {
		loading.value = true;
		// GOOGLE INIT ---------------------------
		// Link to Google Identity SDK in MainWebSite <head>
		googleInit(googleSettings);

		// FACEBOOK INIT -------------------------
		window.fbAsyncInit = function() {
			FB.init({
				appId: '1072682922785995',
				xfbml: true,
				version: 'v17.0'
			});
			FB.AppEvents.logPageView();
		};
        
		const script = document.getElementsByTagName('script')[0];
		if (!document.getElementById('facebook-jssdk')) {
			const js = document.createElement('script'); 
			js.id = 'facebook-jssdk';
			js.src = "https://connect.facebook.net/en_US/sdk.js";
			script.parentNode?.insertBefore(js, script);
		}
		
		// APPLE INIT ----------------------------
		// Link to Apple Login SDK in MainWebSite <head>
		AppleID.auth.init({
			clientId: 'com.farmandfleet.mainwebsite',
			scope: 'name email',
			redirectURI: `${window.location.origin}/account/apple-auth`,
			usePopup: true
		});
		loading.value = false;
	});

	return { 
		apple: { 
			login: appleLogin,
			verify: appleVerify
		},
		facebook: {
			login: facebookLogin,
			verify: facebookVerify
		}, 
		googleInit,
		loading, 
		error, 
		result 
	};
}

export interface IExternalAuthHandler {
	login(): void;
	verify(): void;
}
