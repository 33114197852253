
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
	name: 'ButtonSpinner'
})
export default class ButtonSpinner extends Vue {



}
