
import Cleave from "cleave.js";
import { CleaveOptions } from "cleave.js/options";
import { Component, Inject, Mixins, Prop, Watch } from "vue-property-decorator";
import { icons } from "Src/js/constants/icons";
import { PropType } from "vue";

import BFieldWrapper from "./BFieldWrapper.vue";
import Icon from "Src/js/components/Icon.vue";
import WithUniqueId from "Src/js/mixins/WithUniqueId";

import { FormatOptions, getCleaveOptions } from "./formatting";
import {
	formRegistrarKey,
	IFormRegistrar,
	IValidatable,
	ValidatorFunction
} from "./internal-types";

@Component({
	name: "BTextInput",
	components: {
		Icon,
		BFieldWrapper
	}
})
class BTextInput extends Mixins(WithUniqueId) implements IValidatable {
	$refs!: {
		input: HTMLInputElement | HTMLTextAreaElement;
	};

	/**
	 * Optional id to apply to the internal field to ARIA relationsips.
	 * A UUID will be used instead when omitted.
	 */
	@Prop({ type: String })
	id?: string;

	@Prop({ type: String })
	name: string;

	/**
	 * Optional field type
	 */
	@Prop({ type: String, required: false, default: "text" })
	type: string;

	/**
	 * Descriptive label for the field.
	 */
	@Prop({ type: String, required: true })
	label: string;

	/**
	 * Text field value
	 */
	@Prop({ type: String, default: "" })
	value: string;

	/**
	 * Required property of the text field. If true, applies and internal validation rule..
	 */
	@Prop({ type: Boolean })
	required: boolean;

	/**
	 * whether or not to show required or option label flags
	 */
	@Prop({ type: Boolean })
	hideRequirementFlag: boolean;

	/**
	 * Allows passing an external error (perhaps from server validation) to the field's
	 * error message area. Overrides any internal errors.
	 */
	@Prop({ type: String, default: "" })
	error: string;

	/**
	 * Determines text field type. input[type=text] when false (default), textarea when true.
	 */
	@Prop({ type: Boolean })
	multiline: boolean;

	/**
	 * Determines text field type. Controls the "show/hide" span on password fields
	 */
	@Prop({ type: Boolean })
	password: boolean;

	/**
	 * Validation rules applied to the form.
	 */
	@Prop({ type: Array as () => ValidatorFunction[], default: () => [] })
	rules: ValidatorFunction[];

	/**
	 * When true, disables validate-on-blur behavior, only validating when
	 * wrapping form is submitted
	 */
	@Prop({ type: Boolean })
	noAutoValidation: boolean;

	/**
	 * Number used as the maximum in a character counter (eg. 0/**250**). The counter
	 * text will change color to red when `value`'s length has exceeded `counter`.
	 * Can be a number or the string representation of a number. Decimal values will
	 * be discarded.
	 */
	@Prop({
		type: [Number, String],
		validator: (value: string | number | null) =>
			value == null ||
			typeof value === "number" ||
			!isNaN(parseInt(value))
	})
	counter?: number | string;

	/**
	 * Input formatting/masking options. See formatting.ts for more information. Note that this prop is targeted by a watcher,
	 * so do not define the options object directly in the template—it will fire the watcher on every render.
	 */
	@Prop({ type: Object as PropType<FormatOptions>, default: () => ({}) })
	format: FormatOptions;

	/**
	 * IDs of elements to add to aria-describedby for textField
	 */
	@Prop({ type: String, required: false, default: "" })
	readonly ariaDescribedby: string

	@Inject({ from: formRegistrarKey as symbol, default: undefined }) form: IFormRegistrar | undefined;

	/**
	 * Internal error message field for validation errors
	 */
	internalErrorMessage: string = "";

	/**
	 * Whether or not field should validate on input
	 */
	shouldValidate: boolean = false;

	/**
	 * When working with a password field this controls the field type
	 */
	passwordType: string = "password";

	/**
	 * The cleave.js instance
	 */
	cleave: Cleave | null = null;

	/**
	 * A stored reference to cleave's `onValueChanged` hook, if passed by the consumer.
	 */
	cleaveOnValueChanged: CleaveOptions["onValueChanged"] | null = null;

	/**
	 * Proxies input event to `handleChange` in cases where cleave.js is not active.
	 */
	handleInput(e: InputEvent) {
		const { properties } = this.cleave ?? {};

		// HACK: this is the condition the cleave uses internally to determine whether to setup listeners
		const cleaveWontFireChanges =
			!properties ||
			(!properties.numeral &&
				!properties.phone &&
				!properties.creditCard &&
				!properties.time &&
				!properties.date &&
				(properties as any).blocksLength === 0 && !properties.prefix);

		if (cleaveWontFireChanges) {
			const el = e.target as HTMLInputElement | HTMLTextAreaElement;
			this.handleChange({
				target: { value: el.value, rawValue: el.value }
			});
		}
	}

	/**
	 * Handles event emission and validation when the input value changes. This takes the place of the cleave's `onValueChanged` option,
	 * so if the consumer has passed a value for `onValueChanged`, it calls a stored reference to it.
	 */
	handleChange(e: { target: { value: string; rawValue: string } }) {
		this.cleaveOnValueChanged?.(e);

		// when `value` is null, cleave attempts to normalize it to empty string. We want null to remain a valid value,
		// so prevent that normalization from firing an unnecessary change
		const isCleaveChangingNullToEmptyString = this.value === null &&
													(this.format.emitFormattedValue && e.target.value === '' ||
													!this.format.emitFormattedValue && e.target.rawValue === '');
		if (!isCleaveChangingNullToEmptyString) {
			this.$emit(
				"input",
				this.format.emitFormattedValue ? e.target.value : e.target.rawValue
			);
		}

		// Ensures validation happens at the correct time
		this.$nextTick(() => {
			setTimeout(() => {
				if (this.shouldValidate) {
					const valid = this.validate();
					if (valid === true) {
						this.shouldValidate = false;
					}
				}
			}, 0);
		});
	}

	/**
	 * Id used for checkbox and ARIA relationships. Id prop if passed,
	 * otherwise generated unique id
	 */
	get $id() {
		return this.id || this.uuid;
	}

	/**
	 * Field validity indicator icon
	 */
	get fieldStateIcon() {
		if (this.errorMessage) {
			return {
				name: icons.alert,
				className: "error"
			};
		} else if (this.value) {
			return {
				name: icons.checkmark,
				className: "valid"
			};
		}

		return null;
	}

	/**
	 * Returns the `error` props if it's not undefined or empty and the `internalErrorMessage` otherwise
	 */
	get errorMessage() {
		return this.error || this.internalErrorMessage;
	}

	/**
	 * Internal rules array. Augments `rules` with an additional validation rules when `required` is true.
	 */
	get _rules(): ValidatorFunction[] {
		// If there's an external error message applied, this field is not valid.
		const externalErrorValidator: ValidatorFunction = _value =>
			this.error || true;

		const internalRules = [externalErrorValidator];
		if (this.required) {
			internalRules.push(value => {
				if (!value || value.trim() === "")
					return "This field is required.";
				return true;
			});
		}

		return internalRules.concat(this.rules);
	}

	/**
	 * Returns the parsed or rounded counter value for use in the template
	 */
	get counterValue(): number | null {
		if (this.counter == null) {
			return null;
		}

		if (typeof this.counter === "string") {
			const parsed = parseInt(this.counter);
			if (isNaN(parsed)) {
				return null;
			}
			return parsed;
		}

		return Math.floor(this.counter);
	}

	get inputLength(): number {
		return this.value ? this.value.length : 0;
	}

	/**
	 * Controls the input type. Currently used to control password field type
	 */
	get fieldType() {
		return this.multiline
			? null
			: this.password
				? this.passwordType
				: this.type;
	}

	/**
	 * What icon should we show in the password toggle
	 */
	get passwordIcon() {
		return this.passwordType === "text" ? "view" : "hide";
	}

	/**
	 * Toggles the field type from password to text
	 */
	toggleShowPassword() {
		this.passwordType =
			this.passwordType === "password" ? "text" : "password";
	}

	/**
	 * Handles validation behavior when field is blurred
	 */
	onBlur() {
		if (this.noAutoValidation) {
			return;
		}

		this.$emit('blur');

		this.startValidating();
	}

	/**
	 * Enables on-change field validation and runs the initial validation
	 */
	startValidating() {
		this.shouldValidate = true;

		const valid = this.validate();
		if (valid === true) {
			this.shouldValidate = false;
		}
	}

	/**
	 * IValidatable implementation.
	 */
	validate(): string | true {
		let value: string;
		if (this.cleave) {
			value = this.format?.validateAgainstFormattedValue
				? this.cleave.getFormattedValue()
				: this.cleave.getRawValue();
		} else {
			value = this.value;
		}

		const error = this._rules
			.map(rule => rule(value))
			.find(validationResult => validationResult !== true) as
			| string
			| undefined;

		if (error) {
			this.internalErrorMessage = error;
			this.shouldValidate = true;
			return error;
		}

		this.internalErrorMessage = "";
		return true;
	}

	/**
	 * IValidatable implementation.
	 */
	resetValidation() {
		this.shouldValidate = false;
		this.internalErrorMessage = '';
	}

	/**
	 * Destroys any existing cleave instance and initializes a new one if `format` is valid.
	 * Sets the values of `cleaveOnValueChanged` using any value passed in on `onValueChange`
	 * in the cleave options object.
	 */
	maybeInitializeCleave(format: FormatOptions) {
		if (this.cleave) {
			this.cleave.destroy();
			this.cleaveOnValueChanged = null;
		}

		const options = getCleaveOptions(format);

		if (!options) {
			this.cleave = null;
			return;
		}

		const { onValueChanged, ...remainingOptions } = options;

		this.cleaveOnValueChanged = onValueChanged;

		this.cleave = new Cleave(this.$refs.input, {
			...remainingOptions,
			onValueChanged: this.handleChange.bind(this)
		});
	}

	mounted() {
		this.form?.register?.(this);
		this.maybeInitializeCleave(this.format);
	}

	beforeDestroy() {
		this.form?.unregister?.(this);
		this.cleave?.destroy();
	}

	@Watch("value")
	onValueChange(value: string) {
		if (!this.cleave) {
			return;
		}

		const newValueIsSameAsRawValue =
			!this.format.emitFormattedValue &&
			value === this.cleave.getRawValue();
		const newValueIsSameAsFormattedValue =
			this.format.emitFormattedValue && value === this.$refs.input.value;

		const newValueIsSameAsEmittedValue =
			newValueIsSameAsRawValue || newValueIsSameAsFormattedValue;
		if (newValueIsSameAsEmittedValue) {
			return;
		}

		this.cleave.setRawValue(value);
	}

	@Watch("error")
	onErrorChange(error: string) {
		if (error === this.internalErrorMessage) return;
		this.internalErrorMessage = error;
	}

	@Watch("format", { deep: true })
	onFormatChange(format: FormatOptions) {
		this.maybeInitializeCleave(format);
	}
}

export default BTextInput;
