import {
	ButtonStateChangeEvent,
	ButtonStateChangeListener,
	IListSlider,
	SlideChangeEvent,
	SlideChangeListener,
	SlideEndListener,
	SliderMoveDirection,
} from "./types";

import { floatEquals } from "JS/utilities/number";

/**
 * Creates an API for sliding list items within a given element.
 *
 * This function assumes all list items are of equal width and are laid out in a single row.
 * The viewport overflow style should be set to 'hidden' and the list items should not wrap.
 * We make these concessions to improve layout shifting and main thread performance.
 *
 * View `carousels.less` to see an example of proper list-slider styles. The helpers in `_mixins.less`
 * are useful.
 *
 * @param viewport An HTML element containing the slider track. The track should be one of
 *                   1. a `<ul>` (the track) with `<li>` children (the items)
 *                   2. an element with the `data-list-slider-track` attribute (the track) and direct children with the `data-list-slider-item` attribute (the items)
 */
export function createListSlider(viewport: HTMLElement): IListSlider {
	if (!(viewport instanceof HTMLElement)) return;

	const track = getTrack(viewport);
	if (!track) return;

	function getItems(): HTMLElement[] {
		return Array.from(track.children) as HTMLElement[];
	}

	let leftItemIndex: number = 0;
	let nextButton: HTMLButtonElement | null = null;
	let prevButton: HTMLButtonElement | null = null;

	const slideListeners = new Set<SlideChangeListener>();
	function triggerSlideChangeEvent(e: SlideChangeEvent) {
		slideListeners.forEach(listener => listener(e));
	}

	const slideEndListeners = new Set<SlideEndListener>();
	const buttonStateListeners = new Set<ButtonStateChangeListener>();

	function slideIndexIntoView(index: number) {
		const items = getItems();
		// Nothing to slide in this case
		if (items.length < 2) return;

		// track is a normal, horizontally scrollable element. this probably means mobile or tablet.
		if (!isControlled(viewport)) {
			items[index].scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'nearest' });
			return;
		}

		const itemsPerSlide = getFullItemsPerSlide(viewport, items);

		const itemIsAlreadyVisible = index >= leftItemIndex && index < leftItemIndex + itemsPerSlide;
		if (itemIsAlreadyVisible) {
			return;
		}

		let direction: SliderMoveDirection;
		let nextLeftItemIndex: number;
		if (index < leftItemIndex) {
			direction = 'backward';
			nextLeftItemIndex = Math.max(0, index - (index % itemsPerSlide));
		} else {
			direction = 'forward';
			nextLeftItemIndex = Math.min(items.length - itemsPerSlide, index - (index % itemsPerSlide));
		}

		const slideEvent = new SlideChangeEvent({
			fromSlide: getSlideForIndex(leftItemIndex),
			toSlide: getSlideForIndex(nextLeftItemIndex),
			slideCount: getNumSlides(),
		});

		triggerSlideChangeEvent(slideEvent);

		if (slideEvent.defaultPrevented) {
			return;
		}

		leftItemIndex = nextLeftItemIndex;

		slideTrack(viewport, track, leftItemIndex, items, () => {
			slideEndListeners.forEach(listener => listener(direction));
		});

		updateButtonState();
	}

	function slide(direction: SliderMoveDirection): void {
		slideIndexIntoView(
			direction === 'forward'
				? leftItemIndex + getFullItemsPerSlide(viewport, getItems())
				: leftItemIndex - 1
		);
	}

	function getNumSlides(): number {
		const items = getItems();
		const itemsPerSlide = getFullItemsPerSlide(viewport, items);

		return Math.ceil(items.length / itemsPerSlide);
	}

	function goToSlide(slide: number): void {
		const items = getItems();
		const itemsPerSlide = getFullItemsPerSlide(viewport, items);

		slideIndexIntoView(slide * itemsPerSlide);
	}

	function getSlideForIndex(leftItemIndex: number) {
		const items = getItems();
		const itemsPerSlide = getFullItemsPerSlide(viewport, items);

		let currentSlide = Math.floor(leftItemIndex / itemsPerSlide);

		// The last slide is not always a full slide of products so we need to see if we have a remainder here to determine if we are on the last slide
		const remainder = leftItemIndex % itemsPerSlide;
		if (remainder > 0) {
			currentSlide++;
		}

		return currentSlide;
	}

	function getCurrentSlide(): number {
		return getSlideForIndex(leftItemIndex);
	}

	const slideForward = () => slide('forward');
	const slideBackward = () => slide('backward');

	const nextButtonClickHandler = () => {
		if (nextButton && !isCarouselButtonDisabled(nextButton)) {
			slideForward();
		}
	};

	const previousButtonClickHandler = () => {
		if (prevButton && !isCarouselButtonDisabled(prevButton)) {
			slideBackward();
		}
	};

	function setControls(newPrevButton: HTMLButtonElement, newNextButton: HTMLButtonElement): void {
		if (newPrevButton instanceof HTMLButtonElement) {
			prevButton?.removeEventListener('click', previousButtonClickHandler);
			prevButton = newPrevButton;
			prevButton.addEventListener('click', previousButtonClickHandler);
		}

		if (newNextButton instanceof HTMLButtonElement) {
			nextButton?.removeEventListener('click', nextButtonClickHandler);
			nextButton = newNextButton;
			nextButton.addEventListener('click', nextButtonClickHandler);
		}

		updateButtonState();
	}

	function updateButtonState() {
		const disablePrevious = leftItemIndex <= 0;
		const items = getItems();
		const disableNext = !canSlideFurtherRight({
			leftItemIndex,
			fullItemsPerSlide: getFullItemsPerSlide(viewport, items),
			itemCount: items.length
		});

		if (prevButton) {
			prevButton.setAttribute('aria-disabled', disablePrevious.toString());
		}

		if (nextButton) {
			nextButton.setAttribute('aria-disabled', disableNext.toString());
		}

		const e = new ButtonStateChangeEvent({
			previousDisabled: disablePrevious,
			nextDisabled: disableNext,
		});

		buttonStateListeners.forEach(l => l(e));
	}

	function reset(): void {
		track.style.transition = 'none';
		track.style.transform = `translate3d(0, 0, 0)`;
		leftItemIndex = 0;
		viewport.scrollLeft = 0;
		updateButtonState();
	}

	function onSlide(listener: SlideChangeListener): () => void {
		slideListeners.add(listener);

		return () => slideListeners.delete(listener);
	}

	function onSlideEnd(listener: SlideEndListener): () => void {
		slideEndListeners.add(listener);

		return () => slideEndListeners.delete(listener);
	}

	function onButtonStateChange(listener: ButtonStateChangeListener): () => void {
		buttonStateListeners.add(listener);

		return () => buttonStateListeners.delete(listener);
	}

	function handleTrackFocus(e: FocusEvent) {
		const itemIndex = getItems().findIndex(n => n.contains(e.target as HTMLElement));

		if (itemIndex >= 0) {
			slideIndexIntoView(itemIndex);
		}
	}

	function handleTrackMousedown(e: MouseEvent) {
		e.preventDefault();
	}

	function handleViewportScroll(e: Event) {
		if (isControlled(viewport)) { // new list sliders scroll the viewport, not the track
			(e.target as HTMLElement).scrollLeft = 0;
		}
	}

	function addListeners() {
		// when an item or one of its children is focused, make sure the item is visible
		track.addEventListener('focus', handleTrackFocus, { capture: true });

		// Prevents auto-scrolling when user clicks on a partially visible card in the carousel.
		track.addEventListener('mousedown', handleTrackMousedown);


		// when an item inside of the viewport is focused, the browser automatically scrolls to it.
		// the viewport should not move/scroll whatsoever. movement should only happen using the track element
		viewport.addEventListener('scroll', handleViewportScroll);
	}

	function removeListeners() {
		// when an item or one of its children is focused, make sure the item is visible
		track.removeEventListener('focus', handleTrackFocus, { capture: true });

		// Prevents auto-scrolling when user clicks on a partially visible card in the carousel.
		track.removeEventListener('mousedown', handleTrackMousedown);


		// when an item inside of the viewport is focused, the browser automatically scrolls to it.
		// the viewport should not move/scroll whatsoever. movement should only happen using the track element
		viewport.removeEventListener('scroll', handleViewportScroll);
	}

	addListeners();

	return {
		reset,
		setControls,
		slideForward,
		slideBackward,
		onSlide,
		onSlideEnd,
		onButtonStateChange,
		slideIndexIntoView,
		getNumSlides,
		goToSlide,
		getCurrentSlide,
		destroy: () => {
			removeListeners();

			if (prevButton) {
				toggleCarouselButtonDisabled(prevButton, false);
				prevButton?.removeEventListener('click', previousButtonClickHandler);
			}

			if (nextButton) {
				toggleCarouselButtonDisabled(nextButton, false);
				nextButton.removeEventListener('click', nextButtonClickHandler);
			}
		}
	};
}

/*=================================================================
 == Utility functions -- Not specific to a single carousel instance
/==================================================================*/

export function getTrack(viewport: HTMLElement): HTMLElement | null {
	return viewport.querySelector('[data-list-slider-track]') ?? viewport.querySelector('ul');
}

function isControlled(viewport: HTMLElement) {
	return getComputedStyle(viewport).overflowX !== 'scroll';
}

// Gets the number of full items per slide
export function getFullItemsPerSlide(viewport: HTMLElement, items: HTMLElement[]): number {
	const itemsPerSlide = getItemsPerSlide(viewport, items);

	// need a fairly large threshold here given the browser's rounding of percentage-widths
	if (floatEquals(itemsPerSlide, Math.round(itemsPerSlide), 0.09)) {
		return Math.round(itemsPerSlide);
	}

	return Math.floor(itemsPerSlide);
}

// Gets the number of items per slide
function getItemsPerSlide(viewport: HTMLElement, items: HTMLElement[]): number {
	if (!items.length) return 0;

	try {
		const style = getComputedStyle(items[0]);
		const itemsPerSlide = parseFloat(style.getPropertyValue('--list-slider-items-per-slide'));
		if (!isNaN(itemsPerSlide)) {
			return itemsPerSlide;
		} else {
			const fullItemsPerSlide = parseFloat(style.getPropertyValue('--list-slider-full-items-per-slide'));
			let peeking = parseFloat(style.getPropertyValue('--list-slider-item-peeking'));
			if (isNaN(peeking)) {
				peeking = 0;
			}

			if (!isNaN(fullItemsPerSlide)) {
				return fullItemsPerSlide + peeking;
			}
		}
	} catch (_) {}

	const fcr = items[0].getBoundingClientRect();
	let gap = parseFloat(getComputedStyle(getTrack(viewport)).gap);
	if (isNaN(gap)) {
		gap = 0;
	}
	const gapAvg = gap * (items.length - 1) / items.length;
	return viewport.getBoundingClientRect().width / (fcr.width + gapAvg);
}

function getItemDistanceFromStartOfTrack(item: HTMLElement, track: HTMLElement): number {
	const { left: itemLeft } = item.getBoundingClientRect();
	const { left: trackLeft } = track.getBoundingClientRect();

	return Math.abs(itemLeft - trackLeft);
}

// transforms the track horizonally to the given item index
function slideTrack(viewport: HTMLElement, track: HTMLElement, nextIndex: number, items: HTMLElement[], onTransitionEnd?: (e: TransitionEvent) => void): void {
	const hasReachedEnd = nextIndex !== 0 && !canSlideFurtherRight({
		leftItemIndex: nextIndex,
		fullItemsPerSlide: getFullItemsPerSlide(viewport, items),
		itemCount: items.length
	});

	const translateX = hasReachedEnd
		? track.scrollWidth - track.clientWidth // just slide to the end. otherwise, we'd end up with a partially-empty viewport
		: getItemDistanceFromStartOfTrack(items[nextIndex], track);

	function transitionEndListener(e: TransitionEvent): void {
		if (e.target !== track) {
			return;
		}

		onTransitionEnd?.(e);
		track.removeEventListener('transitionend', transitionEndListener);
	};

	track.addEventListener('transitionend', transitionEndListener);

	track.style.transition = 'transform 0.4s ease-in-out';
	track.style.transform = `translate3d(-${translateX}px, 0, 0)`;
	// prevents weird flicker in Safari;
	track.style.backfaceVisibility = 'hidden';
}

/**
 * Determines whether a slider can move further right given
 * a) the index of the left-most fully-visible item
 * b) the number of *full* items per slide e.g. if there are 3.75 items per slide, then there are 3 full items
 * c) total items in the slider
 */
function canSlideFurtherRight({
	leftItemIndex,
	fullItemsPerSlide,
	itemCount
}: {
	leftItemIndex: number;
	fullItemsPerSlide: number;
	itemCount: number;
}) {
	return leftItemIndex + fullItemsPerSlide < itemCount;
}

/**
 * @returns true if the button is disabled -- either by the disabled property or the aria-disabled attribute.
 */
export function isCarouselButtonDisabled(button: HTMLButtonElement) {
	return button.disabled || button.getAttribute('aria-disabled') === 'true';
}

/**
 * Toggles {@link button}'s aria-disabled state
 * @param button
 */
export function toggleCarouselButtonDisabled(button: HTMLButtonElement): void
/**
 * Sets {@link button}'s aria-disabled attribute to {@link disabled} (stringified)
 * @param button
 * @param disabled
 */
export function toggleCarouselButtonDisabled(button: HTMLButtonElement, disabled: boolean): void
export function toggleCarouselButtonDisabled(button: HTMLButtonElement, disabled?: boolean): void {
	const newValue = typeof disabled === 'undefined'
		? !isCarouselButtonDisabled(button)
		: disabled.toString();

	button.setAttribute('aria-disabled', newValue.toString());
}