
import HeaderDropMenu from '../HeaderDropMenu.vue';
import Icon from 'JS/components/Icon.vue';
import IconBadge from '../IconBadge.vue';
import QuickCart from './QuickCart.vue';
import { defineComponent } from 'vue';
import { IHeaderJson } from 'Src/js/types/Header';
import { PropType } from 'vue';
import { useBreakpoints } from 'JS/composables/breakpoint';

export default defineComponent({
	name: 'HeaderCartDropdown',
	components: {
		HeaderDropMenu,
		Icon,
		IconBadge,
		QuickCart
	},
	props: {
		headerData: { type: Object as PropType<IHeaderJson>, required: true },
	},
	setup() {
		const { isMobile } = useBreakpoints();
		return {
			isMobile,
		};
	}
});
