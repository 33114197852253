<template functional>
	<div class="spinner" :class="data.staticClass" v-bind="data.attrs">
		<div class="ie-10-spinner"></div>
		<div class="spinner-loader">
			<svg class="spinner-circular" viewBox="25 25 50 50">
				<circle
					class="spinner-path"
					cx="50"
					cy="50"
					r="20"
					fill="none"
					stroke-width="2"
					stroke-miterlimit="10"
				/>
			</svg>
		</div>
	</div>
</template>

<script>
export default { name: 'Spinner' };
</script>