/**
 * Delays a Promise in cases where the result may be available _too_ quickly. Good for showing loading states for speedy network requests.
 * Resolves after the time it takes for `promise` to resolve, or after `delayInMS` --- whichever takes longer. Will reject immediately on error.
 * 
 * @param promise The Promise to delay
 * @param delayInMS The time to delay the result of `promise` in milliseconds. This won't add additional time if `promise` takes longer to resolve
 * @param shouldResolveEagerly Optional predicate for resolving `promise` before `delayInMS` has elapsed. Useful in cases where --- for example --- there may be form errors that should be shown ASAP.
 * 
 * @returns The wrapped Promise. It will have the same result/error as the original `promise`.
 */
export function delay<T>(promise: Promise<T>, delayInMS: number, shouldResolveEagerly?: (result: T) => boolean): Promise<T> {
	return new Promise((resolve, reject) => {
		const timeout = setTimeout(() => {
			try {
				resolve(promise);
			} catch (e) {
				reject(e);
			}
		}, delayInMS);

		promise.then(result => {
			if (shouldResolveEagerly?.(result)) {
				clearTimeout(timeout);
				resolve(result);
			}
		}).catch(e => reject(e));
	});
}