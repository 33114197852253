export function getScriptResources(): PerformanceEntry[] {
	if (!performance?.getEntriesByType) {
		// eslint-disable-next-line no-console
		console.log("performance API not supported");
		return [];
	}

	// Get a list of "resource" performance entries
	const resources = performance.getEntriesByType("resource");
	if (resources === undefined || resources.length <= 0) {
		return [];
	}

	const scripts = resources.filter(
		// @ts-ignore
		resource => resource.initiatorType === "script"
	);

	//@ts-ignore
	return scripts.sort((a, b) => a.responseEnd - b.responseEnd);
}


/**
 * Attemts to get an error message from unknown type, useful for catch blocks
 * @param error
 * @returns {string} The error message
 */
export function getErrorMessage(error: any): string {
	const defaultMessage = 'An unknown error occurred.';
	if (!error) return defaultMessage;
	if (typeof error === 'string') return error;
	if (typeof error === 'object' && Object.prototype.hasOwnProperty.call(error, 'message')) {
		return error.message;
	}
	return defaultMessage;
}