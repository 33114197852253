<template>
	<div class="scroll-hidden" ref="scroll_container" :style="containerStyles">
		<div class="scroll-content" ref="scroll_content">
			<slot></slot>
		</div>
		<div
			:class="`scroll-hint ${hintClass}`"
			v-show="showScrollHint"
			ref="scroll_hint"
		>
			<span class="no-select">
				scroll for more <span class="icon icon-tiny icon-chevron-down-tiny"></span>
			</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ScrollableContainer',
	props: {
		includeScrollHint: {
			type: Boolean,
			default: false
		},
		maxHeight: {
			type: Number,
			default: 480
		},
		hintClass: {
			type: String,
			default: 'white'
		},
	},
	computed: {
		containerStyles() {
			return `
				max-height: ${this.maxHeight}px;
			`;
		}
	},
	data() {
		return {
			showScrollHint: this.includeScrollHint,
			scrollHintHeight: 70
		};
	},
	methods: {
		handleScroll(event) {
			const { scrollTop, scrollHeight, offsetHeight } = event.target;
			if (!this.showScrollHint && (scrollTop + offsetHeight + this.scrollHintHeight < scrollHeight)) {
				this.showScrollHint = true;
			} else if (this.showScrollHint && (scrollTop + offsetHeight + this.scrollHintHeight > scrollHeight)) {
				this.showScrollHint = false;
			}
			this.$emit('scroll', event);
		},
	},
	mounted() {
		if (this.includeScrollHint) {
			this.$refs.scroll_container.addEventListener('scroll', e => this.handleScroll(e));
		}
	},
	beforeDestroy() {
		if (this.includeScrollHint) {
			this.$refs.scroll_container.removeEventListener('scroll', e => this.handleScroll(e));
		}
	}
};
</script>

<style lang='less'>
.scroll-hidden {
	overflow-y: auto;
	overflow-x: hidden;
	position: relative;
	margin-bottom: 5px;
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar { width: 0 !important }

	.scroll-hint {
		min-height: 70px;
		bottom: 0;
		position: sticky;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		color: #717BA9;
		font-size: 14px;
		&.grey {
			border-bottom: 2px solid #E5E5E5;
			margin: 0 10px;
			background: rgb(245,245,245);
			background: linear-gradient(180deg, rgba(245,245,245,0) 0%, rgba(245,245,245,1) 50%, rgba(245,245,245,1) 100%);
		}
		&.white {
			background: #fff;
			background: transparent linear-gradient(180deg, #E5E7F100 0%, #FFFFFF 63%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
		}
		span {
			display: flex;
			align-items: center;
		}
		.icon-tiny {
			font-size: 7px;
			margin-left: 7px;
		}
	}
}
</style>