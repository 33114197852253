import type { Ref } from "vue";

export const LoginPages = {
	"login": 0,
 	"create": 1,
	"forgot-password": 2
} as const;

export type LoginPageType = keyof typeof LoginPages;

export type PasswordRequirements = {
	hasMinLength: boolean,
	hasLowercase: boolean,
	hasUppercase: boolean,
	hasNumber: boolean
}

export interface IAuthenticateResource {
	userName: string,
	password: string,
	saveLogin: boolean,
	hasCaptcha: boolean,
	captcha: string,
	skipCartMerge?: boolean
}

export interface IAuthenticateResponse {
	success: boolean,
	captchaFailed?: boolean,
	captchaRequired?: boolean,
	passwordChangeRequired?: boolean,
	redirectUrl?: string,
	isRewardsMember?: boolean,
	isNewAccount?: boolean,
	headerJson?: object,
}

export interface IAuthenticateError {
	success: boolean,
	error: string,
	message: string
}

export interface ICreateAccountResource {
	firstName: string,
	lastName: string,
	emailAddress: string,
	password: string,
	saveLogin: boolean,
}

export interface ICreateAccountResponse {
	success: boolean,
	headerJson: object,
}

export interface IRecoveryResource {
	emailAddress: string,
	verificationToken: string
}

export interface IRecoveryResponse {
	success: boolean
}

export interface IGetRewardsDataResponse {
	success: boolean,
	customerHasRewards: boolean,
	sessionMFound: boolean,
	phone: string,
	email: string,
}

export type ExternalAuthGoogleOptions = {
	parent: Ref<HTMLElement>,
	options: google.accounts.id.GsiButtonConfiguration
}

export enum LoginMethod {
	Local = "Local",
	Google = "Google",
	Facebook = "Facebook",
	Apple = "Apple"
}
