
import UiButton from '../UiButton.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
	components: { UiButton },
	name: 'BSubmitButton',
})
class BSubmitButton extends Vue {
}

export default BSubmitButton;
