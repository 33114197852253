
import TransitionExpand from 'Src/js/components/transitions/TransitionExpand.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
	name: 'BFieldWrapper',
	components: {
		TransitionExpand,
	},
})
class BFieldWrapper extends Vue {
	@Prop({ type: String, required: true })
	fieldId: string;

	@Prop({ type: String, default: '' })
	error: string;

	get ariaDescribedBy(): string { // ID reference list - https://www.w3.org/TR/wai-aria/#typemapping
		const ids = [this.messagesLeftId, this.messagesRightId];
		if (this.error) {
			ids.unshift(this.errorMessageID);
		}
		return ids.join(' ');
	}

	get errorMessageID(): string {
		return `${this.fieldId}-error-message`;
	}

	get messagesLeftId(): string {
		return `${this.fieldId}-left-message`;
	}

	get messagesRightId(): string {
		return `${this.fieldId}-right-message`;
	}
}

export default BFieldWrapper;
