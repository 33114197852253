import BaseHttpService from './BaseHttpService';
import EventBusService from './EventBusService';
import LoggerService from './LoggerService';
import { IBaseHttpService } from '../types/Http';
import { IEventBusService } from '../types/EventBus';
import { ILoggerService } from '../types/Logger';

export default class BlainBaseService {

	protected readonly _httpSvc: IBaseHttpService;
	protected readonly _eventBus: IEventBusService;
	protected readonly _logger: ILoggerService;

	constructor(
		eventBusService: IEventBusService = new EventBusService(),
		loggerService: ILoggerService = new LoggerService(),
		httpSvc: IBaseHttpService = new BaseHttpService()
	) {
		this._eventBus = eventBusService;
		this._logger = loggerService;
		this._httpSvc = httpSvc;
	}

	handleError(error: string, supressLogger: boolean = false): void {
		// eslint-disable-next-line no-console
		console.error(error);
		if (supressLogger) this._logger.log(error);
	}

}