import { formRegistrarKey, IValidatable } from "./internal-types";
import { inject, onBeforeUnmount, onMounted } from "vue";

/**
 * Composable that handles component registration with its parent form. `formChild` is an `IValidatable`
 * that exposes handles with which the form can trigger behavior in its child components when validation
 * runs.
 */
export function useFormRegistration(formChild: IValidatable) {
	const form = inject(formRegistrarKey, undefined);

	onMounted(() => {
		form?.register?.(formChild);
	});

	onBeforeUnmount(() => {
		form?.unregister?.(formChild);
	});
}
