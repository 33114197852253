import Vue, { defineComponent } from "vue";
import { Component } from "vue-property-decorator";
import { useUuid } from "JS/composables/uuid";

@Component({
	extends: defineComponent({
		setup() {
			return {
				actualUuid: useUuid(),
			};
		},
	})
})
/**
 * Simple mixin that provides consumer components with unique id.
 * Useful for defining reusable components that need to specify
 * ARIA relationships with IDs
 * @deprecated Use `useUuid` instead.
 */
export default class WithUniqueId extends Vue {
	declare private readonly actualUuid: string;

	/**
     * Unique ID (uuid v4)
	 * @deprecated Use `useUuid` instead.
     */
	get uuid() {
		return this.actualUuid;
	}
}