
import BaseButton from './BaseButton.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
	components: { BaseButton },
	name: 'LinkButton',
})
class LinkButton extends Vue {
	@Prop({ type: String })
	href?: string;

	@Prop({ type: Boolean })
	padded: boolean;

	get classes() {
		return [
			'link-button',
			this.padded && 'link-button--padded',
		];
	}
}

export default LinkButton;
