var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.BFieldWrapper,{staticClass:"blain-form-select",class:{
		'blain-form-select--disabled': _vm.disabled,
	},attrs:{"field-id":_setup.selectElementId,"error":_setup.errorMessage,"data-test":"blain-form-text-field"},scopedSlots:_vm._u([{key:"default",fn:function({ ariaDescribedBy }){return [(_vm.labelDisplayType === 'normal')?_c('label',{staticClass:"blain-form-select__label",attrs:{"for":_setup.selectElementId}},[_c('span',{staticClass:"blain-form-select__label-text"},[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),(!_vm.hideRequirementFlag)?[(_vm.required)?_c('span',{staticClass:"blain-form-select__required-notice",attrs:{"aria-hidden":"true"}},[_vm._v(" *required")]):_c('span',{staticClass:"blain-form-select__optional-notice",attrs:{"aria-hidden":"true"}},[_vm._v(" —optional")])]:_vm._e()],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"blain-form-select__inner relative"},[(_vm.labelDisplayType === 'inside')?_c('label',{ref:"insideLabel",staticClass:"blain-form-select__inside-label",attrs:{"for":_setup.selectElementId}},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.label)+"\n\t\t\t")]):_vm._e(),_vm._v(" "),_c('select',_vm._b({ref:"selectElement",class:[
					'blain-form-select__select',
					!!_setup.errorMessage && 'error',
					_setup.errorMessage ? 'focusable-error' : '',
					_setup.styleAsPlaceholder && 'blain-form-select__select--placeholder'
				],style:({
					paddingLeft: _setup.selectLeftPadding,
				}),attrs:{"id":_setup.selectElementId,"aria-required":_vm.required ? 'true' : 'false',"aria-describedby":ariaDescribedBy,"disabled":_vm.disabled},domProps:{"value":_setup.internalValue},on:{"input":_setup.handleInput,"blur":_setup.onBlur}},'select',_vm.$attrs,false),[(_vm.placeholder)?_c('option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v(_vm._s(_vm.placeholder))]):_vm._e(),_vm._v(" "),_vm._t("default")],2),_vm._v(" "),_c('div',{staticClass:"blain-form-select__icon-wrapper"},[_c(_setup.Icon,{staticClass:"blain-form-select__icon",attrs:{"name":"chevron-down"}})],1)])]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }