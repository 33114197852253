import { IHeaderJson } from './Header';
import { IQuickCartSummaryJson } from './Order';

export interface IEventBusService {
	/** @deprecated Use `emit` with the relevant event type and payload instead */
	updateQuickCart(cartSummary: IQuickCartSummaryJson): void;

	/** @deprecated Use `on` with the relevant event type instead */
	listenOnLoginSuccess(callback: Function): void;

	emit<T extends EventBusEvent>(
		event: T,
		...payload: EventBusEventMap[T] extends never ? [] : [EventBusEventMap[T]]
	): void;
	on<T extends EventBusEvent>(
		event: T,
		callback: EventBusEventMap[T] extends never ? (() => void) : ((payload: EventBusEventMap[T]) => void)
	): void;
	off<T extends EventBusEvent>(
		event: T,
		callback: EventBusEventMap[T] extends never ? (() => void) : ((payload: EventBusEventMap[T]) => void)
	): void;
}

export type EventBusEvent = keyof EventBusEventMap;

export type EventBusEventMap = {
	'update-quick-cart': IQuickCartSummaryJson;
	'blain-login-success': never;
	'header-vm': IHeaderJson;
	'footer-vm': any; // TODO: don't think there's a type for this at the moment
}

export const EventBusEvents = {
	UpdateQuickCart: 'update-quick-cart',
	LoginSuccess: 'blain-login-success',
} as const;