require('lessRoot/catalog/index.less');
import TireSizeLookupService from 'JS/automotive/tires-landing-page/services/TireSizeLookupService.ts';
import { lazyLoadImages } from './images';
import { onBreakpointChange } from 'JS/utilities/viewport';
import { UserIsBot } from '../helpers';

Blain.Catalog = {

	inlineReviews: {},

	initPagination: function() {
		if (!document.getElementById("NonHawkList")) return;
		const next = $(".next");
		const prev = $(".previous");
		const pages = $(".page");
		const refreshUrl = $('.list-container').attr('data-blain-refreshUrl');

		const changePage = function (qs, url) {
			const queryStringSeparator = refreshUrl.indexOf('?') === -1 ? '?' : '&';

			$.ajax({
				url: refreshUrl + queryStringSeparator + qs,
				dataType: 'json',
				method: 'POST',
				beforeSend: function () {
					// Show spinner
					$("#wrapper").addClass("loading");
				},
				success: function (result) {
					$("#NonHawkList").replaceWith(result.html);
				    Blain.Catalog.init();
				},
				error: function(result) {
				},
				complete: function(result) {
					if (typeof (window.history.pushState) === 'function') window.history.pushState(null, url, url);
					// Remove spinner
					$("#wrapper").removeClass("loading");
					$(document).trigger("hawkchanged");
					$(document).trigger("logsearch");
				}
			});
		};

		// TODO: Is this even used?
		function hawkInit() {
			const header = $(".hawk-selectedNav");
			if (!header.length) return;
			header.addClass("hawk-notCollapsed");
			$(".hawk-groupHeading", header).hide();
			const count = $(".hawkFacet-active", header).length;
			if (count > 0) {
				$(".mobile-facet-toggle .header-text").text(`Narrow (${count})`);
			}

			onBreakpointChange((bp) => {
				header.addClass("hawk-notCollapsed");
				if (bp > 767) {
					$(".hawk-groupHeading", header).show();
				} else {
					$(".hawk-groupHeading", header).hide();
				}
			});
		}

		$(document).off("hawkchanged").on("hawkchanged", function (e) {
			hawkInit();
			if ($(".is-landing-page").length === 0) {
				return;
			}

			$(".list-container").removeClass("is-landing-page");
			$(".list-wrapper .category-carousels").remove();

			$(window).off('popstate').on('popstate', function (e) {
				let search = window.location.search;
				search = search.replace(/(&)?(mpp|sort|cref)=[0-9a-zA-Z]+/g, '');
				if (!search) {
				    location.replace(location.pathname);
				    $(".list-container").addClass("is-landing-page");
				}
			});
		});

		next.off("click").on("click", function (e) {
		    e.preventDefault();
			const that = this;
			if ($(that).data("qs") === -1) {
				return;
			}

			changePage($(that).data("qs"), $.qs.set("pg", $(that).data("value")));
			window.scrollTo(0, 0);
		});
		prev.off("click").on("click", function (e) {
		    e.preventDefault();
			const that = this;
			if ($(that).data("qs") === -1) {
				return;
			}

			let url = "";
			const val = $(that).data("value");

			if (val !== 1) {
				url = $.qs.set("pg", val);
			} else {
				url = $.qs.set("pg", null);
			}

			changePage($(that).data("qs"), url);
			window.scrollTo(0, 0);
		});
		pages.off("click").on("click", function (e) {
		    e.preventDefault();
			const that = this;
			if ($(that).data("qs") === -1) {
				return;
			}

			let url = "";
			const val = $(that).data("value");

			if (val !== 1) {
				url = $.qs.set("pg", val);
			} else {
				url = $.qs.set("pg", null);
			}

			changePage($(that).data("qs"), url);
			window.scrollTo(0, 0);
		});
	    hawkInit();
	},
	initSimpleSelect: function() {
		const select = $(".simple-select");
		if (select.length === 0) return;
		select.find(".header").off("click").on("click", function() {
			const that = this;
			const parent = that.parentElement;
			let options = $(parent.querySelector(".options"));

			if ($(parent).hasClass("page-sort") && !$(parent).hasClass("desktop-page-sort")) {
			    const hawkRailNav = $(".hawkRailNav");

			    if (hawkRailNav.is(":visible")) {
			        $(".mobile-facet-toggle .header").trigger("click");

			    }
			}

			if ($(parent).hasClass("mobile-page-sort")) {
			    options = $(".list-header-sort-options");
			    if (options.hasClass("closed")) {
			        $(parent).addClass("active");
			        options.removeClass("closed").addClass("open");
			    } else {
			        $(parent).removeClass("active");
			        options.removeClass("open").addClass("closed");
			    }
			    return;
			} else {
			    $(".page-sort .options").addClass("closed");
			}

			if (options.hasClass("closed")) {
				options.removeClass("closed");
			} else {
				options.addClass("closed");
			}

			if ($(parent).hasClass("mobile-facet-toggle")) {
			    $(".mobile-page-sort").removeClass("active");
			}

			$(document).off("click").on("click", function(e) {
				const temp = $(e.target);
				if (temp.hasClass("header") || temp.parent().hasClass("header")) {
					e.preventDefault();
					return;
				}
				options.addClass("closed");
			});
		});

		select.find(".option").off("click").on("click", function() {
			const that = this;
			if ($(that).hasClass("active")) return;
			const parent = that.parentElement;
			$(parent.querySelectorAll(".option")).removeClass("active");
			$(that).addClass("active");
			$(parent).addClass("closed");
			$(that).trigger("simple:selected"); //emit selected event on the selected dom node
		});
	},

	logProductView: function() {
		if (!UserIsBot()) {
			if (document.getElementById('productDetail')) {
				const elem = $('#productDetail');
				$.ajax({
					url: '/event/a/productview',
					type: "post",
					data: { a: "lp", u: location.href, b: elem.attr('data-blain-num'), h: elem.attr('data-blain-ph') }
				});
			}
		}
	},
	facets: function() {
	    const hawkRailNav = $(".hawkRailNav");
	    const mobileFacetToggle = $(".mobile-facet-toggle");
	    mobileFacetToggle.removeClass("active");
	    mobileFacetToggle.off("click").on("click", function(e) {
			hawkRailNav.toggle();
			if ($(this).hasClass("active")) {
				$(this).removeClass("active");
			} else {
			    $(this).addClass("active");
			    if (!$(".list-header-sort-options").hasClass("closed")) {
			        $(".list-header-sort-options").removeClass("open").addClass("closed");
			    }
			}
		});
	},
	unveil: function() {
		const list = document.getElementById("NonHawkList");
		if (!list) return;
		lazyLoadImages(list);
	},

	initTireSizeSelector: function () {
		const tireSelector = document.getElementById('tireSelector');
		if (!tireSelector) return;

		const svc = new TireSizeLookupService();
		const widthInput = document.getElementById('tireWidth');
		const ratioInput = document.getElementById('tireRatio');
		const rimSizeInput = document.getElementById('tireRimSize');
		const button = tireSelector.querySelector('button');
		let width, ratio, rimSize;

		widthInput.addEventListener('change', (e) => {
			width = e.target.value;
			if (!width) return;
			ratioInput.disabled = true;
			button.disabled = true;
			svc.getAspectRatios(width).then((ratios) => {
				const html = '<option value="" selected disabled>Aspect Ratio</option>';
				ratioInput.innerHTML = ratios.reduce((html, ratio) => {
					html += `<option value="${ratio}">${ratio}</option>`;
					return html;
				}, html);
				ratioInput.disabled = false;
			});
		});

		ratioInput.addEventListener('change', (e) => {
			ratio = e.target.value;
			if (!ratio) return;
			rimSizeInput.disabled = true;
			button.disabled = true;
			svc.getRimSizes(width, ratio).then((rimSizes) => {
				const html = '<option value="" selected disabled>Rim Size</option>';
				rimSizeInput.innerHTML = rimSizes.reduce((html, size) => {
					html += `<option value="${size}">${size}</option>`;
					return html;
				}, html);
				rimSizeInput.disabled = false;
			});
		});

		rimSizeInput.addEventListener('change', (e) => {
			rimSize = e.target.value;
			if (!rimSize) return;
			button.disabled = false;
		});

	},
	initSwatches() {
		const $swatches = $(".list-item .swatch:not(.swatch-plus)");

		function swapSwatch() {
			const that = $(this).addClass("selected");
			that.siblings().removeClass("selected");
			const parent = that.parents(".list-item").find(".list-item-img");
			if (window.devicePixelRatio > 1) parent.prop("src", that.data("xl"));
			else parent.prop("src", that.data("lg"));
		}

		$swatches.hover(swapSwatch);
		$swatches.off("click").on("click", swapSwatch);
	},
	init: function () {
	    const self = this;
	    self.initPagination();
	    self.initSimpleSelect();
	    // self.getReviews();
	    self.logProductView();
	    self.unveil();
	    self.facets();
	    self.initTireSizeSelector();
	    self.initSwatches();

		// TODO: Is code below needed? Not sure this html exists anymore
		const header = $(".hawk-selectedNav");
		if (!header.length) return;
		onBreakpointChange((bp) => {
			header.addClass("hawk-notCollapsed");
			if (bp > 767) {
				const hawkRailNav = $(".hawkRailNav");
		        const mobileFacetToggle = $(".mobile-facet-toggle");
		        if (!hawkRailNav.is(":visible")) {
		            mobileFacetToggle.trigger("click");
		        }
			} else {
				const hawkRailNav = $(".hawkRailNav");
		        const mobileFacetToggle = $(".mobile-facet-toggle");
		        if (hawkRailNav.is(":visible")) {
		            mobileFacetToggle.trigger("click");
		        }
			}
		});
	}
};