
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IHawkSuggestionCategory, IHawkSuggestionProduct, IHawkSuggestionsResult, IHawkSuggestionTrackingData } from 'Src/js/types/HawkSearch';

@Component({
	name: 'SearchSuggestions',
})
export default class SearchSuggestions extends Vue {

	@Prop({ type: Object as () => IHawkSuggestionsResult, required: true })
	readonly suggestions: IHawkSuggestionsResult;

	@Prop({ type: String, required: true, default: '' })
	readonly keyword: string;

	@Prop({ type: Number, required: false, default: 0 })
	readonly testGroup: number;

	mounted() {
		document.body.classList.add('shadowed');
		window.addEventListener('keydown', stopScroll);
	}

	trackAutoSuggestCategory(category: IHawkSuggestionCategory, suggestType: number) {
		this.doTrackAutoSuggest({
			keyword: this.keyword,
			suggestType: suggestType,
			name: category.ValueWithoutHtml,
			itemUrl: category.Url
		});
	}

	trackAutoSuggestProduct(product: IHawkSuggestionProduct) {
		this.doTrackAutoSuggest({
			keyword: this.keyword,
			suggestType: 3,
			name: product.Brand + " " + product.ProductName,
			itemUrl: product.Url
		});
	}

	doTrackAutoSuggest(event: IHawkSuggestionTrackingData) {
		window.blainGtmDataLayer.push({
			'searchKeyword': event.keyword,
			'searchAutocompleteSuggestType': event.suggestType,
			'searchAutocompleteName': event.name,
			'searchAutocompleteItemUrl': event.itemUrl,
			'event': 'search_autocomplete_click'
		});
	}

	destroyed() {
		document.body.classList.remove('shadowed');
		window.removeEventListener('keydown', stopScroll);
	}

}

function stopScroll(e: KeyboardEvent) {
	switch (e.keyCode) {
		case 37:
		case 39:
		case 38:
		case 40:
			e.preventDefault();
			break;
		default: break;
	}
}

