Blain.IconsList = {
	init() {
		const iconsListWrapper = $(".icons-list-wrapper");        
		if (!iconsListWrapper) return;
		const iconsList = $(".icons-list");
		const iconsListItem = $(".icons-list-item");        
		const dotContainer = $('.dot-container');        
		let currentIndex = 0;
		let currentPosition = 0;

		iconsListItem.each(function(i) {
			if (i === currentIndex && iconsList.width() < 767) $(this).addClass('active');
			$(this).addClass(`${'item-' + i}`);
			dotContainer.append(`<div class="dot ${'item-' + i} ${i === currentIndex ? 'active' : ''}" />`);            
		});

		const itemsPerView = $('.icons-list').width() / iconsListItem.width();

		$(window).on('resize', function() {
			if ($(this).width() > 767) {
				iconsList.find('.active').removeClass('active');
			}
			if ($(this).width() < 767) {
				iconsList.find('.item-0').addClass('active');
			}
		});
        
		iconsList.on('scroll', function() {            
			const lengthFromStart = $(this).scrollLeft();

			if (lengthFromStart >= iconsListItem.width() * (currentIndex + 1 / itemsPerView) && lengthFromStart > currentPosition) {                          
				if (lengthFromStart >= iconsListItem.width() * (iconsListItem.length - 1)) return;            
				$(`.${'item-' + currentIndex}`).toggleClass('active');
				currentIndex++;
				$(`.${'item-' + currentIndex}`).toggleClass('active');
				currentPosition = lengthFromStart;
			} else if (lengthFromStart <= iconsListItem.width() * (currentIndex - 1 / itemsPerView) && lengthFromStart < currentPosition) {                                                          
				$(`.${'item-' + currentIndex}`).toggleClass('active');
				currentIndex--;                
				$(`.${'item-' + currentIndex}`).toggleClass('active');                                
				currentPosition = lengthFromStart;
			}
		});
	}
};