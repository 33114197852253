
import BCheckboxBase from './BCheckboxBase';
import BFieldWrapper from './BFieldWrapper.vue';
import Icon from '../Icon.vue';
import { Component, Mixins } from 'vue-property-decorator';

@Component({
	name: 'BCheckbox',
	components: {
		Icon,
		BFieldWrapper,
	},
})
class BCheckbox extends Mixins(BCheckboxBase) {
}

export default BCheckbox;
