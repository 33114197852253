/**
 * Gets a cookie value by name
 * @param name Cookie name
 * @returns Cookie value or null
 */
export function getCookie(name: string): string | null {
	const c = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
	return c ? decodeURIComponent(c[2]) : null;
}

export function getCookieObj<T>(name: string): T | null {
	let obj: T = null;
	const val = getCookie(name);
	if (!val) return null;
	try {
		obj = JSON.parse(val) as T;
		return obj;
	} catch (e) {
		return null;
	}
}

/**
 * Sets a cookie
 * @param name Cookie name
 * @param value Cookie value
 * @param expiration
 */
export function setCookie(name: string, value: string, expiration?: Date): void {
	value = encodeURIComponent(value);
	if (!expiration || typeof expiration !== 'object') {
		document.cookie = name + "=" + value + ";path=/";
	} else {
		document.cookie = name + "=" + value + ";path=/;expires=" + expiration.toUTCString();
	}
}

export function setCookieObj<T>(name: string, obj: T, expiration?: Date): boolean {
	try {
		const serialized = JSON.stringify(obj);
		if (!expiration || typeof expiration !== 'object') {
			document.cookie = name + "=" + serialized + ";path=/";
		} else {
			document.cookie = name + "=" + serialized + ";path=/;expires=" + expiration.toUTCString();
		}
		return true;
	} catch (e) {
		return false;
	}
}

/**
 * Deletes a cookie
 * @param name Cookie name
 */
export function deleteCookie(name: string): void {
	setCookie(name, '', new Date());
}