import { HTTPMethod } from "../types/Http";

export function get<Response>(url: string): Promise<Response> {
	return makeJsonRequest<Response>("GET", url);
}

export function post<Response, RequestData = any>(
	url: string,
	data: RequestData
): Promise<Response> {
	return makeJsonRequest<Response>("POST", url, data);
}

function makeJsonRequest<Response, RequestData = any>(
	method: "GET" | "POST",
	url: string,
	data?: RequestData
): Promise<Response> {
	return new Promise((resolve, reject) => {
		const req = new XMLHttpRequest();
		req.open(method, url, true);
		req.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
		req.onload = function() {
			if (req.status === 200) {
				resolve(JSON.parse(req.responseText) as Response);
				// This will happen when the bot firewall requires a captcha.
				// the page is reloaded to allow legitimate users to be verified
			} else if (req.status === 405) {
				window.location.reload();
			} else {
				reject("A network error has occurred");
			}
		};
		// req.onerror = reject(req);
		req.send(data ? JSON.stringify(data) : null);
	});
}

// TODO: At the time this was authored, all of our base http code assumed we were making requests for JSON.
// We should consider a base service that supports any type of ajax request
export function makeXmlHttpRequest(
	method: HTTPMethod,
	url: string,
	headers?: Record<string, string>
): Promise<string> {
	return new Promise((resolve, reject) => {
		try {
			const req = new XMLHttpRequest();
			req.open(method, url, true);

			if (headers) {
				for (const header of Object.entries(headers)) {
					req.setRequestHeader(header[0], header[1]);
				}
			}

			req.onreadystatechange = function() {
				if (req.readyState === XMLHttpRequest.DONE) {
					switch (req.status) {
						case 200:
							resolve(req.responseText);
							break;
						default:
							reject(req.statusText);
					}
				}
			};

			req.send();
		} catch (e) {
			reject(e);
		}
	});
}

export default {
	get,
	post,
};