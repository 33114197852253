export function toCurrency(val: string): string {
	let num = parseInt(val.toString().replace(/\$|\,/g, ''));
	if (isNaN(num)) return "0.00";
	num = Math.floor(num * 100 + 0.50000000001);
	let cents: string | number = num % 100;
	let str = Math.floor(num / 100).toString();
	if (cents < 10) cents = padLeft(cents.toString(), 2);
	str = str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return str + "." + cents;
};

export function htmlEncode(val: string): string {
	return String(val)
		.replace(/& /g, '&amp; ')
		.replace(/"/g, '&quot;')
		.replace(/'/g, '&#39;')
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;');
};

export function htmlDecode(val: string): string {
	return String(val)
		.replace(/&quot;/g, '"')
		.replace(/&#39;/g, "'")
		.replace(/&lt;/g, '<')
		.replace(/&gt;/g, '>')
		.replace(/&amp;/g, '&');
};

/**
 * @deprecated Use String.prototype.padStart instead
 */
export function padLeft(val: string, len: number, character: string = "0"): string {
	for (let x = val.length; x < len; x++) val = character + val + "";
	return val;
};

// Ensures a string is a url local to the current host. Based off of method in ASP.NET MVC stack
export function isLocalUrl(val: string): boolean {
	const url = val;
	return url.length > 0 && ((url.charAt(0) === "/" && (url.length === 1 || (url.charAt(1) !== "/" && url.charAt(1) !== "\\"))));
};

export function capitalize<T extends string>(val: T): Capitalize<T> {
	return (val.charAt(0).toUpperCase() + val.slice(1)) as Capitalize<T>;
};