export type SliderMoveDirection = 'forward' | 'backward';

export interface SlideEventDetail {
	fromSlide: number;
	toSlide: number;
	slideCount: number;
}

export class SlideChangeEvent extends CustomEvent<SlideEventDetail> {
	constructor(detail: SlideEventDetail, cancelable = true) {
		super('slide', {
			cancelable,
			detail,
		});
	}
}

export type SlideChangeListener = (e: SlideChangeEvent) => void;
export type SlideEndListener = (direction: SliderMoveDirection) => void;

export type ButtonStateChangeEventDetail = {
	previousDisabled: boolean;
	nextDisabled: boolean
};

export class ButtonStateChangeEvent extends CustomEvent<ButtonStateChangeEventDetail> {
	constructor(detail: ButtonStateChangeEventDetail) {
		super('button-state-change', {
			cancelable: false,
			detail,
		});
	}
}

export type ButtonStateChangeListener = (e: ButtonStateChangeEvent) => void;

export interface IListSlider {
	reset: () => void;
	setControls: (prevBtn: HTMLButtonElement, nextBtn: HTMLButtonElement) => void;
	slideForward: () => void;
	slideBackward: () => void;
	onSlide: (listener: SlideChangeListener) => () => void;
	onSlideEnd: (listener: SlideEndListener) => () => void;
	onButtonStateChange: (listener: ButtonStateChangeListener) => () => void,
	slideIndexIntoView: (index: number) => void;
	getNumSlides: () => number;
	goToSlide: (slide: number) => void;
	getCurrentSlide: () => number;
	destroy: () => void;
}
