import bugsnagClient from "./bugsnag";
import { IGlobalWindowData } from "../types/GlobalData";

type WithGlobalData = Pick<typeof window, 'globalData'>;
type Reporter = Pick<typeof bugsnagClient, 'notify'>;

/**
 * Behavior:
 * - Warn on access of any properties during SSR/pre-rendering.
 * - Warn and report missing globalData on the client.
 * - Warn and report access of missing properties on the client side and returns null.
 *
 * Context: this replaces a class which manually proxied each property of globalData, returning null and reporting errors if missing.
 * Benefits over the previous approach:
 * - new properties may simply get added to the IGlobalWindowData interface
 * - this will not attempt to notify bugsnag during prerender
 * - better developer messaging when attempting access during prerender
 * - window and bugsnagClient are injected, so it's a little more easily testable
 *
 * @param windowObject The window object containing globalData
 * @param bugReporter Used to report missing globalData properties client-side. Constrained interface of bugsnagClient.
 */
export function createGlobalDataProxy(windowObject: WithGlobalData | undefined = window, bugReporter: Reporter = bugsnagClient) {
	// window is undefined in SSR
	const ssr = typeof windowObject === 'undefined';

	const notify = (e: string) => {
		// eslint-disable-next-line no-console
		console.warn(e);
		if (!ssr) {
			bugReporter.notify(e);
		}
	};

	if (!ssr && typeof windowObject.globalData === 'undefined') {
		notify('globalData was not found on the window object');
	}

	const globalData = ssr || !windowObject.globalData ? {} as IGlobalWindowData : windowObject.globalData;

	return new Proxy<IGlobalWindowData>(globalData, {
		get(target, prop) {
			if (ssr) {
				throw new Error(
					`Attempted access of \`${prop.toString()}\` on globalData during SSR.`
					+ " Make sure to access globalData's properties inside of a `typeof window !== 'undefined'`"
					+ " check or a component's `onMounted` hook"
				);
			}

			if (!(prop in target) || typeof target[prop] === 'undefined') {
				notify(`${prop.toString()} not found on global window data`);
				return null;
			}

			return target[prop];
		}
	});
}

export default createGlobalDataProxy();