Blain.SliverBanner = {
	init: function () {
		const self = this;
		const sBanner = $("#sliverBanner");
		if (sBanner.length) {
			$("#sliverBanner #closeBanner").on("click", function() {
				sBanner.slideUp();
			});
		}
	}
};