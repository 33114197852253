<template>
	<div>
		<div v-if="cart.cartQty" class="cart-wrapper">
			<div class="cart-header flex-row flex-between">
				<div class="header-text flex-row fs-14 medium-bold">
					<div class="left flex-row">
						<span class="icon icon-cart-outline"></span> Cart Subtotal
					</div>
					<div class="right">
						<div class="price-amount-wrapper">
							<span class="dollar-sign">$</span>{{ cart.subtotal }}
						</div>
					</div>
				</div>
				<close-btn class="show-mobile" @close="$emit('close')" />
			</div>
			<a class="reward-callout flex-row flex-between no-select" href="/rewards" v-if="cart.potentialRewardsPoints">
				<div class="flex-row">
					<span class="icon icon-blain-eagle"></span>
					Earn
					<span class="points">{{ cart.potentialRewardsPoints }} points</span>
					with this purchase!
				</div>
				<div class="icon icon-tiny icon-chevron-right"></div>
			</a>
			<div style="padding:10px">
				<div class="scroll-hidden" ref="scroll_area">
					<div
						v-if="cart.cartQty > 0"
						class="product-list"
						ref="_product_summary"
					>
						<div v-for="p in cart.productsInCart" :key="p.id">
							<quick-cart-product :product="p" />
						</div>
						<div v-for="gc in cart.giftCardsInCart" :key="gc.id">
							<quick-cart-gift-card :gift-card="gc" />
						</div>
					</div>
					<div
						class="scroll-hint"
						v-show="cart.productsInCart.length > 3 && showScrollHint"
						ref="_scroll_hint"
					>
						<span class="no-select">
							scroll for more <span class="icon icon-tiny icon-chevron-down-tiny"></span>
						</span>
					</div>
				</div>
				<ui-button
					href="/cart/"
					style="padding: 7px 16px;"
					color="red-white-border"
					class="ui-btn-full"
				>
					View Cart &amp; Check Out
				</ui-button>
			</div>
		</div>
		<div v-else>
			<div class="cart-header flex-row flex-between">
				<div class="header-text flex-row fs-14-medium-bold">
					<span class="icon icon-cart-outline"></span>
					<span class="text">Cart</span>
				</div>
				<close-btn class="show-mobile" @close="$emit('close')" />
			</div>
			<div class="no-items-wrapper">
				<p class="no-items-text text-center medium-bold fs-14">
					Your cart is currently empty.
					<span v-if="!loggedIn" class="light">Sign in to see items you might have added previously.</span>
				</p>
				<ui-button
					v-if="!loggedIn"
					@click="showLoginSideMenu"
					style="margin: 0 auto 10px auto;padding: 7px 16px;max-width: 200px;"
					color="red-white-border"
				>
					Sign In
				</ui-button>
			</div>
		</div>
	</div>
</template>

<script>
import CloseBtn from 'JS/components/CloseBtn.vue';
import QuickCartGiftCard from './QuickCartGiftCard.vue';
import QuickCartProduct from './QuickCartProduct.vue';
import UiButton from 'JS/components/UiButton.vue';

export default {
	name: 'QuickCart',
	components: {
		QuickCartGiftCard,
		QuickCartProduct,
		CloseBtn,
		UiButton
	},
	props: {
		cart: {
			type: Object,
			required: true
		},
		loggedIn: Boolean
	},
	computed: {
		hasProductsInCart() {
			return this.cart && this.cart.cartQty > 0;
		}
	},
	data() {
		return {
			showScrollHint: true,
		};
	},
	methods: {
		navigateToCart() {
			this.isTablet ? this.open() : window.location.replace('/cart/');
		},
		handleScroll(event) {
			const { scrollTop, scrollHeight, offsetHeight } = event.target;
			const scrollHintHeight = this.$refs._scroll_hint.clientHeight;
			const productSummaryHeight = this.$refs._product_summary.lastElementChild.clientHeight;
			if (!this.showScrollHint && (scrollHeight - (scrollTop + offsetHeight) > productSummaryHeight + scrollHintHeight)) {
				this.showScrollHint = true;
			} else if (this.showScrollHint && (scrollTop + offsetHeight + scrollHintHeight >= scrollHeight)) {
				this.showScrollHint = false;
			}
		},
		showLoginSideMenu() {
			Blain.Login.showSideMenu('login', true);
		},
	},
	mounted() {
		if (this.hasProductsInCart) {
			this.$refs.scroll_area.addEventListener('scroll', e => this.handleScroll(e));
		}
	}
};
</script>

<style lang='less' scoped>
@import '~@less/_mixins.less';
.cart-wrapper {
	background: #E5E7F1; /* Old browsers */
	background: -moz-linear-gradient(top,  #E5E7F1 0%, rgb(234, 235, 241) 80%,  #F5F5F5 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  #E5E7F1 0%,rgb(234, 235, 241) 80%,#F5F5F5 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  #E5E7F1 0%,rgb(234, 235, 241) 80%,#F5F5F5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	color: #000;
	z-index: 2;
	position: relative;
}

.cart-header {
	background-color: #fff;
	padding: 10px;
}

.header-text {
	color: #656E97;
	letter-spacing: 0.56px;
	.left {
		color: #656E97;
		letter-spacing: 0.56px;
		align-self: flex-end;
	}
	.right {
		margin-left: 8px;
		color: #000;
	}
	.text {
		margin-top: 5px;
	}
	.icon {
		color: #98A1CA;
		font-size: 24px;
		margin-right: 5px;
	}
}

.price-amount-wrapper {
	display: inline-flex;
	align-items: flex-end;
	font-size: 14px;
	font-weight: 500;
	color: #000;
}

.dollar-sign {
	color: #000;
	vertical-align: text-bottom;
	font-weight: bold;
	font-size: 11px;
	padding: 0 1px 2px 0;
}

.scroll-hidden {
	max-height: 480px;
	overflow-y:scroll;
	overflow-x: hidden;
	position: relative;
	margin-bottom: 5px;
	&::-webkit-scrollbar { width: 0 !important }
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.scroll-hint {
	min-height: 60px;
	bottom: 0;
	position: sticky;
	background: rgb(245,245,245);
	display: flex;
	align-items: flex-end;
	justify-content: center;
	background: linear-gradient(180deg, rgba(245,245,245,0) 0%, rgba(245,245,245,1) 50%, rgba(245,245,245,1) 100%);
	color: #717BA9;
	font-size: 14px;
	border-bottom: 2px solid #E5E5E5;
	.icon-tiny {
		font-size: 7px;
		margin-left: 7px;
	}
}

.reward-callout {
	background-color: #FFF3D6;
	color: #666666;
	font-size: 14px;
	font-weight: 500;
	justify-content: space-between;
	padding: 13px 7px;
	max-height: 42px;
	text-decoration: none;
	.points {
		font-weight: bold;
		color: @DR;
		margin: 0 3px;
	}
	.icon {
		font-size: 24px;
		margin-right: 10px;
	}
	.icon-tiny {
		font-size: 7px;
		margin-right: 5px;
	}
}

.no-items-wrapper {
	padding: 0px 10px 5px 10px;
}

.no-items-text {
	border-top: 2px solid #E5E7F1;
	color: #666;
	line-height: 20px;
	padding: 15px 0;
	padding-bottom: 0;
	margin-bottom: 15px;
}
</style>