
import { formRegistrarKey, groupDisabledKey, groupNameKey, IRadioComponent, IValidatable, radioGroupKey, ValidationResult } from './internal-types';

import TransitionExpand from 'Src/js/components/transitions/TransitionExpand.vue';
import { computed, defineComponent, inject, nextTick, onBeforeUnmount, onMounted, provide, ref, watch } from 'vue';
import { v4 } from 'uuid';

export default defineComponent({
	name: 'BRadioGroup',
	components: {
		TransitionExpand,
	},
	props: {
		/**
		 * Current value of the radio group. Can be used with v-model.
		 */
		value: null,

		/**
		 * Name to apply to nested radio inputs. Randomly-generated if not supplied.
		 */
		name: { type: String },

		/**
		 * Disables all radio inputs within the group.
		 */
		disabled: { type: Boolean },

		/**
		 * Required at least one radio button to be selected at all times
		 */
		forceSelection: { type: Boolean, required: false, default: true },

		/**
		 * If false, options can remain unselected
		 */
		required: { type: Boolean, default: true },

		innerClass: { type: String, default: '' }
	},
	emits: ['input'],
	setup(props, { emit }) {
		const uuid = v4();

		const groupName = computed(() => props.name || `${uuid}-name`);
		const groupDisabled = computed(() => props.disabled);
		provide(groupNameKey, groupName);
		provide(groupDisabledKey, groupDisabled);

		const items = ref<IRadioComponent[]>([]);
		const internalValue = ref<any>(null);

		/**
		 * Maintains internal state and the state of nested radio elements.
		 */
		function updateValue(value: any) {
			if (value !== props.value) {
				emit('input', value);
			}

			internalValue.value = value;

			items.value.forEach(item => {
				item.isActive = item.value === internalValue.value;
			});

			errorMessage.value = '';
		}

		/**
		 * Ensures that a radio element is selected.
		 */
		function updateMandatory() {
			if (!items.value.length) {
				return;
			}

			const currentVal = getCurrentValueIfMatchingItemExists(items.value, props.value);
			if (currentVal) {
				updateValue(currentVal);
				return;
			}

			if (props.forceSelection) {
				updateValue(
					getCurrentValueIfMatchingItemExists(items.value, props.value) ??
					getFirstCheckedItemValue(items.value) ??
					getFirstNonDisabledItemValue(items.value) ??
					getFirstItemValue(items.value)
				);
			}
		}

		const childWatchers = new Map<IRadioComponent, () => void>();

		provide(radioGroupKey, {
			register(child: IRadioComponent) {
				if (items.value.length === 0) {
					// Schedule updateMandatory when all of the radio children added in the current tick have been registered
					nextTick(() => {
						updateMandatory();
					});
				}

				items.value.push(child);

				const stopExistingWatcher = childWatchers.get(child);
				stopExistingWatcher?.();


				childWatchers.set(child, child.onChange(() => {
					updateValue(child.value);
				}));
			},

			unregister(child: IRadioComponent) {
				items.value = items.value.filter(registeredChild => registeredChild !== child);

				const wasActive = child.isActive;

				childWatchers.get(child)?.();
				childWatchers.delete(child);

				// Scheduling this for next tick lets us determine whether _all_ of the
				// children are being unregistered (in which case, we don't want to fire an update),
				// or if only some are being unregistered
				nextTick(() => {
					if (wasActive && items.value.length > 0) {
						updateMandatory();
					}
				});
			}
		});

		const errorMessage = ref('');

		function validate(): ValidationResult {
			if (props.value == null && props.required) {
				errorMessage.value = "Please make a selection";
				return errorMessage.value;
			}

			errorMessage.value = '';
			return true;
		}

		function resetValidation() {
			errorMessage.value = '';
		}

		const form = inject(formRegistrarKey, undefined);

		const formChild: IValidatable = {
			validate,
			resetValidation
		};

		onMounted(() => {
			form?.register?.(formChild);

			if (props.value != null) {
				updateValue(props.value);
			}

		});

		onBeforeUnmount(() => {
			form?.unregister?.(formChild);
		});

		watch(() => props.value, value => {
			if (value !== internalValue.value) {
				updateValue(value);
			}
		});

		return {
			errorMessage,
			uuid,
		};
	}
});

function getCurrentValueIfMatchingItemExists(items: IRadioComponent[], value: any): any | null {
	const itemWithCurrentValueExists = !!value && !!items.find(item => item.value === value);
	return itemWithCurrentValueExists ? value : null;
}

function getFirstCheckedItemValue(items: IRadioComponent[]): any | null {
	const checked = items.find(item => item.checked);
	return checked?.value ?? null;
}

function getFirstNonDisabledItemValue(items: IRadioComponent[]): any | null {
	const firstNotDisabled = items.find(item => !item.disabled);
	return firstNotDisabled?.value ?? null;
}

function getFirstItemValue(items: IRadioComponent[]): any {
	return items[0].value;
}

