import { inject, InjectionKey, provide } from "vue";
import { v4, v5 } from "uuid";

export const uuidGeneratorProviderKey: InjectionKey<UuidGenerator> = Symbol();

export type UuidGenerator = IterableIterator<string>;

/**
 * Works alongside `useUuid` to expose a deterministic stream of UUIDs to Vue components within the tree.
 * Its primary purpose is to ensure that UUIDs generated on the client and server match up (why determinism
 * is significant here). IMPORTANT: seed should be unique, i.e. no two trees on the same page should have the
 * same seed -- otherwise there will be duplicate IDs.
 * @param {string} seed Should be unique
 */
export function provideSeededUuidGenerator(seed: string) {
	provide(uuidGeneratorProviderKey, seededUuidGenerator(seed));
}

/**
 * Returns a UUID string. Useful for things like element IDs, especially for re-usable components
 * where associating elements by ID is important for accessibility. Avoid calling conditionally.
 * If used in components that will be server-side rendered, its important that the app does one of
 * the following in order to keep UUIDs in server-rendered HTML, the DOM, and component state in sync

 * 1. Provides a seeded UUID generator at the top level (using `provideSeededUuidGenerator`)
 * 2. Is wrapped in an `<ssr-root>`, which does 1
 */
export function useUuid(): string {
	let uuidGenerator = inject(uuidGeneratorProviderKey, undefined);
	if (typeof uuidGenerator === 'undefined' && typeof window === 'undefined') {
		// eslint-disable-next-line no-console
		console.warn(
			`Called useUuid on the server outside of an SsrRoot. If the return
 value is used as a DOM attribute, the UUID in component state and
the UUID in the DOM will be mismatched.`
		);
	}

	uuidGenerator = uuidGenerator || randomUuidGenerator();

	return uuidGenerator.next().value;
}

/**
 * Returns a generator which produces an infinite sequence of random UUIDs.
 */
function* randomUuidGenerator(): UuidGenerator {
	while (true) {
		yield v4();
	}
}

// arbitrary UUID. allows us to produce a deterministic sequence of UUIDs from a starting seed.
// using a hardcoded value here because it must be the same on the server and client so that
// calls to useUuid return the same value in the same places
const SEEDED_UUID_NAMESPACE = '68108a49-fe3d-411e-ac02-0073be4313c9';

/**
 * Returns a generator which produces an infinite sequence of UUIDs. The sequence is deterministic; given the
 * same seed, it produces the same sequence of UUIDs.
 *
 * @param {string} seed Some string value which essentially identifies some sequence of UUIDs.
 */
function* seededUuidGenerator(seed: string): UuidGenerator {
	let last = seed;
	while (true) {
		const uuid = v5(last, SEEDED_UUID_NAMESPACE);
		last = uuid; // this UUID will be the seed for the next UUID
		yield uuid;
	}
}

