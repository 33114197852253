export function debounce<Fn extends (...args: any[]) => void>(delay: number, f: Fn): Fn {
	let timer: number | undefined;
	
	return ((...args: Parameters<Fn>): void => {
		if (timer != null) {
			clearTimeout(timer);
		}

		timer = window.setTimeout(() => f(...args), delay);
	}) as Fn;

};