
import HasMenu from '../HasMenu.vue';
import HeaderDropMenu from '../HeaderDropMenu.vue';
import StoreFinder from './StoreFinder.vue';
import { Component, Prop } from 'vue-property-decorator';
import { IHeaderJson } from 'Src/js/types/Header';

@Component({
	name: 'HeaderStores',
	components: {
		HeaderDropMenu,
		StoreFinder
	}
})
export default class HeaderStores extends HasMenu {

	@Prop({ type: Object as () => Partial<IHeaderJson>, required: true })
	readonly headerData: Partial<IHeaderJson>;

	@Prop({ type: String, required: false, default: "left" })
	readonly position: string;

}

