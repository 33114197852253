import BlainBaseService from "./BlainBaseService";
import { IHeaderJson } from "../types/Header";

export class GlobalResourceService extends BlainBaseService {

	public async getHeaderData(): Promise<IHeaderJson> {
		const res = await this._httpSvc.get<IHeaderJson>(`/globalresource/header/`);
		return res;
	}

}

export default new GlobalResourceService();