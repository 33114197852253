<template>
	<div id="vue-footer" class="footer-content">
		<mobile-app-callout class="hide-desktop" />

		<!----------------- Desktop ----------------->

		<div class="footer-content-top desktop container flex flex-between">
			<sign-up-section :asset-cdn-url="assetCdnUrl" id-prefix="desktop">
				<a :href="newsweekArticleUrl" class="footer-content-newsweek mt-lg hide-desktop">
					<img
						:src="`${assetCdnUrl}/assets/images/misc/Newsweek_BOS2024_Logo_Hor.svg`"
						alt="Newsweek America's Best Online Shops 2024"
						loading="lazy"
						width="225"
						height="113"
					/>
				</a>
			</sign-up-section>
			<a :href="newsweekArticleUrl" class="footer-content-newsweek">
				<img
					:src="`${assetCdnUrl}/assets/images/misc/Newsweek_BOS2024_Logo_Basic.svg`"
					alt="Newsweek America's Best Online Shops 2024"
					loading="lazy"
					width="130"
					height="224"
				/>
			</a>
			<services-links />
			<company-links />
			<customer-care-links />
			<store-summary :store="localStore" />
		</div>

		<!----------------- Tablet ----------------->

		<div class="footer-content-top tablet">
			<div class="flex flex-between mb-lg">
				<sign-up-section :asset-cdn-url="assetCdnUrl" id-prefix="tablet" />
				<a :href="newsweekArticleUrl" class="footer-content-newsweek">
					<img
						:src="`${assetCdnUrl}/assets/images/misc/Newsweek_BOS2024_Logo_Basic.svg`"
						alt="Newsweek America's Best Online Shops 2024"
						loading="lazy"
						width="130"
						height="224"
					/>
				</a>
				<store-summary :store="localStore" />
			</div><br />
			<div class="flex flex-between mt-lg">
				<services-links />
				<company-links />
				<customer-care-links />
			</div>
		</div>

		<!----------------- Mobile ----------------->

		<div class="footer-content-top mobile">
			<div class="margin-x-auto maxwidth-300">
				<customer-care />
				<a :href="newsweekArticleUrl" class="footer-content-newsweek">
					<img
						:src="`${assetCdnUrl}/assets/images/misc/Newsweek_BOS2024_Logo_Hor.svg`"
						alt="Newsweek America's Best Online Shops 2024"
						loading="lazy"
						width="225"
						height="113"
					/>
				</a>
				<sign-up-section :asset-cdn-url="assetCdnUrl" id-prefix="mobile" />
			</div>
			<mobile-link-section />
		</div>
	</div>
</template>

<script>
import CompanyLinks from './CompanyLinks.vue';
import CustomerCare from './shared/CustomerCare.vue';
import CustomerCareLinks from './CustomerCareLinks.vue';
import MobileAppCallout from './mobile/MobileAppCallout.vue';
import MobileLinkSection from './mobile/MobileLinkSection.vue';
import ServicesLinks from './ServicesLinks.vue';
import SignUpSection from './shared/SignUpSection.vue';
import StoreSummary from './StoreSummary.vue';
import vueEventBus from '../../global/vue-event-bus.js';
export default {
	name: 'FooterApp',
	components: {
		CompanyLinks,
		CustomerCareLinks,
		ServicesLinks,
		MobileAppCallout,
		SignUpSection,
		CustomerCare,
		MobileLinkSection,
		StoreSummary,
	},
	data() {
		return {
			isMobileDevice: false,
			localStore: null,
			assetCdnUrl: '',
			newsweekArticleUrl: 'https://www.farmandfleet.com/blog/best-online-shops-2022/'
		};
	},
	methods: {
		init(vm) {
			if (!vm) return;
			this.isMobileDevice = vm.isMobileDevice;
			this.localStore = vm.localStoreJson;
			if (vm.assetCdnBaseUrl) this.assetCdnUrl = vm.assetCdnBaseUrl;
		},
		eventBusListen() {
			if (!window) return;
			if (!window.vueEventBus) window.vueEventBus = vueEventBus;
			window.vueEventBus.$on('footer-vm', vm => this.init(vm));
		}
	},
	mounted() {
		if (!window) return;
		window.footerJson ? this.init(window.footerJson) : this.eventBusListen();
	}
};
</script>

<style lang="less">
@import '../../../less/_mixins.less';

.footer-content {
	background-color: #F2F3F8;
}

.footer-content-top {
	a {
		color: @DB;
		display: block;
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 10px;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	.footer-email-signup {
		flex: 0 1 300px;
	}
}

.footer-content-top.desktop {
	padding: 50px 15px;
	display: none;

	@media only screen and (min-width: 1025px) {
		display: flex;
	}

	.styles-at(1180px, {
		.footer-content-newsweek {
			display: none;
		}
		.footer-email-signup .footer-content-newsweek {
			display: block !important;
			margin: 20px auto 0 auto;
		}
	});
}

.footer-content-top.tablet {
	display: none;
	margin: 0 auto;
	max-width: 720px;
	padding: 20px;

	@media only screen and (min-width: @tablet-min) and (max-width: @desktop-min) {
		display: block;
	}
}

.footer-content-top.mobile {
	margin: 0 auto;
	max-width: 420px;
	padding: 20px;

	@media only screen and (min-width: @tablet-min){
		display: none;
	};

	.footer-customer-care,
	.footer-content-newsweek {
		margin: 0 0 20px;
	}

	.fes-social {
		margin: 0 auto 20px;
		width: 290px;
	}
}

.footer-content-signup {
	flex: 0 1 520px;
}

.footer-content-newsweek {
	max-width: 225px;
	flex: 0 0 130px;
	img {
		width: 100%;
	}
}

.footer-bottom {
	background-color: #E5E7F1;
	padding: 10px;

	a {
		border-left: solid 1px #B2B8D7;
		color: @DB;
		display: inline-block;
		font-size: 14px;
		margin: 0 0 10px 2px;
		padding-left: 5px;
		text-decoration: none;

		&:hover {
			text-decoration: none;
		}

		.mobile-styles({
			font-size: 11px;
			margin: 0 0 5px 2px;
		});
	}

	a:first-child {
		border-left: none;
		padding-left: 0;
		margin-left: 0;
	}

	.footer-divider {
		margin-top: 5px;
	}

	.mobile-styles({
		padding-bottom: 100px;
	});
}

.footer-bottom-copy {
	color: #4C5271;

	.mobile-styles({
		font-size: 11px;
	});
}

.footer-divider {
	background-color: #CBD0E4;
	height: 2px;
	margin: 15px 0 10px;
}

.footer-links-list {
	h2 {
		color: #4C5271;
		font-size: 18px;
		font-weight: 200;
		margin-bottom: 12px;
	}
}
</style>