import { capitalize } from "JS/utilities/string";
import { computed, ComputedRef, getCurrentScope, onMounted, onScopeDispose, onUnmounted, readonly, ref } from "vue";
import { createSharedComposable } from "JS/utilities/composable";
import { debounce } from "JS/utilities/debounce";
import { DefaultBreakpointDefinition, DeviceBreakpoint } from "JS/types/Media";

/**
 * Returns a readonly ref with the current window size. The value is debounced and thus not updated in real-time.
 */
export const useViewportWidth = createSharedComposable(() => {
	const size = ref(typeof window === 'undefined' ? DeviceBreakpoint.DesktopSmall : window.innerWidth);

	const listener = debounce(50, () => {
		size.value = window.innerWidth;
	});

	onMounted(() => {
		window.addEventListener('resize', listener);
	});

	onUnmounted(() => {
		window.removeEventListener('resize', listener);
	});

	return readonly(size);
});

export type BreakpointDefinition = Record<string, number>;
type BreakpointGetterKey<K extends keyof BreakpointDefinition> = `is${Capitalize<K>}`
export type BreakpointGetters<T extends BreakpointDefinition> = { [K in keyof T as BreakpointGetterKey<string & K>]: ComputedRef<boolean> };

/**
 * Reactive breakpoints object. In addition to the properties/methods with doc comments, it includes boolean getters for each entry in T.
 */
export type Breakpoints<T extends BreakpointDefinition> = Readonly<BreakpointGetters<T>> & {
	/**
	 * Name of the currently active breakpoint.
	 */
	currentBreakpoint: ComputedRef<keyof T>,

	/**
	 * Compares named `breakpoint` to currently active breakpoint. Returns true if `breakpoint` is larger.
	 * @param breakpoint Breakpoint for comparison
	 */
	currentIsGreaterThan(breakpoint: keyof T): boolean

	/**
	 * Compares named `breakpoint` to currently active breakpoint. Returns true if `breakpoint` is smaller.
	 * @param breakpoint Breakpoint for comparison
	 */
	currentIsLessThan(breakpoint: keyof T): boolean
};

/**
 * Composable utility that provides reactive information about the viewport size, given `breakpointDefinition`. If the given set of breakpoints will be used in more than a couple of places,
 * wrapping this with createSharedComposable is recommended to avoid duplicate MediaQueryLists and unnecessary listeners. See `useBreakpoints` as an example of this for the default breakpoint setup.
 *
 * @param breakpointDefinition A record mapping breakpoint name to the maximum width at which the breakpoint ends. The value of the largest breakpoint will not be used directly, but it must be greater than the other breakpoints.
 *
 * @returns See the example or the type definition for Breakpoints.
 *
 * @example
 * const breakpoints: useCustomBreakpoints({
 *   mobile: 767;
 *   tablet: 1023;
 *   desktop: Infinity;
 * })
 *
 * console.log(breakpoints);
 *
 * // console output. various stuff Vue adds for reactivity is omitted for clarity.
 * {
 *   currentBreakpoint: 'tablet', // assume that window width is greater than 767 and less than or equal to 1023
 *   currentIsGreaterThan() {...},
 *   currentIsLessThan() {...},
 *   isMobile: false,
 *   isTablet: true,
 *   isDesktop: false,
 * }
 */
export function useCustomBreakpoints<T extends BreakpointDefinition>(breakpointDefinition: T, defaultBreakpoint?: (keyof T & string) | null): Breakpoints<T> {
	// break breakpoint definition up into [name, max] pairs and sort by max ascending to make lookup and comparison easy
	const breakpointEntries = Object.entries(breakpointDefinition).sort(([_, maxA], [__, maxB]) =>
		maxA < maxB
			? -1
			: maxA === maxB
				? 0
				: 1) as [(keyof T & string), number][];

	const mediaQueryPairs: [keyof T & string, ComputedRef<boolean>][] = breakpointEntries
		.map(([name, value], i) => {
			const previous = breakpointEntries[i - 1];
			const next = breakpointEntries[i + 1];

			let query = '';
			if (!previous) {
				query = `(max-width: ${value}px)`;
			} else if (!next) {
				query = `(min-width: ${previous[1] + 1}px)`;
			} else {
				query = `(min-width: ${previous[1] + 1}px) and (max-width: ${value}px)`;
			}

			return [name, useMediaQuery(query)];
		});

	const currentBreakpoint = computed(() => mediaQueryPairs.find(([_, matches]) => matches.value)?.[0] ?? defaultBreakpoint);

	const breakpoints = Object.fromEntries(
		breakpointEntries.map(([key, _]) => [
			`is${capitalize(key)}`,
			computed(() => currentBreakpoint.value === key)
		])
	) as BreakpointGetters<T>;

	return {
		currentBreakpoint: computed(() => currentBreakpoint.value),
		...breakpoints,
		currentIsLessThan(breakpoint) {
			const currentIndex = breakpointEntries.findIndex(([name]) => name === currentBreakpoint.value);
			const bpIndex = breakpointEntries.findIndex(([name]) => name === breakpoint);

			return bpIndex > currentIndex;
		},
		currentIsGreaterThan(breakpoint) {
			const currentIndex = breakpointEntries.findIndex(([name]) => name === currentBreakpoint.value);
			const bpIndex = breakpointEntries.findIndex(([name]) => name === breakpoint);

			return bpIndex < currentIndex;
		},
	};
}

/**
 * Composable utility that provides reactive information about viewport size.
 *
 * @returns Breakpoints object defined with the default site breakpoints. See documentation for useCustomBreakpoints and the Breakpoint type for more information
 */
export const useBreakpoints = createSharedComposable(() => useCustomBreakpoints(DefaultBreakpointDefinition, 'desktop'));

/**
 * Reactive window.matchMedia. Returns a readonly ref with the current value of MediaQueryList.matches. Will always be false in SSR.
 *
 * @param query media query passed to window.matchMedia
 */
export function useMediaQuery(query: string) {
	const matches = ref(false);

	if (typeof window !== 'undefined') {
		const media = matchMedia(query);

		const listener = (e: { matches: boolean }) => {
			matches.value = e.matches;
		};

		media.addEventListener('change', listener);
		listener(media);

		if (getCurrentScope()) {
			onScopeDispose(() => media.removeEventListener('change', listener));
		}
	}

	return computed(() => matches.value);
}