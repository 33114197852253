import { ICarouselClickData, ICarouselClickGtmData, ICarouselViewData, ICarouselViewGtmData } from "JS/types/Carousel";

export interface ICarouselTrackingService {
	trackClick(clickData: ICarouselClickData | null, gtmData: ICarouselClickGtmData | null): Promise<void>;
	trackViews(viewData: ICarouselViewData[] | null, gtmData: ICarouselViewGtmData[] | null): Promise<void>;
}

export default class CarouselTrackingService implements ICarouselTrackingService {
	/**
	 * Sends a carousel product click event to the back-end and Google Tag Manager
	 *
	 * @param clickData Click data object to be sent to the back-end
	 */
	async trackClick(clickData: ICarouselClickData | null, gtmData: ICarouselClickGtmData | null): Promise<void> {

		if (gtmData) {
			// Send update to Google Tag Manager
			window.blainGtmDataLayer.push({
				'itemListID': gtmData.itemListID,
				'itemListName': gtmData.itemListName,
				'itemListIndex': gtmData.index + 1, // Adjusting for the fact that this appears to be zero-based across the board
				'currentPrice': gtmData.currentPrice,
				'belowMap': gtmData.belowMap,
				'priceMode': gtmData.priceMode,
				'blainNumber': gtmData.blainNumber,
				'vendorBrand': gtmData.vendorBrand,
				'productName': gtmData.productName,
				'event': "product_carousel_click"
			});
		}

		// Send update to internal tracking
		if (clickData) {
			sendJsonBeacon('/event/a/carousel-click', clickData);
		}
	}

	/**
	 * Sends an array of carousels to the back-end for tracking
	 *
	 * @param viewData Array of CarouselViewData
	 */
	async trackViews(viewData: ICarouselViewData[] | null, gtmData: ICarouselViewGtmData[] | null): Promise<void> {
		for (const item of gtmData ?? []) {
			window.blainGtmDataLayer.push({ 'carouselProducts': null });
			window.blainGtmDataLayer.push({
				'itemListID': item.itemListID,
				'itemListName': item.itemListName,
				'carouselProducts': item.carouselProducts,
				'event': "product_carousel_view"
			});
		}

		if (viewData?.length) {
			sendJsonBeacon('/event/a/carousel-view', viewData);
		}
	}
}

function sendJsonBeacon<T>(url: string, data: T) {
	return navigator.sendBeacon(url, JSON.stringify(data));
}