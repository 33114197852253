
import HasMenu from '../HasMenu.vue';
import HeaderDropMenu from '../HeaderDropMenu.vue';
import Icon from 'JS/components/Icon.vue';
import IconBadge from '../IconBadge.vue';
import NotificationsComponent from './Notifications.vue';
import { Component, Prop } from 'vue-property-decorator';
import { IHeaderJson } from 'Src/js/types/Header';
import { INotification } from 'Src/js/types/Notifications';

@Component({
	name: 'HeaderNotifications',
	components: {
		HeaderDropMenu,
		Icon,
		IconBadge,
		NotificationsComponent,
	}

})
export default class HeaderNotifications extends HasMenu {

	/* Props
	============================================*/

	@Prop({ type: Object as () => Partial<IHeaderJson>, required: true })
	readonly headerData: Partial<IHeaderJson>;

	/* Computed
	============================================*/

	get notifications(): INotification[] {
		return this.headerData?.notifications ?? [];
	}

	get newNotifications(): number {
		return this.headerData?.notificationsCount ?? 0;
	}

	get dropdownAriaLabel(): string {
		let label = "";
		if (this.newNotifications > 0) {
			label += this.newNotifications + " new notification" + (this.newNotifications > 1 ? "s. " : ". ");
		}
		return label + "Show notifications menu";
	}

	get iconBadgeDescription(): string {
		return ` new ${this.newNotifications === 1 ? 'notification' : 'notifications'}`;
	}

}

